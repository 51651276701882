import { PropsWithChildren, useState } from 'react'
import cn from '../../../../../utils/cn'
import styles from '../authPage.module.css'
import TextInput from '../../../../../shared/Inputs/TextInput'
import { IRegisterData } from '../../../auth.types'
import TextField from '../../../../../shared/Forms/TextField'
import Button from '../../../../../shared/Buttons/Button/Button'
import useHttpLoader from '../../../../../shared/hooks/useHttpLoader'
import authApi from '../../../auth.api'
import eventBus from '../../../../../services/eventBus'
import RrojectEducationYearDropdown from 'screens/Projects/components/ProjectEducationYearDropdown'

interface IProps {
  serverError: string
  onRegistered: () => void
  valid: boolean
}

const RegisterForm = (props: PropsWithChildren<IProps>) => {
  const { wait, loading } = useHttpLoader()
  const [registerData, setRegisterData] = useState<IRegisterData>({
    email: '',
    password: '',
    name: '',
    universityGroup: '',
    educationYear: 1,
  })

  const handleChange = (val: any, name: string) => {
    setRegisterData((prev) => ({ ...prev, [name]: val }))
  }

  const handleRegister = () => {
    wait(authApi.register(registerData), (resp) => {
      if (resp.status === 'success') {
        eventBus.pub('ntf.message', { message: 'Вы успешно зарегестрировались!', type: 'info' })
        props.onRegistered()
      }
    })
  }

  return (
    <>
      <div className={cn('flex-form', styles.fields)}>
        <TextField header='Университетский e-mail'>
          <TextInput
            name='email'
            value={registerData.email}
            onChange={handleChange}
            placeholder='polyakov.egor@urfu.me'
            type='text'
            required={true}
          />
        </TextField>
        <TextField header='Пароль'>
          <TextInput
            name='password'
            value={registerData.password}
            onChange={handleChange}
            placeholder='secret-password-228'
            type='password'
            required={true}
            minLength={6}
          />
        </TextField>
        <TextField header='ФИО'>
          <TextInput
            name='name'
            value={registerData.name}
            onChange={handleChange}
            placeholder='Поляков Егор Валерьевич'
            required={true}
            minLength={6}
          />
        </TextField>
        <TextField header='Академическая группа'>
          <TextInput
            value={registerData.universityGroup}
            name='universityGroup'
            onChange={handleChange}
            placeholder='РИ-490003'
            minLength={6}
          />
        </TextField>
        <TextField header='Год обучения'>
          <RrojectEducationYearDropdown
            value={registerData.educationYear}
            onChange={handleChange}
            name='educationYear'
          />
        </TextField>
        {props.serverError && (
          <span className={cn('text--alert', 'text--body-xs', 'text-400', 'input--error')}>{props.serverError}</span>
        )}
      </div>
      <Button loading={loading} theme='primary' disabled={!props.valid} onClick={handleRegister} type='button'>
        Зарегестрироваться
      </Button>
      {props.children}
    </>
  )
}

export default RegisterForm
