import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react'

interface IProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  theme: 'primary' | 'grey' | 'danger' | 'grey-outline' | 'lightgreen'
  loading?: boolean
}

const Button: FC<IProps> = ({ children, theme, loading, ...props }) => {
  return (
    <button
      {...props}
      className={`etba-button ${props.className ?? ''}`}
      data-theme={theme}
      data-loading={loading ? 'true' : 'false'}
    >
      <span>{children}</span>
    </button>
  )
}

export default Button
