import { PropsWithChildren, useEffect, useLayoutEffect, useRef } from 'react'
import { useRecoilState } from 'recoil'
import authAtom from 'screens/Auth/auth.atom'
import { applyInterceptors } from 'services/http'
import authApi from 'screens/Auth/auth.api'
import AuthPage from 'screens/Auth/pages/AuthPage'
import useHttpLoader from '../../../../shared/hooks/useHttpLoader'
import LoaderPage from '../../../../shared/Loaders/LoaderPage/LoaderPage'

const AuthProvider = (props: PropsWithChildren) => {
  const { wait, loading } = useHttpLoader()

  const [authState, setAuthState] = useRecoilState(authAtom)
  const refState = useRef({ ...authState })

  useLayoutEffect(() => {
    refState.current = authState
  }, [authState])

  useEffect(() => {
    applyInterceptors(refState, setAuthState)

    wait(authApi.refreshToken(), (resp) => {
      refState.current.auth.accessToken = resp.auth.accessToken
      refState.current.auth.expires = resp.auth.expires
      setAuthState(resp)
    })
  }, [])

  if (loading) {
    return <LoaderPage inscription={`Кажется, вы тут уже были...\nПытаемся авторизоваться...`} />
  }

  if (!authState.auth.accessToken) {
    return <AuthPage />
  }

  return <>{props.children}</>
}

export default AuthProvider
