import { selector } from 'recoil'
import eventsAtom from './events.atom'
import { IEvent } from './events.types'

const eventsMap = selector({
  key: 'selectors/events-map',
  get: ({ get }) => {
    const eventsState = get(eventsAtom)

    return eventsState.items.reduce((acc, val) => ({ ...acc, [val.id]: val.name }), {})
  },
})

const eventsFullMap = selector({
  key: 'selectors/events-full-map',
  get: ({ get }): Record<IEvent['id'], IEvent> => {
    const eventsState = get(eventsAtom)

    return eventsState.items.reduce((acc, val) => ({ ...acc, [val.id]: val }), {})
  },
})

const eventsSelectors = { eventsMap, eventsFullMap }
export default eventsSelectors
