import { HTTPResponse, IPaginationResponse } from '../../services/http/http.types'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useHttpLoader from '../hooks/useHttpLoader'
import { ITableField } from './index'
import useSkipFirstRenderEffect from '../hooks/useSkipFirstRenderEffect'
import useSelect from '../hooks/useSelect'
import usePersistentState from '../hooks/usePersistentState'
import usePersistenceFields from './usePersistenceFields'
import useResizeColumnPluginCtrl from './plugins/resize/useResizeColumnPluginCtrl'
import tableRequestItemsPerPageService from './TableRequestItemsPerPageService/tableRequestItemsPerPage.service'

interface IProps<T extends Record<string, any>> {
  dataFetcher: (paginationParams: Record<string, string | number>) => Promise<HTTPResponse<IPaginationResponse<T>>>
  fields: ITableField<T, keyof T | string>[]
  itemKey: keyof T

  items: T[]
  setItems: Dispatch<SetStateAction<T[]>>
  tableKey: string

  forceLoad?: boolean
  serverSideOrder?: boolean
}

const useTableWithRequest = <T extends Record<string, any>>(props: IProps<T>) => {
  const { wait, loading } = useHttpLoader()

  const [fields, setFields] = usePersistenceFields(props.fields, props.tableKey)
  const resizePluginCtrl = useResizeColumnPluginCtrl({
    columns: fields,
    tableKey: props.tableKey,
    columnsSetter: setFields,
  })

  const [nextPage, setNextPage] = usePersistentState<string>(null, `${props.tableKey}/nextPage`)
  const [prevPage, setPrevPage] = usePersistentState<string>(null, `${props.tableKey}/prevPage`)

  const [page, setPage] = usePersistentState<number>(0, `${props.tableKey}/page`)
  const prevPageRef = useRef(page)

  const [loadedPage, setLoadedPage] = usePersistentState(null, `${props.tableKey}/loadedPage`, 'inMemory')
  const [visibleFields, setVisibleFields] = usePersistentState<Record<keyof T | string, boolean>>(
    getInitialFieldsVisibility(props.fields) as any,
    `${props.tableKey}/visibleFields`
  )

  const [itemsPerPage, setItemsPerPage] = usePersistentState<number>(50, `${props.tableKey}/itemsPerPage`)
  const [totalItemsCount, setTotalItemsCount] = usePersistentState<number>(0, `${props.tableKey}/totalItemsCount`)
  const selectedCtrl = useSelect<string>({ len: totalItemsCount, allKeys: props.items.map((it) => it[props.itemKey]) })

  // 0 - sort disabled, 1 - sort by asc, -1 = sort by desc
  const [orderingState, setOrderingState] = useState<Partial<Record<keyof T, -1 | 0 | 1>>>({})

  const orderingQuery = useMemo(() => {
    if (!props.serverSideOrder) return ''

    let orderingQuery = ''
    for (const field of props.fields) {
      if (orderingState[field.key] !== undefined && !field.clientSideOrdering && orderingState[field.key] !== 0) {
        orderingQuery += (orderingState[field.key] === 1 ? '' : '-') + field.key.toString()
      }
    }

    return orderingQuery
  }, [orderingState, props.serverSideOrder])

  const orderedItems = useMemo(() => {
    if (props.serverSideOrder && !props.fields.some((f) => f.clientSideOrdering)) {
      return props.items
    }

    const res = [...props.items]
    if (Object.values(orderingState).every((s) => s === undefined || s === 0)) return res

    res.sort((a, b) => {
      let result: number

      for (const field of props.fields) {
        if (props.serverSideOrder && !field.clientSideOrdering) continue

        if (orderingState[field.key] !== undefined && orderingState[field.key] !== 0) {
          result ||= comparator(
            field.sortGetter(a, field.key),
            field.sortGetter(b, field.key),
            orderingState[field.key] === 1 ? 'asc' : 'desc'
          )
        }
      }

      if (result === undefined) return 0
      return result
    })

    return res
  }, [orderingState, props.items, props.serverSideOrder])

  const visibleFieldsArr = useMemo(() => {
    return fields.filter((f) => visibleFields[f.key])
  }, [fields, visibleFields])

  useSkipFirstRenderEffect(() => {
    wait(props.dataFetcher({ limit: itemsPerPage, offset: 0, ordering: orderingQuery }), (resp) => {
      if (resp.status === 'success') {
        setTotalItemsCount(resp.body.totalCount)
        setLoadedPage(0)
        props.setItems(resp.body.items)

        setNextPage(resp.body.nextPage)
        setPrevPage(resp.body.prevPage)
      }
    })
  }, [props.dataFetcher, itemsPerPage, orderingQuery])

  useEffect(() => {
    if ((loading || page === loadedPage) && !(totalItemsCount > 0 && props.items.length === 0) && !props.forceLoad) {
      return
    }

    let nextToken = nextPage
    if (prevPageRef.current - page > 0) nextToken = prevPage

    wait(props.dataFetcher({ limit: itemsPerPage, nextPage: nextToken }), (resp) => {
      if (resp.status === 'success') {
        setLoadedPage(page)
        setTotalItemsCount(resp.body.totalCount)

        setNextPage(resp.body.nextPage)
        setPrevPage(resp.body.prevPage)

        props.setItems(resp.body.items)
      }
    })
  }, [page])

  const handleSwap = useCallback(<K extends keyof T>(target: K, dropped: K) => {
    setFields((prev) => {
      const arr = [...prev]
      const targetIndex = arr.findIndex((e) => e.key === target)
      const droppedIndex = arr.findIndex((e) => e.key === dropped)
      if (targetIndex === droppedIndex) return prev

      if (targetIndex === -1 || droppedIndex === -1) return prev

      if (targetIndex > droppedIndex) {
        return [
          ...arr.slice(0, droppedIndex),
          ...arr.slice(droppedIndex + 1, targetIndex),
          arr[targetIndex],
          arr[droppedIndex],
          ...arr.slice(targetIndex + 1),
        ]
      }

      return [
        ...arr.slice(0, targetIndex),
        arr[droppedIndex],
        ...arr.slice(targetIndex).filter((it) => it.key !== dropped),
      ]
    })
  }, [])

  const handleVisibilityChange = useCallback(
    (key: keyof T | string, value: boolean) => {
      setVisibleFields({ ...visibleFields, [key]: value })
    },
    [visibleFields]
  )

  const handleItemsPerPageChange = async (val: number | 'custom') => {
    if (typeof val === 'number') {
      setItemsPerPage(val)
    } else {
      const resp = await tableRequestItemsPerPageService.request()
      if (resp.status === 'entered') {
        setItemsPerPage(resp.value)
      }
    }
  }

  return {
    loading,
    orderedItems,
    itemsPerPage,
    onItemsPerPageChange: handleItemsPerPageChange,
    pageNumber: page,
    onPageNumberChange: setPage,
    totalItemsCount,
    orderingState,
    onOrderingStateChange: setOrderingState,
    selectedCtrl,
    fields: visibleFieldsArr,
    onSwap: handleSwap,
    visibleFields,
    allFields: fields,
    handleVisibilityChange,
    resizePluginCtrl,
  }
}

const comparator = (a: any, b: any, sort: 'asc' | 'desc'): number => {
  if (typeof a === 'string' && typeof b === 'string') {
    if (sort === 'desc') return b.localeCompare(a)
    return a.localeCompare(b)
  }

  if (typeof a === 'number' && typeof b === 'number') {
    if (sort === 'desc') return b - a
    return a - b
  }

  return 0
}

const getInitialFieldsVisibility = <T extends Record<string, any>>(
  fields: ITableField<T, keyof T | string>[]
): Record<keyof T | string, boolean> => {
  const state: Record<keyof T | string, boolean> = {} as any
  for (const f of fields) state[f.key] = true

  return state
}

export default useTableWithRequest
