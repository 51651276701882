const isPrimitive = (val: any) => {
  return !(typeof val == 'object' || typeof val == 'function')
}

const flatObject = (obj: Record<string, any>) => {
  const flatten = {}

  const walk = (objectPart: Record<string, any>) => {
    for (const key in objectPart) {
      if (isPrimitive(objectPart[key])) {
        flatten[key] = objectPart[key]
      } else if (!Array.isArray(objectPart[key])) {
        walk(objectPart[key])
      } else {
        flatten[key] = objectPart[key][0]
      }
    }
  }
  walk(obj)

  return flatten
}

const flatObjectWithDots = (obj: Record<string, any>) => {
  function traverseAndFlatten(currentNode, target, flattenedKey?) {
    for (const key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
        let newKey
        if (flattenedKey === undefined) {
          newKey = key
        } else {
          newKey = flattenedKey + '.' + key
        }

        const value = currentNode[key]
        if (value !== null && typeof value === 'object' && !(value instanceof File)) {
          traverseAndFlatten(value, target, newKey)
        } else {
          target[newKey] = value
        }
      }
    }
  }

  const flattenedObject = {}
  traverseAndFlatten(obj, flattenedObject)
  return flattenedObject
}

const flattenToObject = (object: Record<string, any>) => {
  const originalObject = {}

  for (const key in object) {
    const path = key.split('.')

    for (let i = 0, currentDepth = originalObject; i < path.length; ++i) {
      if (i === path.length - 1) {
        currentDepth[path[i]] = object[key]
        break
      }

      if (!currentDepth[path[i]]) currentDepth[path[i]] = {}
      currentDepth = currentDepth[path[i]]
    }
  }

  return originalObject
}

const objectPatchDiff = <T extends Record<string, any>>(updated: T, prev: T): Partial<T> => {
  const diff = {}
  const flattedUpdated = flatObjectWithDots(updated)
  const flattenPrev = flatObjectWithDots(prev)

  for (const key in flattedUpdated) {
    if (flattedUpdated[key] !== flattenPrev[key]) diff[key] = flattedUpdated[key]
  }

  return flattenToObject(diff)
}

const generateFieldFilter = (fieldValue: number | string, key: string) => {
  return (value: Record<string, any>) => fieldValue === value[key]
}

const generateExcludeFieldFilter = (fieldValue: number | string, key: string) => {
  return (value: Record<string, any>) => fieldValue !== value[key]
}

const stringDateToObject = (date: string) => {
  if (!date) return null

  try {
    return new Date(date)
  } catch {
    return null
  }
}

const globalUtils = {
  flatObject,
  isPrimitive,
  generateFieldFilter,
  generateExcludeFieldFilter,
  flatObjectWithDots,
  objectPatchDiff,
  stringDateToObject,
}
export default globalUtils
