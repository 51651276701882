import AdminNavigationLayout from 'shared/Layouts/TopNavigationLayout/AdminNavifationLayout'
import UserSkillsFilters from './componenets/UserSkillsFilters'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import UserSkillsTable from './componenets/UserSkillsTable'
import Button from 'shared/Buttons/Button/Button'
import { useNavigate } from 'react-router-dom'

const UserSKillsPage = () => {
  const navigate = useNavigate()

  return (
    <AdminNavigationLayout>
      <TopNavigationLayoutPageContent type='default'>
        <div className='flex-space-between'>
          <div className='inline-flex-gap' style={{ gap: '2.5em' }}>
            <h2 className='page--header'>Компетенции</h2>
          </div>
          <div className='inline-flex-gap'>
            <Button theme='primary' onClick={() => navigate('/admin/skills/create')}>
              <span className='inline-flex-gap' style={{ gap: '0.5em' }}>
                <span>+</span>
                <span>Создать</span>
              </span>
            </Button>
          </div>
        </div>
        <UserSkillsFilters />
        <UserSkillsTable />
      </TopNavigationLayoutPageContent>
    </AdminNavigationLayout>
  )
}

export default UserSKillsPage
