import SearchInput from 'shared/Inputs/SearchInput'
import userSkillsTableAtom from '../UserSkillsTable/userSkillsTable.atom'
import { useRecoilState } from 'recoil'

const UserSkillsFilters = () => {
  const [state, setState] = useRecoilState(userSkillsTableAtom)

  const handleChange = (value: any, name: string) => {
    setState((prev) => ({ ...prev, filters: { ...prev.filters, [name]: value } }))
  }

  return (
    <div className='inline-flex-gap' style={{ gap: '8px' }}>
      <SearchInput
        value={state.filters.search}
        name='search'
        onChange={handleChange}
        required={false}
        placeholder='Поиск'
        autofocus={true}
      />
    </div>
  )
}

export default UserSkillsFilters
