import { AnyObject } from '../types/global'

/**
 * Сравнение двух объектов. Работает только с сериализируемыми структурами (примитивы, массивы, объекты)
 */
export const isJsonEqual = (a: any, b: any) => {
  let ta: string = typeof a
  let tb: string = typeof b

  if (a === null) ta = 'null'
  if (b === null) tb = 'null'
  if (Array.isArray(a)) ta = 'array'
  if (Array.isArray(b)) tb = 'array'

  if (ta !== tb) return false

  const isPrimitive = ta === 'number' || ta === 'string' || ta === 'undefined' || ta === 'null' || ta === 'boolean'
  if (isPrimitive) return a === b

  if (ta === 'array') {
    if (a.length !== b.length) return false
    return a.every((value, i) => isJsonEqual(value, b[i]))
  }

  // objects
  const keys1 = Object.keys(a)
  const keys2 = Object.keys(b)
  if (keys1.length !== keys2.length) return false

  return keys1.every((key) => isJsonEqual(a[key], b[key]))
}

/**
 * Копирование сериализируемых структур (примитивы, массивы, объекты)
 */
export const deepCopy = <T>(value: T): T => {
  if (!value) return value

  if (Array.isArray(value)) {
    return value.map((v) => deepCopy(v)) as any
  }

  if (typeof value === 'object') {
    const copy = { ...value }

    for (const [k, v] of Object.entries(value)) {
      copy[k] = deepCopy(v)
    }

    return copy
  }

  return value
}

/**
 * Создать объект включающий только измененные поля
 * a - начальное состояние объекта, b - состояние после редактирования
 */
export const objectDiff = (a: any, b: any) => {
  const result = {}

  for (const key of Object.keys(b)) {
    const aValue = a[key]
    const bValue = b[key]

    if (bValue !== undefined && !isJsonEqual(aValue, bValue)) {
      result[key] = bValue
    }
  }

  return result
}

export const mergeObjects = (obj1: AnyObject, obj2: AnyObject) => {
  for (const p in obj2) {
    try {
      if (typeof obj2[p] === 'object' && obj2[p] !== null && !Array.isArray(obj2[p])) {
        obj1[p] = mergeObjects(obj1[p], obj2[p])
      } else {
        obj1[p] = obj2[p]
      }
    } catch (e) {
      obj1[p] = obj2[p]
    }
  }

  return obj1
}
