import { RefObject, useEffect, useState, useCallback } from 'react'

interface IProps {
  ref: RefObject<HTMLElement>
}

const useMouseMove = ({ ref }: IProps) => {
  const [ev, setEv] = useState(null)
  const [startEv, setStartEv] = useState(null)

  useEffect(() => {
    ref && ref.current && ref.current.addEventListener('mousedown', handleMouseDown)
    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      removeEventListeners()
    }
  }, [ref])

  const removeEventListeners = useCallback((ev?) => {
    if (ev) {
      ev.preventDefault()
      ev.stopPropagation()
    }

    setStartEv(null)
    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', removeEventListeners)
  }, [])

  const handleMouseDown = useCallback((ev) => {
    ev.preventDefault()
    ev.stopPropagation()

    setEv(ev)
    setStartEv(ev)
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', removeEventListeners)
  }, [])

  const handleMouseMove = useCallback((ev: MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    setEv(ev)
  }, [])

  return {
    mouseMove: ev as MouseEvent,
    stopMove: removeEventListeners,
    startEv: startEv as MouseEvent,
  }
}

export default useMouseMove
