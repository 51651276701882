import { IFullTeam, ITeam, ITeamInvite } from './teams.types'
import { HTTPResponse, IPaginationResponse } from '../../services/http/http.types'
import http, { handleHttpError, handleHttpResponse } from '../../services/http'
import { IUser } from '../Admin/RolesAndUsers/users.types'

interface IGetParams {
  search?: string
  limit?: number
  nextPage?: string
}

const list = async (params: IGetParams): Promise<HTTPResponse<IPaginationResponse<IFullTeam>>> => {
  return http.get('/nsi/v1/teams', { params }).then(handleHttpResponse).catch(handleHttpError)
}

const createTeam = async (
  dto: Pick<ITeam, 'name'> & { ownerId?: IUser['id']; maxCapacity?: number }
): Promise<HTTPResponse<IFullTeam>> => {
  return http.post('/nsi/v1/teams', dto).then(handleHttpResponse).catch(handleHttpError)
}

const getFullTeam = async (id: ITeam['id']): Promise<HTTPResponse<IFullTeam>> => {
  return http
    .get('/nsi/v1/teams/' + id)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const inviteIntoTeam = async (teamId: ITeam['id'], userId: IUser['id']): Promise<HTTPResponse<any>> => {
  return http.post(`/nsi/v1/teams/${teamId}/invite`, { userId }).then(handleHttpResponse).catch(handleHttpError)
}

const acceptInvite = async (inviteId: ITeamInvite['id']): Promise<HTTPResponse<any>> => {
  return http.post(`/nsi/v1/teams/invite/${inviteId}/accept`).then(handleHttpResponse).catch(handleHttpError)
}

const cancelInvite = async (inviteId: ITeamInvite['id']): Promise<HTTPResponse<any>> => {
  return http.post(`/nsi/v1/teams/invite/${inviteId}/cancel`).then(handleHttpResponse).catch(handleHttpError)
}

const setTeamMembers = async (teamId: ITeam['id'], members: Array<IUser['id']>): Promise<HTTPResponse<IFullTeam>> => {
  return http
    .put(`/nsi/v1/teams/${teamId}/team-members`, { users: members })
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const deleteTeam = (id: ITeam['id']): Promise<HTTPResponse<IFullTeam>> => {
  return http
    .delete('/nsi/v1/teams/' + id)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const teamsApi = {
  list,
  createTeam,
  getFullTeam,
  inviteIntoTeam,
  acceptInvite,
  cancelInvite,
  setTeamMembers,
  deleteTeam,
}
export default teamsApi
