import { atom } from 'recoil'
import { IUser } from '../../../RolesAndUsers/users.types'

interface IStudentsTableAtomState {
  items: IUser[]
  filters: {
    search?: string
  }
}
const usersTableAtom = atom<IStudentsTableAtomState>({
  key: 'admin/tables/users',
  default: { items: [], filters: { search: '' } },
})
export default usersTableAtom
