import { NavLink } from 'react-router-dom'
import { CSSProperties, memo } from 'react'
import useNavigationLink from './useNavigationLink'
import ProtectedContent from '../../../screens/Auth/components/ProtectedContent'
import { IRoute } from '../../navigation.types'

interface IProps {
  route: IRoute
  style?: CSSProperties
  className?: string
}

const NavigationLink = (props: IProps) => {
  const { className, isActive, isNested, isLastChild } = useNavigationLink(props.route)

  const renderIcon = () => {
    if (isNested) return null

    return props.route.icon ? props.route.icon : null
  }

  return (
    <ProtectedContent permissions={props.route.permissions || []}>
      <NavLink
        to={props.route.path}
        className={`${className} ${props.className || ''}`}
        style={props.style}
        data-active={isActive}
        data-nested={isNested}
        data-lastchild={isLastChild}
      >
        {renderIcon()}
        {props.route.title}
      </NavLink>
    </ProtectedContent>
  )
}

export default memo(NavigationLink)
