import Modal from 'shared/Modal'
import ModalHeader from 'shared/Modal/ModalHeader'
import { IUser } from 'screens/Admin/RolesAndUsers/users.types'
import InviteStudentContent from '../../InviteStudentContent'

interface IProps {
  onSelect: (user: IUser) => void
  onClose: () => void
}

const SelectUserModal = (props: IProps) => {
  return (
    <Modal onClose={props.onClose} size='hg' shouldAnimate={false}>
      <ModalHeader onClose={props.onClose}>Выбор студента</ModalHeader>
      <div className='modal-content flex-lines' style={{ height: '60vh' }}>
        <InviteStudentContent team={null} onClick={props.onSelect} />
      </div>
    </Modal>
  )
}

export default SelectUserModal
