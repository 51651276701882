import { createContext } from 'react'

export interface IAuthValues {
  email: string
  password: string
  rememberMe: boolean
}

export const generateEmptyAuthState = (): IAuthValues => ({
  email: '',
  password: '',
  rememberMe: true,
})

const authContext = createContext<{
  values: IAuthValues
  onChange: (value: any, name: string) => void
}>({
  values: generateEmptyAuthState(),
  onChange: () => {},
})

export default authContext
