import Sheet from '../../../../../../shared/Sheet'
import TextField from '../../../../../../shared/Forms/TextField'
import TextInput from '../../../../../../shared/Inputs/TextInput'
import { useState } from 'react'
import { ITeam } from '../../../../teams.types'
import Button from '../../../../../../shared/Buttons/Button/Button'
import useHttpLoader from '../../../../../../shared/hooks/useHttpLoader'
import teamsApi from '../../../../teams.api'
import { useSetRecoilState } from 'recoil'
import selfTeamsAtom from '../../../../../Auth/components/InitProvider/selfTeams.atom'

interface IProps {
  onCreate: (team: ITeam) => void
}

const CreateTeamSheet = (props: IProps) => {
  const setSelfTeamsState = useSetRecoilState(selfTeamsAtom)

  const { wait, loading } = useHttpLoader()
  const [team, setTeam] = useState<Pick<ITeam, 'name'>>({ name: '' })

  const handleChange = (val: any, name: string) => {
    setTeam((prev) => ({ ...prev, [name]: val }))
  }

  const handleCreate = () => {
    wait(teamsApi.createTeam(team), (resp) => {
      if (resp.status === 'success') {
        setSelfTeamsState((prev) => ({
          ...prev,
          items: [{ ...resp.body, ownerId: resp.body.owner.id }, ...prev.items],
        }))
        props.onCreate(resp.body)
      }
    })
    props.onCreate(null)
  }

  return (
    <Sheet>
      <div className='flex-lines'>
        <TextField header='Наименование команды (от 5 символов)'>
          <TextInput value={team.name} name='name' onChange={handleChange} minLength={5} placeholder='БРИКС' />
        </TextField>
        <div className='form-footer'>
          <Button
            theme='primary'
            onClick={handleCreate}
            style={{ width: 'fit-content' }}
            disabled={team.name.length < 5}
            loading={loading}
          >
            Создать
          </Button>
        </div>
      </div>
    </Sheet>
  )
}

export default CreateTeamSheet
