/**
 * Подсветка совпадений поиска
 */
const highlightSearch = (text: string, searchTerms: string[]) => {
  if (!searchTerms || !searchTerms.length) return { isMatch: false, result: text }

  const result = search(text, searchTerms)
  if (!result.length) return { isMatch: false, result: text }

  return { isMatch: true, result: renderSearchResult(result) }
}

export const matchSearch = (text: string, searchTerms: string[]): boolean => {
  const result = search(text, searchTerms)
  return result.length > 0
}

/**
 * Найти все items в строке text и предоставить результат в виде исходной строки
 * разбитой на части с отмеченными совпадениями
 */
const search = (text: string, items: string[]): SearchResult[] => {
  const result = [{ text, match: false }]

  for (const item of items) {
    let found = false

    for (let i = 0; i < result.length; i++) {
      const part = result[i]
      if (part.match) continue

      const j = part.text.toLowerCase().indexOf(item)

      if (j !== -1) {
        const prefix = part.text.slice(0, j)
        const target = part.text.slice(j, j + item.length)
        const suffix = part.text.slice(j + item.length)

        const replacement = []
        if (prefix !== '') replacement.push({ text: prefix, match: false })
        if (target !== '') replacement.push({ text: target, match: true })
        if (suffix !== '') replacement.push({ text: suffix, match: false })

        result.splice(i, 1, ...replacement)
        found = true
        break
      }
    }

    // one of the search items was not found
    if (!found) return []
  }

  return result
}

/**
 * Превратить массив из результатов совпадений в массив html элементов
 * и добавить класс подсвечивающий совпадения
 */
const renderSearchResult = (result: SearchResult[], fallback?: string) => {
  if (!result || !result.length) return fallback

  const parts = result.map((part, i) => {
    const className = part.match === true ? 'search-result is-match' : 'search-result'

    return (
      <span className={className} key={i}>
        {part.text}
      </span>
    )
  })

  return <span className="search-results">{parts}</span>
}

export interface SearchResult {
  text: string
  match: boolean
}

const searchUtils = { highlightSearch, search, renderSearchResult }
export default searchUtils
