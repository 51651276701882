import { IUser } from '../Admin/RolesAndUsers/users.types'
import http, { handleHttpError, handleHttpResponse } from '../../services/http'
import { HTTPResponse } from '../../services/http/http.types'
import { IUserSkill } from '../Admin/UserSkills/userSkills.types'
import { ITeam } from '../Teams/teams.types'

const updateSelfInfo = async (info: Partial<IUser['info']>): Promise<HTTPResponse<IUser['info']>> => {
  return http.patch('/nsi/v1/self/settings', info).then(handleHttpResponse).catch(handleHttpError)
}

const updateSelfSkills = async (skills: Array<IUserSkill['id']>) => {
  return http.patch('/nsi/v1/self/skills', { skills }).then(handleHttpResponse).catch(handleHttpError)
}

const leaveTeam = async (id: ITeam['id'], newOwnerId?: IUser['id']): Promise<HTTPResponse<any>> => {
  const body = newOwnerId ? { newOwnerId } : {}

  return http
    .patch('/nsi/v1/self/team/' + id + '/leave', body)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const updateSelfImage = async (file: File) => {
  const formData = new FormData()
  formData.set('image', file)

  return http.put('/nsi/v1/self/image', formData).then(handleHttpResponse).catch(handleHttpError)
}

const selfApi = { updateSelfInfo, updateSelfSkills, leaveTeam, updateSelfImage }
export default selfApi
