import Sheet from 'shared/Sheet'
import { IUserSkill } from '../../userSkills.types'
import TextField from 'shared/Forms/TextField'
import TextInput from 'shared/Inputs/TextInput'
import { PropsWithChildren } from 'react'

interface IProps {
  skill: Omit<IUserSkill, 'id'>
  onChange: (val: any, name: string) => void
}

const SkillSheet = (props: PropsWithChildren<IProps>) => {
  return (
    <Sheet>
      <div className='grid-form-2'>
        <TextField header='Наименование'>
          <TextInput value={props.skill.name} onChange={props.onChange} name='name' placeholder='React' />
        </TextField>
      </div>
      <div className='form-footer'>{props.children}</div>
    </Sheet>
  )
}

export default SkillSheet
