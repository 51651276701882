import { CSSProperties, useEffect, useState } from 'react'
import TextInput from '../TextInput'

interface IProps {
  value: number
  onChange: (val: number, name: string) => void
  name: string
  disabled?: boolean
  style?: CSSProperties
  inputStyle?: CSSProperties
  label?: string
  className?: string
}

const NumberInput = (props: IProps) => {
  const [proxyValue, setProxyValue] = useState(props.value.toString() || '0')

  useEffect(() => {
    setProxyValue(props.value.toString())
  }, [props.value])

  const handleBlur = () => {
    if (Number.isNaN(+proxyValue)) {
      setProxyValue(props.value.toString() || '0')
    } else {
      props.onChange(+proxyValue, props.name)
    }
  }

  return (
    <TextInput
      name={props.name}
      value={proxyValue}
      onChange={setProxyValue}
      onBlur={handleBlur}
      style={props.style}
      className={props.className}
    />
  )
}

export default NumberInput
