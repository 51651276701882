import { ChangeEvent, useRef, useState } from 'react'
import { ReactComponent as TrashIcon } from '../../../../assets/icons/icon-trash.svg'
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/icon-download.svg'
import { IUser } from 'screens/Admin/RolesAndUsers/users.types'
import useHttpLoader from 'shared/hooks/useHttpLoader'
import usersUtils from 'screens/Users/users.utilts'
import { useRecoilValue } from 'recoil'
import authAtom from 'screens/Auth/auth.atom'
import Image from 'shared/Image'
import domUtils from 'utils/dom.utils'
import selfApi from 'screens/Profile/self.api'
import eventBus from 'services/eventBus'
import Card from 'shared/Cards/Card'
import noImage from '../../../../assets/images/defaultAvatar.jpg'

interface IProps {
  userId: IUser['id']
}

const UserPhotoCard = (props: IProps) => {
  const userId = useRecoilValue(authAtom).user.id
  const editable = props.userId === userId

  const { wait, loading } = useHttpLoader()
  const inputRef = useRef<HTMLInputElement>()
  const [file, setFile] = useState(null)

  let imageSrc = usersUtils.generateUserImageURL(props.userId)
  if (file) imageSrc = URL.createObjectURL(file)
  if (!file && !props.userId) imageSrc = `data:image/svg+xml;utf8,${noImage.toString()}`

  const handleClick = () => {
    inputRef.current.click()
  }

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (!ev.target.files[0]) return setFile(null)

    wait(selfApi.updateSelfImage(ev.target.files[0]), (resp) => {
      if (resp.status === 'success') {
        eventBus.pub('ntf.message', { type: 'info', message: 'Изображение обновлено' })
        setFile(ev.target.files[0])
      }
    })
  }

  const handleDelete = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()

    setFile(null)
  }

  const handleDownload = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()

    if (!file) {
      const link = document.createElement('a')
      link.setAttribute('download', 'photo')
      link.setAttribute('href', imageSrc)
      link.click()
    } else {
      domUtils.openLink(imageSrc)
    }
  }

  return (
    <Card type='normal' style={{ height: '225px', width: '325px' }}>
      <div className='flex-lines'>
        <h2 className='text--bold text--caption-l' style={{ textAlign: 'center' }}>
          Фото / Аватар
        </h2>
        <div
          className='flex-lines'
          style={{ position: 'relative', cursor: 'pointer', gap: '8px' }}
          onClick={handleClick}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Image
              src={imageSrc}
              alt='Campaign image'
              loading={loading}
              style={{
                width: '100px',
                height: '100px',
                border: '1px solid var(--gray)',
                borderRadius: '50%',
              }}
              defaultImageSrc={noImage}
            />
          </div>
          {editable && (
            <input
              type='file'
              style={{ width: 0, height: 0, visibility: 'hidden' }}
              ref={inputRef}
              accept='image/*'
              onChange={handleChange}
            />
          )}
          {editable && (
            <div
              className='inline-flex-gap'
              style={{
                justifyContent: 'center',
                width: '100%',
                gap: '16px',
              }}
            >
              <TrashIcon onClick={handleDelete} />
              <DownloadIcon onClick={handleDownload} />
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default UserPhotoCard
