import { atom } from 'recoil'
import { ITeam } from 'screens/Teams/teams.types'

interface IStudentsTableAtomState {
  items: ITeam[]
  filters: {
    search?: string
  }
}
const teamsTableAtom = atom<IStudentsTableAtomState>({
  key: 'tables/teams',
  default: { items: [], filters: { search: '' } },
})
export default teamsTableAtom
