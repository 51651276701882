import { atom } from 'recoil'
import { IOrganization } from './organizations.types'

interface IOrganizationsAtom {
  items: IOrganization[]
}

const organizationsAtom = atom<IOrganizationsAtom>({
  key: 'organizations',
  default: { items: [] },
})
export default organizationsAtom
