import { useRecoilValue } from 'recoil'
import { useMemo } from 'react'
import selfUserAtom from 'screens/Auth/selfUser.atom'

const useUserPermissionsSet = () => {
  const selfUserState = useRecoilValue(selfUserAtom)

  const permissions = selfUserState.accessRights.map((accessRight) => accessRight.id).flat()

  return useMemo(() => {
    return new Set(permissions)
  }, [selfUserState.accessRights])
}

export default useUserPermissionsSet
