import { ReactComponent as Options } from '../../../assets/icons/threeDots.svg'
import css from './actionsButton.module.css'
import { CSSProperties, forwardRef, PropsWithChildren } from 'react'

const ActionsButton = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ onClick?: () => void; active?: boolean; style?: CSSProperties }>
>((props, ref) => {
  return (
    <div className={css.wrapper} ref={ref} onClick={props.onClick} data-active={props.active} style={props.style}>
      <Options style={{ width: '21px', height: '15px' }} />
      {props.children}
    </div>
  )
})

export default ActionsButton
