import { ResizeColumnPluginCtrl } from './useResizeColumnPluginCtrl'
import { useRef, useState } from 'react'
import useMouseMove from '../../../hooks/useMouseMove'
import useSkipFirstRenderEffect from '../../../hooks/useSkipFirstRenderEffect'

const useResizeColumnPluginTableThCtrl = (props: ResizeColumnPluginCtrl, columnKey: string) => {
  const resizeElementRef = useRef<HTMLDivElement>()
  const mouseMoveCtrl = useMouseMove({ ref: resizeElementRef })

  const [isResizing, setIsResizing] = useState(false)

  useSkipFirstRenderEffect(() => {
    if (!mouseMoveCtrl.mouseMove || !mouseMoveCtrl.startEv) {
      setIsResizing(false)
      return
    }

    setIsResizing(true)
    props.handleMouseMove(mouseMoveCtrl.startEv, mouseMoveCtrl.mouseMove, columnKey)
  }, [mouseMoveCtrl.mouseMove, mouseMoveCtrl.startEv])

  return { resizeElementRef, isResizing }
}

export default useResizeColumnPluginTableThCtrl
