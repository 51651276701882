import { useState } from 'react'
import authApi from '../auth.api'
import useHttpLoaderWithServerError from '../../../shared/hooks/useHttpLoaderWithServerError'
import { generateEmptyAuthState } from '../auth.context'
import useValidationForm from '../../../shared/Forms/ValidationForm/useValidationForm'
import authAtom from '../auth.atom'
import { useSetRecoilState } from 'recoil'

const useAuthCtrl = () => {
  const { wait, loading, serverError } = useHttpLoaderWithServerError()
  const [authValues, setAuthValues] = useState(generateEmptyAuthState())
  const setAuthState = useSetRecoilState(authAtom)

  const handleChange = (value: any, name: string) => {
    setAuthValues((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmitCredentials = () => {
    wait(
      authApi.loginWithCredentials({
        email: authValues.email,
        password: authValues.password,
        rememberMe: authValues.rememberMe,
      }),
      (resp) => {
        if (resp.status === 'success') {
          setAuthState(resp.body)
        }
      }
    )
  }

  const validationCtrl = useValidationForm()

  return {
    validationCtrl,
    handleSubmitCredentials,
    handleChange,
    authValues,
    loading,
    serverError,
  }
}

export default useAuthCtrl
