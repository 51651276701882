import { selector } from 'recoil'
import organizationsAtom from './organizations.atom'

const organiaztionsMap = selector({
  key: 'selectors/organizations-map',
  get: ({ get }) => {
    const state = get(organizationsAtom)

    return state.items.reduce((acc, val) => ({ ...acc, [val.id]: val }), {})
  },
})

const organizationsSelectors = { organiaztionsMap }
export default organizationsSelectors
