import Card, { CardType } from '../Card'
import { PropsWithChildren } from 'react'
import css from '../card.module.css'

interface IProps {
  type: CardType
  height: string
  width: string
  loading: boolean
}

const SkeletonCard = (props: PropsWithChildren<IProps>) => {
  if (props.loading) {
    return <div className={css.card} data-skeleton={true} style={{ width: props.width, height: props.height }} />
  }

  return <Card type={props.type}>{props.children}</Card>
}

export default SkeletonCard
