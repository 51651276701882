import EditButton from '../../../../shared/Buttons/EditButton'
import Card from '../../../../shared/Cards/Card'
import { useMemo, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import userSkillsSelectors from '../../../Admin/UserSkills/userSkills.selectors'
import authAtom from '../../../Auth/auth.atom'
import TwoListSelect from '../../../../shared/TwoListSelect'
import userSkillsAtom from '../../../Admin/UserSkills/userSkills.atom'
import Button from '../../../../shared/Buttons/Button/Button'
import useHttpLoader from '../../../../shared/hooks/useHttpLoader'
import selfApi from '../../self.api'
import css from './selfSkills.module.css'

const SelfSkillsCard = () => {
  const { wait, loading } = useHttpLoader()
  const skillsList = useRecoilValue(userSkillsAtom).items
  const skillsMap = useRecoilValue(userSkillsSelectors.skillsMap)
  const selfSkills = useRecoilValue(authAtom).user.skills
  const setAuthState = useSetRecoilState(authAtom)

  const [mode, setMode] = useState<'view' | 'edit'>('view')
  const [draftSelfSkills, setDraftSelfSkills] = useState(selfSkills)

  const skillsOptions = useMemo(() => {
    return skillsList.map((s) => ({ title: s.name, value: s.id }))
  }, [skillsList])

  const handleSave = () => {
    wait(selfApi.updateSelfSkills(draftSelfSkills), (resp) => {
      if (resp.status === 'success') {
        setAuthState((prev) => ({ ...prev, user: { ...prev.user, skills: draftSelfSkills } }))
        setMode('view')
      }
    })
  }

  const renderSkills = () => {
    if (mode === 'edit') {
      return (
        <div className='flex-lines'>
          <TwoListSelect
            options={skillsOptions}
            selected={draftSelfSkills}
            titleLeft='Ваши компетенции'
            titleRight='Все компетенеции'
            onSelect={(val) => setDraftSelfSkills((prev) => [...val, ...prev])}
            onUnselect={(val) => setDraftSelfSkills((prev) => prev.filter((v) => !val.includes(v)))}
          />
          <div className='form-footer'>
            <div className='inline-flex-gap'>
              <Button theme='primary' style={{ width: 'fit-content' }} onClick={handleSave} loading={loading}>
                Сохранить
              </Button>
              <Button
                theme='grey-outline'
                style={{ width: 'fit-content' }}
                onClick={() => setMode('view')}
                disabled={loading}
              >
                Отмена
              </Button>
            </div>
          </div>
        </div>
      )
    }

    if (!selfSkills.length) {
      return (
        <div className='text--body-m flex-lines' style={{ gap: '6px' }}>
          <p>Вы еще не указали ни одной компетенции 😲</p>
          <p>Нажмите на карандаш в правом верхнем углу карточки для заполнения информации. Мир должен знать о вас 💚</p>
        </div>
      )
    }

    return (
      <div className='flex-lines' style={{ gap: '12px' }}>
        {selfSkills.map((s) => (
          <div className='inline-flex-gap'>
            <div className={css.skillDot} />
            <p key={s} className='text--body-m'>
              {skillsMap[s]}
            </p>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Card type='normal'>
      <div className='flex-lines'>
        <div className='flex-space-between' style={{ marginBottom: '16px' }}>
          <h2 className='text--caption-l text-700'>Мои компетенции</h2>
          {mode !== 'edit' && <EditButton onClick={() => setMode('edit')} />}
        </div>
        {renderSkills()}
      </div>
    </Card>
  )
}

export default SelfSkillsCard
