import useHttpLoader from '../../shared/hooks/useHttpLoader'
import { useRecoilValue } from 'recoil'
import { useEffect, useState } from 'react'
import { EntityRecoilState, EntityWithId, IUseEntityProps } from './useEntity.types'
import httpCache from '../../services/http/utils/http.cache'
import useSkipFirstRenderEffect from '../../shared/hooks/useSkipFirstRenderEffect'

const useEntity = <T extends EntityWithId>(props: IUseEntityProps<T>) => {
  const { wait, loading } = useHttpLoader()

  const state = useRecoilValue(props.atom)
  const [entity, setEntity] = useState(getInitialState(state, props))

  /*  // При forceLoad === true объект сущности храниться в локальном стейте (useState).
  // Его обновление через recoil state по общим правилам невозможно,
  // поэтому есть специальный event, сообщающий об обновлении сущности.
  useEffect(() => {
    const unsub = eventBus.sub('useEntity.update', (message) => {
      if (props.atom === message.atom && props.entityId === message.entityId) {
        setEntity((prev) => ({ ...prev, ...message.updates }))
      }
    })

    return () => {
      unsub()
    }
  }, [props.atom])*/

  useEffect(() => {
    if (!props.withHttpCache) return

    httpCache.updateCachedValue({ status: 'success', body: entity }, props.itemFetcher, props.entityId)
  }, [props.withHttpCache, entity])

  useEffect(() => {
    if (props.forceLoad) return

    const item = state.items.find((it) => it.id === props.entityId)

    if (item) setEntity(item)
  }, [state.items])

  /*  useEffect(() => {
    if (!props.resource) return

    eventBus.sub(`${props.resource}.updated`, (ev) => {
      setEntity((prev) => ({ ...prev, ...ev.updates }))
    })
  }, [props.resource])*/

  useEffect(() => {
    if (entity || !props.entityId) return

    const promise = props.withHttpCache
      ? httpCache.execute(props.itemFetcher, props.entityId)
      : props.itemFetcher(props.entityId)

    wait(promise, (resp) => {
      if (resp.status === 'success') setEntity(resp.body)
    })
  }, [])

  useSkipFirstRenderEffect(() => {
    const e = getInitialState(state, props)
    if (e) return setEntity(e)

    const promise = props.withHttpCache
      ? httpCache.execute(props.itemFetcher, props.entityId)
      : props.itemFetcher(props.entityId)

    wait(promise, (resp) => {
      if (resp.status === 'success') setEntity(resp.body)
    })
  }, [props.entityId])

  return { entity, loading, setEntity }
}

const getInitialState = <T extends EntityWithId>(state: EntityRecoilState<T>, props: IUseEntityProps<T>): T => {
  const entity = props.forceLoad === true ? undefined : state.items.find((o) => o.id === props.entityId)

  if (props.withHttpCache && !entity) {
    const cached = httpCache.getCachedValue(props.itemFetcher, props.entityId)
    if (cached && cached.status === 'success') {
      return cached.body
    }
  }

  return entity
}

export default useEntity
