import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import EventsDropdown from 'screens/Admin/Events/components/EventsDropdown'
import ProjectTechnologiesDropdown from 'screens/Admin/ProjectTechnologies/componenets/ProjectTechnologiesDropdown'
import RrojectEducationYearDropdown from 'screens/Projects/components/ProjectEducationYearDropdown'
import projectsApi from 'screens/Projects/projects.api'
import projectsUtils from 'screens/Projects/projects.utils'
import Button from 'shared/Buttons/Button/Button'
import TextField from 'shared/Forms/TextField'
import TextInput from 'shared/Inputs/TextInput'
import Textarea from 'shared/Inputs/Textarea'
import Toggle from 'shared/Inputs/Toggle'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import Sheet from 'shared/Sheet'
import useHttpLoader from 'shared/hooks/useHttpLoader'

const CreateProjectPage = () => {
  const navigate = useNavigate()
  const { wait, loading } = useHttpLoader()

  const [project, setProject] = useState(projectsUtils.generateEmptyProjectAdmin())

  const handleChange = (val: any, name: string) => {
    setProject((prev) => ({ ...prev, [name]: val }))
  }

  const handleSave = async () => {
    wait(projectsApi.createProject({ ...project, teamsTargetCount: +project.teamsTargetCount, teams: [] }), (resp) => {
      if (resp.status === 'success') {
        navigate('/projects/' + resp.body.id)
      }
    })
  }

  const isValid =
    project.eventId &&
    project.repositoryLink &&
    project.name &&
    project.taskTrackingLink &&
    project.motivation &&
    project.techStack &&
    project.expectingResults &&
    project.description &&
    project.keyTechnologies.length

  return (
    <TopNavigationLayoutPageContent type='defaultWithBackButton'>
      <div className='flex-lines gap24'>
        <h2 className='text--heading-h4'>Создание проекта</h2>
        <Sheet>
          <div className='flex-lines'>
            <TextField header='Наименование'>
              <TextInput value={project.name} name='name' onChange={handleChange} placeholder='probok.net' />
            </TextField>
            <div className='grid-form-2'>
              <TextField header='Событие'>
                <EventsDropdown value={project.eventId} onChange={handleChange} name='eventId' />
              </TextField>
              <TextField header='Ключевая технология'>
                <ProjectTechnologiesDropdown
                  value={project.keyTechnologies[0]}
                  onChange={(val) => setProject((prev) => ({ ...prev, keyTechnologies: [val] }))}
                />
              </TextField>
            </div>

            <TextField header='Описание проекта'>
              <Textarea
                value={project.description}
                name='description'
                onChange={handleChange}
                placeholder='Проблема пробок становится все более актуальной и животрепещущей по всему миру... '
              />
            </TextField>
            <TextField header='Цель проекта'>
              <Textarea
                value={project.motivation}
                name='motivation'
                onChange={handleChange}
                placeholder='Избавить мир от пробок'
              />
            </TextField>
            <TextField header='Ожидаемый результат'>
              <Textarea
                value={project.expectingResults}
                name='expectingResults'
                onChange={handleChange}
                placeholder='Среднее время ожидания на светофоре в Екатеринбурге 3 секунды.'
              />
            </TextField>
            <TextField header='Технологический стек'>
              <Textarea
                value={project.techStack}
                name='techStack'
                onChange={handleChange}
                placeholder='Максимально подробно опишите и по возможности обоснуйте используемые вами технологии.'
              />
            </TextField>
            <div className='grid-form-2'>
              <TextField header='Ссылка на репозиторий (при отсутствии поставьте прочерк)'>
                <TextInput
                  value={project.repositoryLink}
                  name='repositoryLink'
                  onChange={handleChange}
                  placeholder='https://github.com/..'
                />
              </TextField>
              <TextField header='Ссылка на систему трекинга задач '>
                <TextInput
                  value={project.taskTrackingLink}
                  name='taskTrackingLink'
                  onChange={handleChange}
                  placeholder='https://attlasian...'
                />
              </TextField>
              <TextField header='Минимальный курс обучения'>
                <RrojectEducationYearDropdown
                  value={project.minEducationYear}
                  name='minEducationYear'
                  onChange={handleChange}
                />
              </TextField>
              <TextField header='Максимальный курс обучения'>
                <RrojectEducationYearDropdown
                  value={project.maxEducationYear}
                  name='maxEducationYear'
                  onChange={handleChange}
                />
              </TextField>
              <TextField header='Максимальное кол-во команд'>
                <TextInput
                  value={project.teamsTargetCount.toString()}
                  name='teamsTargetCount'
                  onChange={handleChange}
                />
              </TextField>
              <TextField header='Доступен для записи только администраторами'>
                <Toggle checked={project.adminOnly} name='adminOnly' onChange={handleChange} />
              </TextField>
            </div>

            <div className='form-footer'>
              <Button
                theme='primary'
                style={{ width: 'fit-content' }}
                disabled={!isValid}
                loading={loading}
                onClick={handleSave}
              >
                Создать
              </Button>
            </div>
          </div>
        </Sheet>
      </div>
    </TopNavigationLayoutPageContent>
  )
}

export default CreateProjectPage
