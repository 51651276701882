import TextInput, { ITextInputProps } from '../TextInput'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/IconClose.svg'
import css from './searchInput.module.css'
import CircleLoader from 'shared/Loaders/CircleLoader'
import { CSSProperties } from 'react'

const SearchInput = (
  props: ITextInputProps & {
    loading?: boolean
    loaderColor?: string
    searchIconStyle?: CSSProperties
    closeIconStyle?: CSSProperties
  }
) => {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <TextInput
        placeholder='Быстрый поиск'
        {...props}
        className={`${css.input} ${props.className || ''}`}
        autoComplete='off'
        autofocus={props.autofocus}
      >
        <SearchIcon className={css.icon} style={props.searchIconStyle} />
        {props.value && !props.loading && (
          <CloseIcon
            style={props.closeIconStyle}
            className={css.closeIcon}
            onClick={() => props.onChange && props.onChange('', props.name, !!props.required)}
          />
        )}
        {props.loading && (
          <div className={css.loader}>
            <CircleLoader color={props.loaderColor} />
          </div>
        )}
      </TextInput>
    </div>
  )
}

export default SearchInput
