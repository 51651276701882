import { ITeam } from '../../../teams.types'
import TeamInfoCard from '../components/TeamCards/TeamInfoCard'
import TeamMembersCard from '../components/TeamCards/TeamMembersCard'

interface IProps {
  team: ITeam
}

const TeamInfoContent = (props: IProps) => {
  if (!props.team) return null

  return (
    <div className='flex-lines gap24'>
      <TeamInfoCard team={props.team} />
      <TeamMembersCard team={props.team} />
    </div>
  )
}

export default TeamInfoContent
