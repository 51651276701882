import { atom } from 'recoil'
import { IUser } from './users.types'

interface IUsersState {
  loaded: boolean
  items: Omit<IUser, 'role'>[]
}

const usersAtom = atom<IUsersState>({
  key: 'users',
  default: {
    loaded: false,
    items: [],
  },
})

export default usersAtom
