import { useLocation, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userSkillsSelectors from 'screens/Admin/UserSkills/userSkills.selectors'
import useUser from 'screens/Users/hooks/useUser'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import ThreeDotsLoader from 'shared/Loaders/ThreeDotsLoader'
import css from './userPage.module.css'
import Card from 'shared/Cards/Card'
import UserPhotoCard from 'screens/Users/components/UserPhotoCard'
import TextField from 'shared/Forms/TextField'
import UserTeamsAndProjectsCard from 'screens/Users/components/UserTeamsAndProjectsCard'

const UserPage = () => {
  const params = useParams()
  const location = useLocation()
  const { user, loading } = useUser(+params.id)

  const skillsMap = useRecoilValue(userSkillsSelectors.skillsMap)

  const renderSkillsCardContent = () => {
    if (!user.usersOnSkills.length) {
      return (
        <div className='text--body-m flex-lines' style={{ gap: '6px' }}>
          <p>Пользователь не указал ни одной компетенции 😲</p>
        </div>
      )
    }

    return (
      <div className='flex-lines' style={{ gap: '12px' }}>
        {user.usersOnSkills.map((s) => (
          <div className='inline-flex-gap'>
            <div className={css.skillDot} />
            <p key={s.skillId} className='text--body-m'>
              {skillsMap[s.skillId]}
            </p>
          </div>
        ))}
      </div>
    )
  }

  const renderInfoCardContent = () => {
    const renderInfoField = (name: string, title: string) => {
      return <TextField header={title}>{user.info[name] || '-'}</TextField>
    }

    return (
      <div className='grid-form-2'>
        {renderInfoField('name', 'ФИО')}
        {renderInfoField('universityGroup', 'Универститетская группа')}
        {renderInfoField('phone', 'Номер телефона')}
        {renderInfoField('telegram', 'Telegram')}
      </div>
    )
  }

  const renderContent = () => {
    if (loading || !user) {
      return (
        <div className='flex-page-center'>
          <ThreeDotsLoader />
        </div>
      )
    }

    return (
      <div className='flex-lines gap24'>
        <div className='inline-flex-gap gap24'>
          <UserPhotoCard userId={user.id} />
          <Card type='normal' style={{ width: '100%', height: '225px' }}>
            <div className='flex-lines'>
              <h2 className='text--caption-l text-700' style={{ marginBottom: '16px' }}>
                Общая информация
              </h2>
              {renderInfoCardContent()}
            </div>
          </Card>
        </div>
        <Card type='normal'>
          <div className='flex-space-between' style={{ marginBottom: '16px' }}>
            <h2 className='text--caption-l text-700'>Компетенции</h2>
          </div>
          {renderSkillsCardContent()}
        </Card>
        <UserTeamsAndProjectsCard userId={user.id} />
      </div>
    )
  }

  const getUserRole = () => {
    if (location?.state?.mentor) return 'Куратор'

    return user?.isAdmin ? 'Администратор' : 'Студент'
  }

  return (
    <TopNavigationLayoutPageContent type='defaultWithBackButton'>
      <div className='flex-space-between' style={{ marginBottom: '24px' }}>
        <div className='inline-flex-gap' style={{ gap: '32px' }}>
          <div className='flex-lines' style={{ gap: '6px' }}>
            <h2 className='text--heading-h4' style={{ flexShrink: 0 }}>
              {user?.info?.name || 'Пользователь'}
            </h2>
            <p className='text-caption-m'>
              {getUserRole()}, <span className='text--blue'>{user?.email || ''}</span>
            </p>
          </div>
        </div>
      </div>
      {renderContent()}
    </TopNavigationLayoutPageContent>
  )
}

export default UserPage
