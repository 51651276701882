import AuthProvider from './screens/Auth/components/AuthProvider'
import './styles/ui-kit.styles.css'
import './styles/variables.css'
import './styles/sanitize.css'
import './styles/forms.css'
import './styles/text.css'
import './styles/base.css'
import { RouterProvider } from 'react-router-dom'
import useAppRouter from './navigation'
import Ntf from './services/Ntf'
import InitProvider from './screens/Auth/components/InitProvider'
import ConfirmService from './services/ConfirmService'
import './services/accessToken/index'

function App() {
  const appRouter = useAppRouter()

  return (
    <>
      <Ntf />
      <ConfirmService />
      <AuthProvider>
        <InitProvider>
          <RouterProvider router={appRouter} />
        </InitProvider>
      </AuthProvider>
    </>
  )
}

export default App
