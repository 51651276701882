import fmt from 'date-fns/format'
import ru from 'date-fns/locale/ru'

const formatSeconds = (seconds: number) => {
  return seconds.toString().padStart(2, '0')
}

const formatDate = (date: Date | number, str: string) => {
  if (!date) return null

  try {
    return fmt(date, str, { locale: ru })
  } catch {
    return null
  }
}

const timeUtils = { formatSeconds, formatDate }

export default timeUtils
