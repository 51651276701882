import IStoreTreeStrategy from '../strategy.interface'

export interface ILSCodec<T> {
  parse: (s: string) => T
  stringify: (obj: T) => string
}

class LocalStorageStrategy<T extends unknown> implements IStoreTreeStrategy<T> {
  private codec: ILSCodec<T>

  constructor(codec: ILSCodec<T> = JSON) {
    this.codec = codec
  }

  get(key: string) {
    try {
      return this.codec.parse(localStorage.getItem(key))
    } catch (_) {
      return null
    }
  }

  set(key: string, state: T) {
    localStorage.setItem(key, this.codec.stringify(state))
  }
}

export default LocalStorageStrategy
