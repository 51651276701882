import { useCallback, useEffect, useState } from 'react'
import SelectUserModal from './SelectUserModal'
import css from './userSelect.module.css'
import { IUser } from 'screens/Admin/RolesAndUsers/users.types'
import useUser from 'screens/Users/hooks/useUser'
import SkeletonCard from 'shared/Cards/SkeletonCard'
import cn from 'utils/cn'

interface IProps {
  userId?: IUser['id']
  selectedUser: IUser
  onSelect: (user: IUser) => void
  disabled?: boolean
}

const UserSelect = (props: IProps) => {
  const { user, loading } = useUser(props.userId)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (user) props.onSelect(user)
  }, [user])

  const handleSelect = useCallback(
    (user: IUser) => {
      if (props.disabled) return

      props.onSelect(user)
      setOpen(false)
    },
    [setOpen, props.onSelect, props.disabled]
  )

  if (loading) return <SkeletonCard type='small' height='40px' width='100%' loading={loading} />

  return (
    <>
      <div className={cn('etba-input', css.select)} onClick={() => !props.disabled && setOpen(true)}>
        {props.selectedUser?.info?.name || user?.info?.name || (
          <span className='text--placeholder'>Выберите пользователя</span>
        )}
      </div>
      {open && <SelectUserModal onSelect={handleSelect} onClose={() => setOpen(false)} />}
    </>
  )
}

export default UserSelect
