import TopNavigationLayoutPageContent from '../../../../shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import SlideSelect from '../../../../shared/Inputs/SlideSelect'
import { useEffect, useMemo, useState } from 'react'
import CreateTeamSheet from './components/CreateTeamSheet'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import selfTeamsAtom from '../../../Auth/components/InitProvider/selfTeams.atom'
import TeamInfoContent from './TeamInfoContent'
import InviteStudentContent from './InviteStudentContent'
import InvitesContent from './InvitesContent'
import selfInvitesAtom from '../../../Auth/components/InitProvider/selfInvites.atom'
import authAtom from '../../../Auth/auth.atom'
import ActionsButtonWithMenu from '../../../../shared/Buttons/ActionsButtonWithMenu'
import useIsTeamLeavePossible from 'screens/Teams/hooks/useIsTeamLeavePossible'
import confirmServiceCtrl from 'services/ConfirmService/confirmService.ctrl'
import selfApi from 'screens/Profile/self.api'
import authApi from 'screens/Auth/auth.api'
import selfProjectsAtom from 'screens/Auth/components/InitProvider/selfProjects.atom'

const MyTeam = () => {
  const selfInfo = useRecoilValue(authAtom).user
  const invites = useRecoilValue(selfInvitesAtom)
  const selfTeams = useRecoilValue(selfTeamsAtom).items
  const [selectedSlide, setSelectedSlide] = useState(selfTeams.length ? 'my-team' : 'create-team')

  const setSelfTeams = useSetRecoilState(selfTeamsAtom)
  const setSelfProjects = useSetRecoilState(selfProjectsAtom)

  const activeTeam = selfTeams[0]
  const teamLeavePossible = useIsTeamLeavePossible(activeTeam?.id)

  const handleTeamLeave = async () => {
    const isLead = activeTeam.ownerId === selfInfo.id
    confirmServiceCtrl
      .requestConfirm({
        type: 'info',
        title: 'Подтвердте действие',
        description:
          activeTeam.ownerId === selfInfo.id
            ? 'Вы - лидер команды. В случае, если вы выйдете, лидерство перейдет случайному члену команды.'
            : `Выйти из команды "${activeTeam.name}"?`,
      })
      .then((r) => {
        const job = async () => {
          const leaveTeamResp = await selfApi.leaveTeam(
            activeTeam.id,
            isLead ? activeTeam.members?.[0]?.userId : undefined
          )
          if (leaveTeamResp.status === 'success') {
            const initResp = await authApi.init()
            if (initResp.status === 'success') {
              setSelfTeams((prev) => ({ ...prev, items: initResp.body.userTeams }))
              setSelfProjects((prev) => ({ ...prev, items: initResp.body.userProjects }))
            }

            setSelectedSlide('create-team')
          }
        }

        if (r.response === 'confirm') {
          return job()
        }
      })
  }

  useEffect(() => {
    if (selfTeams.length && ['team-invites', 'create-team'].includes(selectedSlide)) {
      setSelectedSlide('my-team')
    }
  }, [selfTeams.length])

  const slidesOptions = useMemo(() => {
    if (!selfTeams.length) {
      return [
        { value: 'create-team', title: 'Создать команду' },
        { value: 'team-invites', title: `Приглашения (${invites.items.length})` },
      ]
    }

    if (activeTeam.ownerId === selfInfo.id) {
      return [
        { value: 'my-team', title: 'Моя команда' },
        { value: 'invite-student', title: 'Пригласить студента' },
      ]
    }

    return [{ value: 'my-team', title: 'Моя команда' }]
  }, [selfTeams, invites])

  const renderContent = () => {
    switch (selectedSlide) {
      case 'create-team':
        return <CreateTeamSheet onCreate={() => setSelectedSlide('my-team')} />
      case 'my-team':
        return <TeamInfoContent team={activeTeam} />
      case 'invite-student':
        return <InviteStudentContent team={activeTeam} />
      case 'team-invites':
        return <InvitesContent />
      default:
        return null
    }
  }

  return (
    <TopNavigationLayoutPageContent type='default'>
      <div className='flex-space-between'>
        <div className='inline-flex-gap' style={{ gap: '32px' }}>
          <h2 className='text--heading-h4' style={{ flexShrink: 0 }}>
            Управление командой
          </h2>
          <SlideSelect
            options={slidesOptions}
            selectedOption={selectedSlide}
            onSelect={(val) => setSelectedSlide(val)}
          />
        </div>
        {!!selfTeams.length && teamLeavePossible && (
          <ActionsButtonWithMenu>
            <span className='options-contextmenu-option' data-danger={true} onClick={handleTeamLeave}>
              Выйти из команды
            </span>
          </ActionsButtonWithMenu>
        )}
      </div>

      {renderContent()}
    </TopNavigationLayoutPageContent>
  )
}

export default MyTeam
