import { ITableField } from 'shared/Table'
import { IEvent } from '../../events.types'
import timeUtils from 'utils/time.utils'

const eventsTableFields: ITableField<IEvent, keyof IEvent>[] = [
  { key: 'id', getter: (item) => item.id.toString(), sortGetter: (item) => item.id, header: 'ID', width: '100px' },
  {
    key: 'name',
    getter: (item) => item.name.toString(),
    sortGetter: (item) => item.name,
    header: 'Название',
    width: '300px',
  },
  {
    key: 'startAt',
    getter: (item) => timeUtils.formatDate(new Date(item.startAt), 'dd MMM yyyy'),
    sortGetter: (item) => item.startAt,
    header: 'Старт',
    width: '300px',
  },
  {
    key: 'endsAt',
    getter: (item) => timeUtils.formatDate(new Date(item.endsAt), 'dd MMM yyyy'),
    sortGetter: (item) => item.endsAt,
    header: 'Конец',
    width: '300px',
  },
  {
    key: 'locked',
    getter: (item) => (item.locked ? 'True' : 'False'),
    sortGetter: (item) => +item.locked,
    header: 'Locked',
    width: '100px',
  },
]

export default eventsTableFields
