import { ITeamInvite } from '../../../../teams.types'
import timeUtils from '../../../../../../utils/time.utils'
import Button from '../../../../../../shared/Buttons/Button/Button'
import Card from '../../../../../../shared/Cards/Card'
import useHttpLoader from '../../../../../../shared/hooks/useHttpLoader'
import teamsApi from '../../../../teams.api'
import { useSetRecoilState } from 'recoil'
import selfInvitesAtom from '../../../../../Auth/components/InitProvider/selfInvites.atom'
import selfTeamsAtom from '../../../../../Auth/components/InitProvider/selfTeams.atom'
import authApi from '../../../../../Auth/auth.api'
import selfProjectsAtom from '../../../../../Auth/components/InitProvider/selfProjects.atom'

interface IProps {
  invite: ITeamInvite
}

const InviteCard = ({ invite }: IProps) => {
  const setSelfTeamsState = useSetRecoilState(selfTeamsAtom)
  const setInvitesState = useSetRecoilState(selfInvitesAtom)
  const setSelfProjects = useSetRecoilState(selfProjectsAtom)

  const { wait: waitCancel, loading: cancelLoading } = useHttpLoader()
  const { wait: waitAccept, loading: acceptLoading } = useHttpLoader()

  const handleAccept = () => {
    const job = async () => {
      const resp = await teamsApi.acceptInvite(invite.id)
      if (resp.status === 'success') {
        const initResp = await authApi.init()
        if (initResp.status === 'success') {
          setSelfTeamsState((prev) => ({ ...prev, items: initResp.body.userTeams }))
          setSelfProjects((prev) => ({ ...prev, items: initResp.body.userProjects }))
        }
      }

      return resp
    }

    waitAccept(job(), (resp) => {
      if (resp.status === 'success') {
        setInvitesState((prev) => ({ ...prev, items: prev.items.filter((i) => i.id !== invite.id) }))
      }
    })
  }

  const handleCancel = () => {
    waitCancel(teamsApi.cancelInvite(invite.id), (resp) => {
      if (resp.status === 'success') {
        setInvitesState((prev) => ({ ...prev, items: prev.items.filter((i) => i.id !== invite.id) }))
      }
    })
  }

  return (
    <Card type='small' style={{ maxWidth: '700px' }}>
      <div className='flex-lines'>
        <div className='inline-flex-gap flex-space-between'>
          <p className='text--caption-m'>Приглашение в команду "{invite.team.name}"</p>
          <p className='text--body-s'>от {timeUtils.formatDate(new Date(invite.createdAt), 'dd MMM yyyy')}</p>
        </div>
        <div className='inline-flex-gap'>
          <Button
            theme='primary'
            style={{ width: 'fit-content' }}
            loading={acceptLoading}
            disabled={cancelLoading}
            onClick={handleAccept}
          >
            Принять
          </Button>
          <Button
            theme='grey-outline'
            style={{ width: 'fit-content' }}
            loading={cancelLoading}
            disabled={acceptLoading}
            onClick={handleCancel}
          >
            Отклонить
          </Button>
        </div>
      </div>
    </Card>
  )
}

export default InviteCard
