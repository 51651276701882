import { useRecoilValue } from 'recoil'
import Dropdown from '../../../../../shared/Inputs/Dropdown'
import { useMemo } from 'react'
import { IProjectTechnogy } from '../../projectTechnologies.types'
import projectTechnologiesAtom from '../../projectTechnologies.atom'

interface IProps {
  name?: string
  value: IProjectTechnogy['id']
  onChange: (val: IProjectTechnogy['id'], name: string) => void
}

const ProjectTechnologiesDropdown = (props: IProps) => {
  const events = useRecoilValue(projectTechnologiesAtom).items

  const options = useMemo(() => {
    return events.map((e) => ({ title: e.title, value: e.id }))
  }, [events])

  return (
    <Dropdown
      options={options}
      selectedOption={props.value}
      title='Ключевая технология'
      onSelect={props.onChange}
      name={props.name}
    />
  )
}

export default ProjectTechnologiesDropdown
