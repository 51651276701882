import axios, { AxiosError, AxiosResponse } from 'axios'
import { IHTTPErrorResponse, IHTTPSuccessResponse } from './http.types'
import eventBus from '../eventBus'
import { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { IAuthState } from '../../screens/Auth/auth.atom'
import authApi from '../../screens/Auth/auth.api'
import config from '../../config'

const http = axios.create({
  baseURL: config.API_URL,
  headers: {},
})

let interceptorsApplied = false
export const applyInterceptors = (
  authState: MutableRefObject<IAuthState>,
  setAuthState: Dispatch<SetStateAction<IAuthState>>
) => {
  if (interceptorsApplied) return

  interceptorsApplied = true
  let isRefreshing = false

  let refreshRequest = Promise.resolve({
    auth: {
      accessToken: authState.current.auth.accessToken,
      expires: authState.current.auth.expires,
    },
  })

  const ensureAuthorization = (): Promise<Pick<IAuthState, 'auth'>> => {
    const shouldRefresh = !authState || Date.now() + 600000 > authState.current.auth.expires

    return shouldRefresh ? refreshToken() : Promise.resolve(authState.current)
  }

  const refreshToken = async (): Promise<Pick<IAuthState, 'auth'>> => {
    if (isRefreshing) return refreshRequest
    isRefreshing = true

    refreshRequest = authApi.refreshToken().finally(() => (isRefreshing = false))
    return refreshRequest
  }

  http.interceptors.request.use((config) => {
    return ensureAuthorization().then(({ auth }) => {
      authState.current = {
        ...authState.current,
        auth: {
          ...authState.current.auth,
          accessToken: auth.accessToken,
          expires: auth.expires,
        },
      }

      config.headers.Authorization = `Bearer ${auth.accessToken}`
      return config
    })
  })

  http.interceptors.response.use(
    (response) => response,
    (err) => {
      const shouldLogout = err.response && err.response.status === 401

      if (shouldLogout) {
        setAuthState({
          auth: { accessToken: '', expires: 0 },
          user: null,
        })
      }

      throw err
    }
  )
}

export const handleHttpResponse = <T extends any>(response: AxiosResponse<T>): IHTTPSuccessResponse<T> => {
  return { status: 'success', body: response.data }
}

export const handleHttpError = (error: AxiosError): IHTTPErrorResponse => {
  // @ts-ignore
  const serverErrorMessage = error?.response?.data?.message
  if (serverErrorMessage) {
    eventBus.pub('http.error', { message: serverErrorMessage, code: error?.response?.status })

    return { status: 'error', message: serverErrorMessage, code: error?.response?.status }
  }

  if (error?.response?.status === 429) {
    const message = 'Слишком много запросов..'
    eventBus.pub('http.error', { message, code: error?.response?.status })

    return { status: 'error', message, code: error?.response?.status }
  }

  if (error?.response?.status === 404) {
    eventBus.pub('http.error', {
      message: 'Запрашиваемый ресурс не найден',
      code: error?.response?.status,
    })
  }

  if (error?.response?.status === 400) {
    return {
      status: 'error',
      message: 'С вашими данными что-то не так',
      code: error?.response?.status,
      body: error?.response?.data as Record<string, string>,
    }
  }

  if (error?.response?.status === 401) {
    return {
      status: 'error',
      message: 'Unauthorized',
      code: 401,
    }
  }

  const message = 'Произошла непредвиденная ошибка'
  eventBus.pub('http.error', { message, code: error?.response?.status })

  return {
    status: 'error',
    message: error?.message,
    code: error?.response?.status,
  }
}

export default http
