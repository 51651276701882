import { CSSProperties, forwardRef, PropsWithChildren } from 'react'
import { useTransition, a } from '@react-spring/web'

interface IProps {
  open: boolean
  transformOrigin: CSSProperties['transformOrigin']
  style?: CSSProperties

  icon?: JSX.Element | string
}

const SmoothPanel = forwardRef<HTMLDivElement, PropsWithChildren<IProps>>((props, ref) => {
  const transition = useTransition(props.open, {
    from: { scale: 0.1, opacity: 0.1 },
    enter: { scale: 1, opacity: 1 },
    leave: { scale: 0.1, opacity: 0 },
    config: { duration: 250 },
  })

  const renderContent = () => {
    return transition((style, open) => {
      if (!open) return null

      return (
        <a.div style={{ ...style, background: '#fff', transformOrigin: props.transformOrigin }}>{props.children}</a.div>
      )
    })
  }

  const normalizedStyle = props.style || {}
  return (
    <div style={{ position: 'relative', zIndex: props.open ? 10 : 9, ...normalizedStyle }} ref={ref}>
      {props.icon}
      {renderContent()}
    </div>
  )
})

export default SmoothPanel
