import { ChangeEvent, CSSProperties } from 'react'
import css from './toggle.module.css'

const Toggle = (props: IToggleProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.editing === false) return

    props.onChange(e.target.checked, props.name)
  }

  return (
    <label style={{ fontSize: props.fontSize || '15px', ...props.style }} className={css.toggleSwitch}>
      <input type='checkbox' checked={props.checked} onChange={handleChange} name={props.name} />
      <span className={css.toggleSlider} />
    </label>
  )
}

export interface IToggleProps {
  name: string
  checked: boolean
  onChange: (v: boolean, name: string) => void
  fontSize?: string
  style?: CSSProperties
  editing?: boolean
}

export default Toggle
