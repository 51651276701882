import { useState } from 'react'
import projectsUtils from '../../../projects.utils'
import Sheet from '../../../../../shared/Sheet'
import TextField from '../../../../../shared/Forms/TextField'
import TextInput from '../../../../../shared/Inputs/TextInput'
import Textarea from '../../../../../shared/Inputs/Textarea'
import Button from '../../../../../shared/Buttons/Button/Button'
import EventsDropdown from '../../../../Admin/Events/components/EventsDropdown'
import useHttpLoader from '../../../../../shared/hooks/useHttpLoader'
import projectsApi from '../../../projects.api'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import selfProjectsAtom from '../../../../Auth/components/InitProvider/selfProjects.atom'
import selfTeamsAtom from '../../../../Auth/components/InitProvider/selfTeams.atom'
import ProjectTechnologiesDropdown from 'screens/Admin/ProjectTechnologies/componenets/ProjectTechnologiesDropdown'

interface IProps {
  onCreated: () => void
}

const CreateProjectContent = (props: IProps) => {
  const { wait, loading } = useHttpLoader()

  const selfTeams = useRecoilValue(selfTeamsAtom)
  const setSelfProject = useSetRecoilState(selfProjectsAtom)
  const [project, setProject] = useState(projectsUtils.generateEmptyProject())

  const handleChange = (val: any, name: string) => {
    setProject((prev) => ({ ...prev, [name]: val }))
  }

  const handleSave = async () => {
    wait(projectsApi.createProject({ ...project, teams: [selfTeams.items[0].id] }), (resp) => {
      if (resp.status === 'success') {
        setSelfProject((prev) => ({ ...prev, items: [resp.body, ...prev.items] }))
        props.onCreated()
      }
    })
  }

  const isValid =
    project.eventId &&
    project.repositoryLink &&
    project.name &&
    project.taskTrackingLink &&
    project.motivation &&
    project.techStack &&
    project.expectingResults &&
    project.description &&
    project.keyTechnologies.length

  return (
    <div className='flex-lines gap24'>
      <Sheet>
        <div className='flex-lines'>
          <TextField header='Наименование'>
            <TextInput value={project.name} name='name' onChange={handleChange} placeholder='probok.net' />
          </TextField>
          <div className='grid-form-2'>
            <TextField header='Событие'>
              <EventsDropdown value={project.eventId} onChange={handleChange} name='eventId' />
            </TextField>
            <TextField header='Ключевая технология'>
              <ProjectTechnologiesDropdown
                value={project.keyTechnologies[0]}
                onChange={(val) => setProject((prev) => ({ ...prev, keyTechnologies: [val] }))}
              />
            </TextField>
          </div>

          <TextField header='Описание проекта'>
            <Textarea
              value={project.description}
              name='description'
              onChange={handleChange}
              placeholder='Проблема пробок становится все более актуальной и животрепещущей по всему миру... '
            />
          </TextField>
          <TextField header='Цель проекта'>
            <Textarea
              value={project.motivation}
              name='motivation'
              onChange={handleChange}
              placeholder='Избавить мир от пробок'
            />
          </TextField>
          <TextField header='Ожидаемый результат'>
            <Textarea
              value={project.expectingResults}
              name='expectingResults'
              onChange={handleChange}
              placeholder='Среднее время ожидания на светофоре в Екатеринбурге 3 секунды.'
            />
          </TextField>
          <TextField header='Технологический стек'>
            <Textarea
              value={project.techStack}
              name='techStack'
              onChange={handleChange}
              placeholder='Максимально подробно опишите и по возможности обоснуйте используемые вами технологии.'
            />
          </TextField>
          <div className='grid-form-2'>
            <TextField header='Ссылка на репозиторий (при отсутствии поставьте прочерк)'>
              <TextInput
                value={project.repositoryLink}
                name='repositoryLink'
                onChange={handleChange}
                placeholder='https://github.com/..'
              />
            </TextField>
            <TextField header='Ссылка на систему трекинга задач (при отсутствии поставьте прочерк)'>
              <TextInput
                value={project.taskTrackingLink}
                name='taskTrackingLink'
                onChange={handleChange}
                placeholder='https://attlasian...'
              />
            </TextField>
          </div>
          <div className='form-footer'>
            <Button
              theme='primary'
              style={{ width: 'fit-content' }}
              disabled={!isValid}
              loading={loading}
              onClick={handleSave}
            >
              Создать
            </Button>
          </div>
        </div>
      </Sheet>
    </div>
  )
}

export default CreateProjectContent
