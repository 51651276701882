import { IContacts, IInitResponse, ILoginResponse, IRegisterData } from './auth.types'
import http, { handleHttpError, handleHttpResponse } from 'services/http'
import axios from 'axios'
import config from '../../config'
import { HTTPResponse } from '../../services/http/http.types'

const loginWithCredentials = (contacts: IContacts & { rememberMe: boolean }): Promise<HTTPResponse<ILoginResponse>> => {
  return axios
    .post(config.API_URL + '/nsi/v1/auth/login', contacts)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const refreshToken = async (): Promise<ILoginResponse> => {
  const resp: ILoginResponse = await axios
    .post(config.API_URL + '/nsi/v1/auth/refresh')
    .then((r) => r.data)
    .catch(() => ({ auth: { accessToken: '', expires: 0 } }))

  if (!config.production) {
    localStorage.removeItem('access')
  }

  return resp
}

const logout = () => {
  return axios
    .post(config.API_URL + '/nsi/v1/auth/logout')
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const register = async (dto: IRegisterData): Promise<HTTPResponse<any>> => {
  return axios
    .post(config.API_URL + '/nsi/v1/auth/register', dto)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const init = async (): Promise<HTTPResponse<IInitResponse>> => {
  return http.get('/nsi/v1/self/init').then(handleHttpResponse).catch(handleHttpError)
}

const authApi = {
  loginWithCredentials,
  refreshToken,
  logout,
  register,
  init,
}

export default authApi
