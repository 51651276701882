import { atom } from 'recoil'
import { IOrganization } from '../Admin/Organizations/organizations.types'
import { IEvent } from '../Admin/Events/events.types'
import { IProject } from './projects.types'
import { IProjectTechnogy } from 'screens/Admin/ProjectTechnologies/projectTechnologies.types'

interface IProjectsAtomState {
  items: any[]
  nextPage: string | null
  totalCount: number
  filters: {
    organization: IOrganization['id']
    onlyAvailableForJoin: boolean
    search: string
    eventId: IEvent['id']
    status: IProject['status']
    keyTechnology: IProjectTechnogy['id']
  }
}

const projectsAtom = atom<IProjectsAtomState>({
  key: 'projects',
  default: {
    items: [],
    totalCount: 0,
    filters: {
      organization: null,
      onlyAvailableForJoin: true,
      search: '',
      eventId: null,
      status: 'APPROVED',
      keyTechnology: null,
    },
    nextPage: undefined,
  },
})
export default projectsAtom
