import http, { handleHttpError, handleHttpResponse } from 'services/http'
import { HTTPResponse, IPaginationResponse } from 'services/http/http.types'
import { IProjectTechnogy } from './projectTechnologies.types'

interface IGetParams {
  search?: string
}
const list = async (params: IGetParams): Promise<HTTPResponse<IPaginationResponse<IProjectTechnogy>>> => {
  return http.get('/nsi/v1/project-technologies', { params }).then(handleHttpResponse).catch(handleHttpError)
}

const getOne = async (id: IProjectTechnogy['id']): Promise<HTTPResponse<IProjectTechnogy>> => {
  return http
    .get('/nsi/v1/project-technologies/' + id)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const create = async (
  dto: Pick<IProjectTechnogy, 'title' | 'description'>
): Promise<HTTPResponse<IProjectTechnogy>> => {
  return http.post('/nsi/v1/project-technologies', dto).then(handleHttpResponse).catch(handleHttpError)
}

const update = async (
  dto: Partial<IProjectTechnogy>,
  id: IProjectTechnogy['id']
): Promise<HTTPResponse<IProjectTechnogy>> => {
  return http
    .patch('/nsi/v1/project-technologies/' + id, dto)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const projectTechnologiesApi = {
  update,
  create,
  getOne,
  list,
}
export default projectTechnologiesApi
