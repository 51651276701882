import { MutableRefObject, useEffect, useState } from 'react'

interface IProps {
  elementRef: MutableRefObject<HTMLElement>
  isOpen: boolean
}

type Position = 'top' | 'bottom'

const useVirtualMenu = (props: IProps): Position => {
  const [position, setPosition] = useState<Position>('bottom')

  useEffect(() => {
    if (!props.isOpen) return

    const el = props.elementRef?.current
    const parent = getScrollParent(el)?.parentElement
    if (!el || !parent) return

    const parentBbox = parent.getBoundingClientRect()
    const elementBbox = el.getBoundingClientRect()

    const topVisibleHeight = Math.abs(elementBbox.top) - Math.abs(parentBbox.top)
    topVisibleHeight < parentBbox.height / 2 ? setPosition('bottom') : setPosition('top')
  }, [props])

  return position
}

const getScrollParent = (node): HTMLElement => {
  if (node == null) return null

  if (node.scrollHeight > node.clientHeight) return node
  else return getScrollParent(node.parentNode)
}

export default useVirtualMenu
