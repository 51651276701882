import { useEffect, useState } from 'react'
import Modal from '../../Modal'
import ModalHeader from '../../Modal/ModalHeader'
import MaterialInput from '../../Inputs/MaterialInput'
import Button from '../../Buttons/Button/Button'
import eventBus from '../../../services/eventBus'

const TableRequestItemsPerPageService = () => {
  const [requestModalOpen, setRequestModalOpen] = useState(false)
  const [value, setValue] = useState('1')

  useEffect(() => {
    const requestUnsub = eventBus.sub('table.items_per_page_request', () => setRequestModalOpen(true))

    return () => {
      requestUnsub()
    }
  }, [])

  if (!requestModalOpen) return null

  const handleCancel = () => {
    eventBus.pub('table.items_per_page_response', { status: 'canceled', value: null })
    setRequestModalOpen(false)
  }

  const handleSubmit = () => {
    eventBus.pub('table.items_per_page_response', { status: 'entered', value: +value })
    setRequestModalOpen(false)
  }

  return (
    <Modal onClose={handleCancel} size="sm">
      <ModalHeader onClose={handleCancel}>Элементов на странице</ModalHeader>
      <div className="modal-content">
        <MaterialInput value={value} name="value" onChange={setValue} title="Число" />
      </div>
      <div className="modal-footer">
        <Button theme="primary" onClick={handleSubmit}>
          Применить
        </Button>
      </div>
    </Modal>
  )
}

export default TableRequestItemsPerPageService
