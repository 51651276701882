import { IRoute } from './navigation.types'
import ProtectedContent from '../screens/Auth/components/ProtectedContent'
import appRoutes from './app.routes'
import { matchRoutes } from 'react-router-dom'
import { Location } from '@remix-run/router/history'
import TopNavigationLayout from '../shared/Layouts/TopNavigationLayout'

const makeRoutePagesProtected = (routes: IRoute[]) => {
  for (const route of routes) {
    if (route.element && Array.isArray(route.permissions) && route.permissions.length > 0) {
      route.element = <ProtectedContent permissions={route.permissions}>{route.element}</ProtectedContent>
    }

    if (Array.isArray(route.children)) {
      makeRoutePagesProtected(route.children)
    }
  }
}

const flatRoutes = (routes: IRoute[]): IRoute => {
  const flattenRoutes = []
  for (const route of routes) {
    flattenRoutes.push(route)
    if (route.children) {
      for (const nested of route.children) {
        flattenRoutes.push(nested)
      }
    }
    delete route.children
  }

  return { path: '/', children: flattenRoutes, element: <TopNavigationLayout /> }
}

const findRoute = (path: string) => {
  const rootElement = appRoutes.find((r) => r.path === path)
  if (rootElement) {
    return { parent: null, route: rootElement }
  }

  for (const root of appRoutes) {
    if (!root.children) continue

    for (const route of root.children) {
      if (route.path === path) {
        return { parent: root, route }
      }
    }
  }

  return { parent: null, route: null }
}

const isRouteActive = (location: Location, r: Pick<IRoute, 'path'>): { isActive: boolean; isGroupActive: boolean } => {
  const currentRoutesMatch = matchRoutes(appRoutes, location)
  const currentPath = currentRoutesMatch[currentRoutesMatch.length - 1]?.route?.path

  const { parent, route } = findRoute(r.path)

  // Выбран текущий роут
  const isActive = currentPath === r.path

  let isGroupActive = isActive
  if (!isGroupActive) {
    if (!parent) {
      if (route.children) {
        isGroupActive = route.children.some((r) => r.path === currentPath)
      }
    } else {
      if (parent.path === currentPath) isGroupActive = true
      if (parent.children.some((c) => c.path === currentPath)) isGroupActive = true
    }
  }

  return { isActive, isGroupActive }
}

const navigationUtils = { makeRoutePagesProtected, flatRoutes, findRoute, isRouteActive }
export default navigationUtils
