import { ITableField } from '../../../../../shared/Table'
import { IUser } from '../../../../Admin/RolesAndUsers/users.types'

const studentsTableFields: ITableField<IUser, keyof IUser | string>[] = [
  {
    key: 'name',
    header: 'ФИО',
    getter: (user) => user.info.name,
    sortGetter: (user) => user.info.name,
    width: '400px',
  },
  {
    key: 'group',
    header: 'Группа',
    getter: (user) => user.info.universityGroup,
    sortGetter: (user) => user.info.universityGroup,
    width: '300px',
  },
  {
    key: 'email',
    header: 'Email',
    getter: (user) => user.email,
    sortGetter: (user) => user.email,
    width: '300px',
  },
]
export default studentsTableFields
