import useEntity from 'recoil/useEntity/useEntity'
import usersAtom from 'screens/Admin/RolesAndUsers/users.atom'
import usersApi from '../users.api'
import { IUser } from 'screens/Admin/RolesAndUsers/users.types'

const useUser = (id: IUser['id']) => {
  const { entity, loading } = useEntity<IUser>({
    atom: usersAtom,
    itemFetcher: usersApi.getUser,
    entityId: id,
    resource: 'users',
  })

  return { user: entity, loading }
}

export default useUser
