import { useState } from 'react'
import { IHTTPErrorResponse } from '../../../services/http/http.types'
import globalUtils from '../../../utils/global.utils'

const useValidationForm = () => {
  const [valid, setIsValid] = useState(true)
  // General description on the error
  const [errorMsg, setErrorMsg] = useState('')
  // If the server responded with 400 status code, here we can find map with field name as key and error message as value
  const [errors, setErrors] = useState<Record<string, string>>({})

  const handleErrorResponse = (resp: IHTTPErrorResponse) => {
    setErrorMsg(resp.message)

    if (resp.body) {
      setErrors(globalUtils.flatObject(resp.body))
    }
  }

  return { errors, errorMsg, setErrors, setErrorMsg, valid, setIsValid, handleErrorResponse }
}

export default useValidationForm
