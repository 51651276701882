import { useState } from 'react'
import AdminNavigationLayout from 'shared/Layouts/TopNavigationLayout/AdminNavifationLayout'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import SkillSheet from '../../componenets/SkillSheet'
import Button from 'shared/Buttons/Button/Button'
import useHttpLoader from 'shared/hooks/useHttpLoader'
import userSkillsApi from '../../userSkills.api'
import { useSetRecoilState } from 'recoil'
import userSkillsTableAtom from '../../componenets/UserSkillsTable/userSkillsTable.atom'
import { useNavigate } from 'react-router-dom'

const CreateSkillPage = () => {
  const navigate = useNavigate()
  const { wait, loading } = useHttpLoader()
  const [draft, setDraft] = useState({ name: '' })
  const setSkillsState = useSetRecoilState(userSkillsTableAtom)

  const handleChange = (val: any, name: string) => {
    setDraft((prev) => ({ ...prev, [name]: val }))
  }

  const handleSave = () => {
    wait(userSkillsApi.createUserSkill(draft), (resp) => {
      if (resp.status === 'success') {
        setSkillsState((prev) => ({ ...prev, items: [resp.body, ...prev.items] }))
        navigate('/admin/skills')
      }
    })
  }

  return (
    <AdminNavigationLayout>
      <TopNavigationLayoutPageContent type='default'>
        <div className='flex-space-between'>
          <div className='inline-flex-gap' style={{ gap: '2.5em' }}>
            <h2 className='page--header'>Создание компетенции</h2>
          </div>
        </div>
        <SkillSheet skill={draft} onChange={handleChange}>
          <Button theme='primary' loading={loading} onClick={handleSave} style={{ width: 'fit-content' }}>
            Создать
          </Button>
        </SkillSheet>
      </TopNavigationLayoutPageContent>
    </AdminNavigationLayout>
  )
}

export default CreateSkillPage
