import { IProject } from '../../projects.types'
import { memo } from 'react'
import Card from '../../../../shared/Cards/Card'
import { useRecoilValue } from 'recoil'
import eventsSelectors from '../../../Admin/Events/events.selectors'
import Image from '../../../../shared/Image'
import projectsUtils from '../../projects.utils'
import { useNavigate } from 'react-router'
import defaultSrc from 'assets/images/projectDefaultImage.jpg'
import organizationsSelectors from 'screens/Admin/Organizations/organizations.selectors'

interface IProps {
  project: IProject
}

const ProjectListCard = (props: IProps) => {
  const navigate = useNavigate()
  const eventsMap = useRecoilValue(eventsSelectors.eventsMap)
  const organizationsMap = useRecoilValue(organizationsSelectors.organiaztionsMap)

  return (
    <Card
      type='small'
      hoverEffect={true}
      style={{ border: '1px solid #2c5cfa50' }}
      onClick={() => navigate('/projects/' + props.project.id)}
    >
      <div className='flex-lines' style={{ maxWidth: '300px', height: '350px', overflow: 'auto' }}>
        <div className='flex-lines' style={{ gap: '4px' }}>
          <h2 className='text--caption-l text--bold' style={{ textAlign: 'center', marginBottom: '1em' }}>
            {props.project.name}
          </h2>
          <p className='text--caption-m text--bold' style={{ textAlign: 'center' }}>
            {props.project.organizationId ? organizationsMap[props.project.organizationId].name : 'Без заказчика'}
          </p>
          <p className='text--caption-m' style={{ textAlign: 'center' }}>
            {eventsMap[props.project.eventId]}
          </p>
        </div>
        <div style={{ marginTop: 'auto' }}>
          <Image
            src={projectsUtils.getImageLink(props.project.id)}
            alt={props.project.name}
            defaultImageSrc={defaultSrc}
            style={{ width: '300px', height: '100px', borderRadius: '16px', backgroundColor: '#2c5cfa10' }}
          />
        </div>
        <div style={{ justifyContent: 'center', paddingTop: '1em', height: 'fit-content' }} className='inline-flex'>
          <div className='flex-lines' style={{ gap: '0.5em' }}>
            <p className='text--caption-m' style={{ textAlign: 'center' }}>
              Команд
            </p>
            <p className='text--body-m' style={{ textAlign: 'center' }}>
              {props.project.projectToTeams.length} / {props.project.teamsTargetCount}
            </p>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default memo(ProjectListCard)
