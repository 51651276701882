import { Permission } from 'screens/Auth/auth.types'
import { PropsWithChildren } from 'react'
import useUserPermissionsSet from 'screens/Auth/hooks/useUserPermissionsSet'
import { useRecoilValue } from 'recoil'
import authAtom from 'screens/Auth/auth.atom'

interface IProps {
  permissions: (Permission | 'admin')[]
}

const ProtectedContent = (props: PropsWithChildren<IProps>) => {
  const user = useRecoilValue(authAtom).user
  const userPermissions = useUserPermissionsSet()

  if (props.permissions.includes('admin')) {
    if (!user.isAdmin) return null

    return <>{props.children}</>
  }

  if (!props.permissions.some((p) => userPermissions.has(p as any)) && props.permissions.length) return null
  return <>{props.children}</>
}

export default ProtectedContent
