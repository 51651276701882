import css from './twoListSelect.module.css'
import { useMemo, useState } from 'react'
import { ReactComponent as ArrowRight } from '../../assets/icons/longArrowRight.svg'
import twoListSelectUtils from './twoListSelect.utils'
import { Virtuoso } from 'react-virtuoso'
import { IOption } from '../../types/global'
import searchUtils, { SearchResult } from '../../utils/search'
import useDebounce from '../hooks/useDebounce'
import Checkbox from '../Inputs/Checkbox'
import ArrowButton from '../Buttons/ArrowButton'
import SearchInput from '../Inputs/SearchInput'

interface IProps<T extends string | number> {
  options: IOption<T>[]
  selected: T[]

  titleLeft: string | JSX.Element
  titleRight: string | JSX.Element

  onSelect?: (values: T[]) => void
  onUnselect?: (value: T[]) => void

  name?: string
  disabled?: boolean
}

export interface ListSelectOption<T extends string | number> extends IOption<T> {
  search: SearchResult[]
  index: number
}

const TwoListSelect = <T extends string | number>(props: IProps<T>) => {
  const [leftSelected, setLeftSelected] = useState<Array<T>>([])
  const leftSelectedSet = new Set(leftSelected)
  const [rightSelected, setRightSelected] = useState<Array<T>>([])
  const rightSelectedSet = new Set(rightSelected)

  const [leftSearch, setLeftSearch] = useState('')
  const [rightSearch, setRightSearch] = useState('')
  const debouncedLeftSearch = useDebounce(leftSearch, 300)
  const debouncedRightSearch = useDebounce(rightSearch, 300)
  const selectedSet = new Set(props.selected)

  const leftColSearchResults = useMemo(() => {
    const selectedOptions = props.options.filter((o) => selectedSet.has(o.value))
    return twoListSelectUtils.search(selectedOptions, debouncedLeftSearch)
  }, [debouncedLeftSearch, props.options, props.selected])

  const rightColSearchResults = useMemo(() => {
    const notSelectedOptions = props.options.filter((o) => !selectedSet.has(o.value))
    return twoListSelectUtils.search(notSelectedOptions, debouncedRightSearch)
  }, [debouncedRightSearch, props.options, props.selected])

  const handleCheck = (type: 'selected' | 'not-selected', option: ListSelectOption<T>) => (selected: boolean) => {
    if (type === 'not-selected') {
      if (selected) {
        setRightSelected((prev) => [...prev, option.value])
      } else {
        setRightSelected((prev) => prev.filter((v) => v !== option.value))
      }
      setLeftSelected([])
    } else {
      if (selected) {
        setLeftSelected((prev) => [...prev, option.value])
      } else {
        setLeftSelected((prev) => prev.filter((v) => v !== option.value))
      }
      setRightSelected([])
    }
  }

  const handleClick = (type: 'selected' | 'not-selected', option: ListSelectOption<T>) => {
    if (type === 'not-selected') props.onSelect([option.value])
    else props.onUnselect([option.value])
  }

  const renderOption = (column: 'selected' | 'not-selected', option: ListSelectOption<T>) => {
    return (
      <div className={css.row} key={option.value}>
        <div className={css.checkbox}>
          <Checkbox
            disabled={props.disabled}
            onCheck={handleCheck(column, option)}
            checked={column === 'selected' ? leftSelectedSet.has(option.value) : rightSelectedSet.has(option.value)}
          />
        </div>
        <div className={css.rowContent} onClick={() => handleClick(column, option)}>
          <span>{searchUtils.renderSearchResult(option.search)}</span>
          {!props.disabled && (
            <div className={css.arrowRight} data-column={column}>
              <ArrowRight />
            </div>
          )}
        </div>
      </div>
    )
  }

  const handleMoveToUnselected = () => {
    if (props.disabled) return

    props.onUnselect(leftSelected)
    setLeftSelected([])
  }

  const handleMoveToSelected = () => {
    if (props.disabled) return

    props.onSelect(rightSelected)
    setRightSelected([])
  }

  const renderSelectedOptions = () => {
    return leftColSearchResults.map((o) => renderOption('selected', o))
  }

  return (
    <div className={css.wrapper}>
      <div className={css.colWrapper}>
        <span className='text--body-m text--secondary' style={{ marginBottom: '1em' }}>
          {props.titleLeft}
        </span>
        <SearchInput
          value={leftSearch}
          name='leftSearch'
          onChange={setLeftSearch}
          placeholder='Поиск...'
          required={false}
          disabled={props.disabled}
        />
        <div className={css.col}>{renderSelectedOptions()}</div>
      </div>
      <div className={css.transferCol}>
        <ArrowButton direction='left' onClick={handleMoveToSelected} />
        <ArrowButton direction='right' onClick={handleMoveToUnselected} />
      </div>
      <div className={css.colWrapper}>
        <span className='text--body-m text--secondary' style={{ marginBottom: '1em' }}>
          {props.titleRight}
        </span>
        <SearchInput
          value={rightSearch}
          name='rightSearch'
          onChange={setRightSearch}
          placeholder='Поиск...'
          required={false}
          disabled={props.disabled}
        />
        <Virtuoso
          className={css.col}
          data={rightColSearchResults}
          itemContent={(index) => renderOption('not-selected', rightColSearchResults[index])}
          overscan={25}
        />
      </div>
    </div>
  )
}

export default TwoListSelect
