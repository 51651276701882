import useEntity from 'recoil/useEntity/useEntity'
import { IUserSkill } from '../userSkills.types'
import userSkillsAtom from '../userSkills.atom'
import userSkillsApi from '../userSkills.api'

const useUserSkill = (id: IUserSkill['id']) => {
  const { entity, loading } = useEntity({
    atom: userSkillsAtom,
    entityId: id,
    itemFetcher: userSkillsApi.getSkill,
    resource: 'userSKills',
  })

  return { skill: entity, loading }
}

export default useUserSkill
