import { IProject } from './projects.types'
import config from '../../config'
import accessTokenService from 'services/accessToken'

const generateEmptyProject = () => ({
  techStack: '',
  expectingResults: '',
  motivation: '',
  repositoryLink: '',
  taskTrackingLink: '',
  description: '',
  name: '',
  eventId: null,
  keyTechnologies: [],
})

const generateEmptyProjectAdmin = () => ({
  techStack: '',
  expectingResults: '',
  motivation: '',
  repositoryLink: '',
  taskTrackingLink: '',
  description: '',
  name: '',
  eventId: null,
  keyTechnologies: [],
  adminOnly: false,
  teamsTargetCount: 1,
  minEducationYear: 1,
  maxEducationYear: 5,
})

const getImageLink = (id: IProject['id']) =>
  `${config.API_URL}/nsi/v1/projects/${id}/image/raw?accessToken=${accessTokenService.get()}`

const projectsUtils = { generateEmptyProject, getImageLink, generateEmptyProjectAdmin }
export default projectsUtils
