import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useDebounce from 'shared/hooks/useDebounce'
import { useCallback } from 'react'
import useItemsSetter from 'recoil/useItemsSetter'
import useTableWithRequest from 'shared/Table/useTableWithRequest'
import Table from 'shared/Table'
import eventsTableAtom from './eventsTable.atom'
import eventsApi from '../../events.api'
import { IEvent } from '../../events.types'
import eventsTableFields from './eventsTable.fields'

const EventsTable = () => {
  const navigate = useNavigate()
  const state = useRecoilValue(eventsTableAtom)
  const debouncedSearch = useDebounce(state.filters.search, 400)

  const fetcher = useCallback(
    (params: Record<string, any>) => {
      return eventsApi.list({
        search: debouncedSearch,
        ...params,
      })
    },
    [debouncedSearch]
  )

  const itemsSetter = useItemsSetter(eventsTableAtom)

  const tableCtrl = useTableWithRequest({
    dataFetcher: fetcher,
    fields: eventsTableFields,
    itemKey: 'id',
    items: state.items,
    setItems: itemsSetter,
    tableKey: 'events',
  })

  const handleClick = useCallback((e: IEvent) => {
    navigate(`/admin/events/${e.id}`)
  }, [])

  return (
    <>
      <Table
        {...tableCtrl}
        items={tableCtrl.orderedItems}
        handleSelect={tableCtrl.selectedCtrl.setSelected}
        selected={tableCtrl.selectedCtrl.selectedIndexes as Set<keyof IEvent>}
        handleSelectAll={tableCtrl.selectedCtrl.setSelectAll}
        itemKey='id'
        onCLick={handleClick}
      />
    </>
  )
}

export default EventsTable
