import { IUser } from 'screens/Admin/RolesAndUsers/users.types'
import config from 'config'
import accessTokenService from 'services/accessToken'
import { ICreateUserDTO } from '../Admin/Users/users.api'

const generateUserImageURL = (id: IUser['id']) =>
  `${config.API_URL}/nsi/v1/users/${id}/image/raw?accessToken=${accessTokenService.get()}`

const extractUsernameShortCut = (str: string) => {
  if (!str) return ''

  const [lastName, name, middleName] = str.split(' ')

  if (lastName && name && middleName) {
    return `${lastName} ${name[0]}. ${middleName[0]}.`
  }

  if (lastName && name) {
    return `${lastName} ${name[0]}.`
  }

  return lastName
}

const generateEmptyUser = (): ICreateUserDTO => ({
  password: '',
  educationYear: 1,
  email: '',
  name: '',
  universityGroup: '',
})

const usersUtils = { generateUserImageURL, extractUsernameShortCut, generateEmptyUser }
export default usersUtils
