import { CSSProperties, PropsWithChildren, useRef } from 'react'
import { animated, useTransition } from '@react-spring/web'
import css from './smoothDropdown.module.css'
import cn from '../../../utils/cn'

interface IProps {
  open: boolean
  className?: string
  overflow?: string
  maxHeight?: number
  style?: CSSProperties

  onAnimationEnd?: () => void
}

const SmoothDropdown = (props: PropsWithChildren<IProps>) => {
  const contentRef = useRef<HTMLDivElement>(null)

  const transition = useTransition(props.open, {
    initial: { maxHeight: props.open ? contentRef.current?.scrollHeight : 0, opacity: props.open ? 1 : 0.5 },
    from: { maxHeight: 0, opacity: 0.5 },
    enter: () => async (next) => {
      // imitate layout effect
      await new Promise((res) => setTimeout(() => res(true), 50))

      if (!contentRef.current) return

      let ht = contentRef?.current?.scrollHeight
      // Plus 4px to prevent scrollbar appear with overflow: autp
      if (props.maxHeight) ht = Math.min(props.maxHeight + 4, ht + 4)

      await next({ opacity: 1, maxHeight: ht })

      contentRef.current.classList.remove(css.hidden)
      if (props.overflow) contentRef.current.classList.add(css[props.overflow])
    },
    leave: () => async (next) => {
      if (!contentRef.current) return

      contentRef.current.classList.remove(css.resolved)

      if (props.overflow) contentRef.current.classList.remove(css[props.overflow])
      contentRef.current.classList.add(css.hidden)

      // wait for some magic to make a browser recognize that the styles has changed
      await new Promise((res) => setTimeout(() => res(true), 50))

      await next({ opacity: 0.5, maxHeight: 0 })

      if (props.onAnimationEnd) props.onAnimationEnd()
    },
    onRest: (ev) => {
      if (!contentRef.current) return

      if (props.open && ev.finished && !props.maxHeight) {
        contentRef.current.classList.add(css.resolved)
      }
    },
    // If set to 300, real animation duration appears to be 350-380ms
    config: { duration: 250 },
    expires: true,
  })

  return transition((style, item) => {
    if (!item) return null
    // @ts-ignore
    if (!props.open) style.overflow = 'hidden'

    const normalizedExternalStyles = props.style || {}
    return (
      <animated.div
        className={cn(css.content, props.className)}
        style={{ ...normalizedExternalStyles, ...style }}
        ref={contentRef}
      >
        <div>{props.children}</div>
      </animated.div>
    )
  })
}

export default SmoothDropdown
