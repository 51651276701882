import { IProject } from 'screens/Projects/projects.types'
import SlideSelect from '../../../../../shared/Inputs/SlideSelect'
import projectStatusOptions from './projectStatusOptions'

interface IProps {
  selectedOption: IProject['status']
  onSelect: (value: IProject['status']) => void
  name?: string
}

const ProjectStatusSlideSelect = (props: IProps) => {
  return <SlideSelect options={projectStatusOptions} {...props} />
}

export default ProjectStatusSlideSelect
