import NotFoundPage from '../screens/404'
import { IRoute } from './navigation.types'
import navigationUtils from './navigation.utils'
import Projects from '../screens/Projects'
import MyTeam from '../screens/Teams/pages/MyTeam'
import Profile from '../screens/Profile'
import MyProject from '../screens/Projects/pages/MyProject'
import UserPage from 'screens/Users/pages/UserPage'
import ProjectPage from 'screens/Projects/pages/ProjectPage'
import UserSKillsPage from 'screens/Admin/UserSkills'
import CreateSkillPage from 'screens/Admin/UserSkills/pages/CreateSkillPage'
import SkillPage from 'screens/Admin/UserSkills/pages/SkillPage'
import OrganizationsPage from 'screens/Admin/Organizations'
import CreateOrganizationPage from 'screens/Admin/Organizations/pages/CreateOrganizationPage'
import OrganizationPage from 'screens/Admin/Organizations/pages/OrganizationPage'
import EventPage from 'screens/Admin/Events/pages/EventPage'
import CreateEventPage from 'screens/Admin/Events/pages/CreateEventPage'
import EventsPage from 'screens/Admin/Events'
import ProjectTechnologiesPage from 'screens/Admin/ProjectTechnologies'
import CreateProjectTechnologyPage from 'screens/Admin/ProjectTechnologies/pages/CreateProjectTechnologyPage'
import ProjectTechnologyPage from 'screens/Admin/ProjectTechnologies/pages/ProjectTechnologyPage'
import CreateProjectPage from 'screens/Projects/pages/CreateProject'
import TeamPage from 'screens/Teams/pages/TeamPage'
import AdminTeamsPage from 'screens/Admin/Teams'
import CreateTeam from 'screens/Admin/Teams/pages/CreateTeam'
import ImportPage from 'screens/Admin/Import'
import UsersAdminPage from 'screens/Admin/Users'
import ExportScreen from 'screens/Admin/Export'
import CreateUserPage from '../screens/Admin/Users/pages/CreateUser'

const appRoutes: IRoute[] = [
  {
    path: '*',
    element: <NotFoundPage />,
    permissions: [],
  },
  {
    path: '/projects',
    element: <Projects />,
    permissions: [],
    title: 'каталог',
  },
  {
    path: '/projects/:id',
    element: <ProjectPage />,
    permissions: [],
  },
  {
    path: '/my-project',
    element: <MyProject />,
    permissions: [],
    title: 'Проект',
  },
  {
    path: '/my-team',
    element: <MyTeam />,
    permissions: [],
    title: 'Команда',
  },
  {
    path: '/profile',
    element: <Profile />,
    permissions: [],
    title: 'Профиль',
  },
  {
    path: '/admin/skills',
    element: <UserSKillsPage />,
    permissions: ['admin'],
    title: 'Администрирование',
  },
  {
    path: '/admin/skills/create',
    element: <CreateSkillPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/skills/:id',
    element: <SkillPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/organizations',
    element: <OrganizationsPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/organizations/create',
    element: <CreateOrganizationPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/organizations/:id',
    element: <OrganizationPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/events',
    element: <EventsPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/events/create',
    element: <CreateEventPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/events/:id',
    element: <EventPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/project-technologies',
    element: <ProjectTechnologiesPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/project-technologies/create',
    element: <CreateProjectTechnologyPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/project-technologies/:id',
    element: <ProjectTechnologyPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/teams',
    element: <AdminTeamsPage />,
    permissions: ['admin'],
  },
  {
    path: '/users/:id',
    element: <UserPage />,
    permissions: [],
  },
  {
    path: '/admin/users/create',
    element: <CreateUserPage />,
    permissions: ['admin'],
  },
  {
    path: '/projects/create',
    element: <CreateProjectPage />,
    permissions: ['admin'],
  },
  {
    path: '/teams/:id',
    element: <TeamPage />,
    permissions: [],
  },
  {
    path: '/admin/teams/create',
    element: <CreateTeam />,
    permissions: ['admin'],
  },
  {
    path: '/admin/import',
    element: <ImportPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/users',
    element: <UsersAdminPage />,
    permissions: ['admin'],
  },
  {
    path: '/admin/export',
    element: <ExportScreen />,
    permissions: ['admin'],
  },
]

navigationUtils.makeRoutePagesProtected(appRoutes)
export default appRoutes
