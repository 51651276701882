import { CSSProperties, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import css from './dropdown.module.css'
import usePopup from '../../hooks/usePopup'
import cn from '../../../utils/cn'
import SmoothDropdown from '../../Animated/SmoothDropdownBehaivor/SmoothDropdown'
import { ReactComponent as ArrowDown } from '../../../assets/icons/triangleDown.svg'
import { IOption } from '../../../types/global'

interface IProps<T extends string | number> {
  options: IOption<T>[]
  selectedOption: IOption<T>['value']
  title: string | ReactNode
  onSelect: (option: T, name?: string) => void
  name?: string
  menuStyle?: CSSProperties
  loading?: boolean
  allowEmpty?: boolean
  disabled?: boolean
  virtual?: boolean
}

const Dropdown = <T extends string | number>(props: IProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)
  const selectedRef = useRef<IOption<T>>(null)
  const { open, setOpen } = usePopup(ref)
  const [showNotSelectedOption, setShowNotSelectedOption] = useState(
    props.allowEmpty !== false && Boolean(props.selectedOption)
  )

  const handleChange = (optionValue: T) => () => {
    if (props.disabled) return

    props.onSelect(optionValue, props.name)
    setOpen(false)
  }

  const selectedOption = useMemo(() => {
    const option = props.options.find((o) => o.value === props.selectedOption)
    selectedRef.current = option

    return option
  }, [props.selectedOption, props.options])

  const handleAnimationEnd = useCallback(() => {
    setShowNotSelectedOption(props.allowEmpty !== false && Boolean(selectedRef.current))
  }, [props.allowEmpty])

  const renderOptions = () => {
    return props.options.map((option) => (
      <span
        className={cn('text--body-s', css.option)}
        key={option.value}
        onClick={handleChange(option.value)}
        data-active={option.value === props.selectedOption}
      >
        {option.title}
      </span>
    ))
  }

  return (
    <div
      ref={ref}
      className={css.wrapper}
      data-open={open}
      data-loading={props.loading}
      onClick={() => !props.loading && setOpen(!open)}
    >
      <div
        data-open={open}
        className={cn('text--body-s line_clamp', Boolean(selectedOption) ? '' : 'text--placeholder')}
        title={(selectedOption?.title ?? props.title).toString()}
      >
        {selectedOption?.title ?? props.title}
      </div>

      {!props.disabled && <ArrowDown data-open={open} data-selected={Boolean(selectedOption)} className={css.arrow} />}
      <SmoothDropdown
        open={open}
        className={css.options}
        maxHeight={300}
        style={props.menuStyle}
        onAnimationEnd={handleAnimationEnd}
        overflow='auto'
      >
        {showNotSelectedOption && (
          <span className={cn('text--body-s', css.option)} onClick={handleChange(null)}>
            Не выбрано
          </span>
        )}
        {renderOptions()}
      </SmoothDropdown>
    </div>
  )
}

export default Dropdown
