import css from './slideSelect.module.css'
import { IOption } from '../../../types/global'

interface IProps<T extends string | number> {
  options: IOption<T>[]
  selectedOption: IOption<T>['value']
  onSelect: (option: T, name?: string) => void
  name?: string
}

const SlideSelect = <T extends string | number>(props: IProps<T>) => {
  const renderOptions = () => {
    return props.options.map((o) => (
      <div
        key={o.value}
        className={css.option}
        data-selected={props.selectedOption === o.value}
        onClick={() => props.onSelect(o.value, props.name)}
      >
        {o.title}
      </div>
    ))
  }
  return <div className={css.wrapper}>{renderOptions()}</div>
}

export default SlideSelect
