import { atom } from 'recoil'
import { ITeam } from '../../../Teams/teams.types'

interface ISelfTeamsState {
  loaded: boolean
  items: ITeam[]
}

const selfTeamsAtom = atom<ISelfTeamsState>({ key: '/self/teams', default: { items: [], loaded: false } })
export default selfTeamsAtom
