import { IOrganization } from '../../organizations.types'
import Select from '../../../../../shared/Inputs/Select'
import { useRecoilValue } from 'recoil'
import organizationsAtom from '../../organizations.atom'
import { useMemo } from 'react'

interface IProps<T extends string> {
  value: IOrganization['id']
  onChange: (val: IOrganization['id'], name: T) => void
  name: T
  title?: string
  allowEmpty?: boolean
}

const OrganizationSelect = <T extends string>(props: IProps<T>) => {
  const organizations = useRecoilValue(organizationsAtom)

  const options = useMemo(() => {
    let items = organizations.items.map((it) => ({ label: it.name, value: it.id }))
    if (props.allowEmpty) items = [{ label: 'Не выбрано', value: undefined }, ...items]

    return items
  }, [organizations.items])

  return (
    <Select
      onChange={props.onChange}
      selectedOption={options.find((o) => o.value === props.value)}
      name={props.name}
      options={options}
      title={props.title || 'Выберите организацию...'}
    />
  )
}

export default OrganizationSelect
