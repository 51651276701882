import { PropsWithChildren } from 'react'
import { ReactComponent as CloseIcon } from '../../../assets/icons/IconClose.svg'

const ModalHeader = (props: PropsWithChildren<{ onClose: () => void }>) => {
  return (
    <div className='modal-header'>
      <h2 className='text--primary text--heading-h5 text-700'>{props.children}</h2>
      <div className='inline-flex-gap'>
        <CloseIcon width={30} height={30} className='svg-clickable hover-bg' onClick={props.onClose} />
      </div>
    </div>
  )
}

export default ModalHeader
