import http, { handleHttpError, handleHttpResponse } from 'services/http'
import { IOrganization } from './organizations.types'
import { HTTPResponse, IPaginationResponse } from 'services/http/http.types'

interface IGetParams {
  search?: string
}
const list = async (params: IGetParams): Promise<HTTPResponse<IPaginationResponse<IOrganization>>> => {
  return http.get('/nsi/v1/organizations', { params }).then(handleHttpResponse).catch(handleHttpError)
}

const getOne = async (id: IOrganization['id']): Promise<HTTPResponse<IOrganization>> => {
  return http
    .get('/nsi/v1/organizations/' + id)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const create = async (dto: Pick<IOrganization, 'name' | 'contact'>): Promise<HTTPResponse<IOrganization>> => {
  return http.post('/nsi/v1/organizations', dto).then(handleHttpResponse).catch(handleHttpError)
}

const update = async (dto: Partial<IOrganization>, id: IOrganization['id']): Promise<HTTPResponse<IOrganization>> => {
  return http
    .patch('/nsi/v1/organizations/' + id, dto)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const organizationsApi = {
  update,
  create,
  getOne,
  list,
}
export default organizationsApi
