import { CSSProperties, PropsWithChildren, useRef } from 'react'
import ActionsButton from '../ActionsButton'
import SmoothPanel from '../../Animated/SmoothPanel'
import usePopup from '../../hooks/usePopup'

interface IProps {
  panelContentCn?: string
  iconStyle?: CSSProperties
}

const ActionsButtonWithMenu = (props: PropsWithChildren<IProps>) => {
  const ref = useRef()
  const { open, setOpen } = usePopup(ref)

  const icon = <ActionsButton onClick={() => setOpen(!open)} active={open} style={props.iconStyle} />

  return (
    <SmoothPanel open={open} icon={icon} transformOrigin="top right" ref={ref}>
      <div
        onClick={() => setOpen(false)}
        className={`panel-content bottom-right options-content ${props.panelContentCn || ''}`}
      >
        {props.children}
      </div>
    </SmoothPanel>
  )
}

export default ActionsButtonWithMenu
