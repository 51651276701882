import { useRecoilValue } from 'recoil'
import selfProjectsAtom from 'screens/Auth/components/InitProvider/selfProjects.atom'
import selfTeamsAtom from 'screens/Auth/components/InitProvider/selfTeams.atom'
import useSelfActiveProject from 'screens/Projects/hooks/useSelfActiveProject'

const useSelfActiveTeam = () => {
  const selfTeams = useRecoilValue(selfTeamsAtom)
  const selfProjects = useRecoilValue(selfProjectsAtom)
  const activeProject = useSelfActiveProject()

  if (!activeProject) {
    return selfTeams.items.find(
      (it) => !selfProjects.items.some((p) => p.projectToTeams.some((pt) => pt.teamId === it.id))
    )
  }

  return selfTeams.items.find((it) => activeProject.projectToTeams.some((pt) => pt.teamId === it.id))
}

export default useSelfActiveTeam
