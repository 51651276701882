import { atom } from 'recoil'
import { IUserSkill } from '../../userSkills.types'

interface IUserSkillsTableAtomState {
  items: IUserSkill[]
  filters: {
    search?: string
  }
}

const userSkillsTableAtom = atom<IUserSkillsTableAtomState>({
  key: 'tables/admin/users-skills',
  default: { items: [], filters: { search: '' } },
})
export default userSkillsTableAtom
