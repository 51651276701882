import css from './loaderPage.module.css'
import ThreeDotsLoader from '../ThreeDotsLoader'

interface IProps {
  inscription?: string
}

/** The page, that contains only loader */
const LoaderPage = (props: IProps) => {
  return (
    <div className={css.page}>
      <ThreeDotsLoader />
      {props.inscription && (
        <span style={{ fontSize: '14px' }}>{props.inscription}</span>
      )}
    </div>
  )
}

export default LoaderPage
