import useEntity from 'recoil/useEntity/useEntity'
import { IProject } from 'screens/Projects/projects.types'
import teamsTableAtom from '../components/TeamsTable/teamsTable.atom'
import { ITeam } from '../teams.types'
import teamsApi from '../teams.api'

const useTeam = (id: IProject['id']) => {
  const { entity, loading, setEntity } = useEntity<ITeam>({
    resource: 'teams',
    atom: teamsTableAtom,
    entityId: id,
    itemFetcher: teamsApi.getFullTeam,
  })

  return { team: entity, loading, setProject: setEntity }
}

export default useTeam
