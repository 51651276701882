import useSelfActiveTeam from 'screens/Teams/hooks/useSelfActiveTeam'
import useSelfActiveProject from './useSelfActiveProject'
import { useRecoilValue } from 'recoil'
import authAtom from 'screens/Auth/auth.atom'

const useIsJoinProjectPossible = () => {
  const selfId = useRecoilValue(authAtom).user.id
  const activeTeam = useSelfActiveTeam()
  const activeProject = useSelfActiveProject()

  return !activeProject && activeTeam?.ownerId === selfId
}

export default useIsJoinProjectPossible
