import { useRecoilValue } from 'recoil'
import selfInvitesAtom from '../../../../Auth/components/InitProvider/selfInvites.atom'
import { ITeamInvite } from '../../../teams.types'
import InviteCard from './InviteCard'

const InvitesContent = () => {
  const invites = useRecoilValue(selfInvitesAtom)

  const renderInvite = (invite: ITeamInvite) => {
    return <InviteCard invite={invite} key={invite.id} />
  }

  return <div className='flex-lines gap24'>{invites.items.map(renderInvite)}</div>
}

export default InvitesContent
