import styles from './authPage.module.css'
import cn from 'utils/cn'
import useAuthCtrl from '../../hooks/useAuthCtrl'
import AuthContext from '../../auth.context'
import CredentialsForm from './CredentialsForm'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { ReactComponent as Logo } from 'assets/images/logo/logoText.svg'
import { ReactComponent as LogoIcon } from 'assets/images/logo/logoNoText.svg'
import ValidationForm from '../../../../shared/Forms/ValidationForm/ValidationForm'
import Button from '../../../../shared/Buttons/Button/Button'
import { useState } from 'react'
import RegisterForm from './RegisterForm'

const AuthPage = () => {
  const [registerMode, setRegisterMode] = useState(false)
  const authCtrl = useAuthCtrl()

  const renderForm = () => {
    if (registerMode) {
      return (
        <RegisterForm
          valid={authCtrl.validationCtrl.valid}
          serverError={authCtrl.serverError}
          onRegistered={() => setRegisterMode(false)}
        >
          <p className='text--body-xs' style={{ textAlign: 'center' }}>
            <span>Есть аккаунт?</span>{' '}
            <span onClick={() => setRegisterMode(false)} className='text--link text--link-underline'>
              Войти
            </span>
          </p>
        </RegisterForm>
      )
    }

    return (
      <CredentialsForm serverError={authCtrl.serverError}>
        <Button loading={authCtrl.loading} type='submit' theme='primary' disabled={!authCtrl.validationCtrl.valid}>
          Войти <ArrowIcon />
        </Button>
        {registerMode && (
          <p className='text--body-xs' style={{ textAlign: 'center' }}>
            <span>Нет аккаунта?</span>{' '}
            <span onClick={() => setRegisterMode(true)} className='text--link text--link-underline'>
              Зарегестрироваться
            </span>
          </p>
        )}
      </CredentialsForm>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        values: authCtrl.authValues,
        onChange: authCtrl.handleChange,
      }}
    >
      <div className={styles.wrapper}>
        <LogoIcon className={styles.logoIcon} />
        <ValidationForm
          errors={authCtrl.validationCtrl.errors}
          onSubmit={registerMode ? () => {} : authCtrl.handleSubmitCredentials}
          setIsValid={authCtrl.validationCtrl.setIsValid}
          className={cn('flex-form', styles.content)}
        >
          <Logo className={styles.logo} />
          {renderForm()}
        </ValidationForm>
      </div>
    </AuthContext.Provider>
  )
}

export default AuthPage
