import AdminNavigationLayout from '../../../../../shared/Layouts/TopNavigationLayout/AdminNavifationLayout'
import TopNavigationLayoutPageContent from '../../../../../shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import Sheet from '../../../../../shared/Sheet'
import { useState } from 'react'
import usersUtils from '../../../../Users/users.utilts'
import Button from '../../../../../shared/Buttons/Button/Button'
import TextInput from '../../../../../shared/Inputs/TextInput'
import TextField from '../../../../../shared/Forms/TextField'
import NumberInput from '../../../../../shared/Inputs/NumberInput'
import useHttpLoader from '../../../../../shared/hooks/useHttpLoader'
import usersApi from '../../users.api'
import { useSetRecoilState } from 'recoil'
import usersTableAtom from '../../components/UsersTable/usersTable.atom'
import { useNavigate } from 'react-router'

const CreateUserPage = () => {
  const navigate = useNavigate()
  const setUsersState = useSetRecoilState(usersTableAtom)
  const { wait, loading } = useHttpLoader()
  const [user, setUser] = useState(usersUtils.generateEmptyUser())

  const handleChange = (val: any, name: string) => {
    setUser((prev) => ({ ...prev, [name]: val }))
  }

  const handleCreate = () => {
    wait(usersApi.createUser(user), (resp) => {
      if (resp.status === 'success') {
        setUsersState((prev) => ({ ...prev, items: [resp.body, ...prev.items] }))
        navigate('/admin/users')
      }
    })
  }

  return (
    <AdminNavigationLayout>
      <TopNavigationLayoutPageContent type='default'>
        <h2 className='page--header'>Создание пользователя</h2>
        <div style={{ width: '70%' }}>
          <Sheet>
            <div className='flex-lines'>
              <div className='grid-form-2'>
                <TextField header='Email'>
                  <TextInput value={user.email} name='email' onChange={handleChange} />
                </TextField>
                <TextField header='ФИО'>
                  <TextInput value={user.name} name='name' onChange={handleChange} />
                </TextField>
                <TextField header='Академическая группа'>
                  <TextInput value={user.universityGroup} name='universityGroup' onChange={handleChange} />
                </TextField>
                <TextField header='Год обучения'>
                  <NumberInput value={user.educationYear} name='educationYear' onChange={handleChange} />
                </TextField>
                <TextField header='Пароль'>
                  <TextInput value={user.password} name='password' onChange={handleChange} type='password' />
                </TextField>
              </div>
              <div className='form-footer'>
                <Button theme='primary' style={{ width: 'fit-content' }} loading={loading} onClick={handleCreate}>
                  Создать
                </Button>
              </div>
            </div>
          </Sheet>
        </div>
      </TopNavigationLayoutPageContent>
    </AdminNavigationLayout>
  )
}

export default CreateUserPage
