import { CSSProperties, PropsWithChildren, useCallback, useEffect, useReducer, useRef } from 'react'
import ErrorContext from './ErrorContext'

interface IProps {
  onSubmit?: () => void
  errors?: Record<string, string>
  setIsValid?: (isValid: boolean) => void
  className?: string
  style?: CSSProperties
}

const ValidationForm = (props: PropsWithChildren<IProps>) => {
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0)
  const errors = useRef<Record<string, string>>({})

  useEffect(() => {
    if (props.errors) {
      errors.current = { ...errors.current, ...props.errors }

      if (Object.values(errors.current).find(Boolean)) {
        forceUpdate()
        props.setIsValid(false)
      }
    }
  }, [props.errors])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (props.onSubmit) {
      const err = Object.values(errors.current).find(Boolean)
      if (!err) props.onSubmit()
    }
  }

  const getError = useCallback(
    (name: string) => {
      return errors.current[name]
    },
    [errors, updater]
  )

  const onValidate = (name: string, error: string) => {
    if (!props.setIsValid) return

    errors.current[name] = error

    const isValid = !Boolean(Object.values(errors.current).find(Boolean))
    props.setIsValid(isValid)
  }

  return (
    <ErrorContext.Provider value={{ onValidate, getError }}>
      <form onSubmit={handleSubmit} className={props.className} style={props.style}>
        {props.children}
      </form>
    </ErrorContext.Provider>
  )
}

export default ValidationForm
