import eventBus from '../eventBus/index'
import useConfirmService from './useConfirmService'
import Button from '../../shared/Buttons/Button/Button'
import Modal from '../../shared/Modal'
import ModalHeader from '../../shared/Modal/ModalHeader'

const ConfirmService = () => {
  const { active, setActive, params } = useConfirmService()

  const handleConfirm = () => {
    setActive(false)
    eventBus.pub('confirmService.response', { response: 'confirm' })
  }

  const handleCancel = () => {
    setActive(false)
    eventBus.pub('confirmService.response', { response: 'cancel' })
  }

  if (!active) return null

  return (
    <Modal onClose={handleCancel} size='sm'>
      <ModalHeader onClose={handleCancel}>
        <p className={`text--heading-h5 ${params.type === 'danger' ? 'text--alert' : ''}`}>{params.title}</p>
      </ModalHeader>
      <div className='modal-content'>
        <p className='text--body-m'>{params.description}</p>
      </div>
      <div className='modal-footer'>
        <Button theme='grey-outline' onClick={handleCancel} style={{ width: 'fit-content' }}>
          Отмена
        </Button>
        <Button
          data-danger={params.type === 'danger'}
          theme={params.type === 'danger' ? 'danger' : 'primary'}
          onClick={handleConfirm}
          style={{ width: 'fit-content' }}
        >
          Подтвердить
        </Button>
      </div>
    </Modal>
  )
}

export default ConfirmService
