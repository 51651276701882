import { useState } from 'react'
import useIsProjectEditable from 'screens/Projects/hooks/useIsProjectEditable'
import useProjectsActions from 'screens/Projects/hooks/useProjectsActions'
import { IProject, IProjectComment } from 'screens/Projects/projects.types'
import Button from 'shared/Buttons/Button/Button'
import Card from 'shared/Cards/Card'
import TextInput from 'shared/Inputs/TextInput'
import { ReactComponent as UserIcon } from 'assets/icons/user-solid.svg'
import css from './projectContentStyles.module.css'
import usersUtils from 'screens/Users/users.utilts'

interface IProps {
  project: Pick<IProject, 'id' | 'comments'>
}

const ProjectCommentsContent = (props: IProps) => {
  const projectActions = useProjectsActions()

  const [message, setMessage] = useState('')
  const isProjectEditable = useIsProjectEditable(props.project.id)

  const renderComment = (comment: IProjectComment, index: number) => {
    const isLast = index === props.project.comments.length - 1
    return (
      <div
        key={comment.id}
        className='inline-flex-gap'
        style={{ borderBottom: isLast ? undefined : '1px dashed var(--gray)', paddingBottom: '1em' }}
      >
        <div className='flex-lines' style={{ gap: '4px', width: '120px', alignItems: 'center' }}>
          <div className={css.userIcon}>
            <UserIcon className={css.userIconSvg} />
          </div>
          <p className='text--body-xs line_clamp'>{usersUtils.extractUsernameShortCut(comment.user.info.name)}</p>
        </div>

        <p className='text--body-m' style={{ marginBottom: 'auto' }}>
          {comment.message}
        </p>
      </div>
    )
  }

  const renderContent = () => {
    if (!props.project.comments.length) {
      return (
        <div style={{ height: '300px' }} className='flex-page-center'>
          <p className='text--body-m text--blue'>Тут пока ничего нет 😀</p>
        </div>
      )
    }

    return <div className='flex-lines gap24'>{props.project.comments.map(renderComment)}</div>
  }

  return (
    <div className='flex-lines'>
      <p className='text--body-s'>* Настоящий раздел предназначен для коммуникации администраторов и студентов</p>
      <Card type='normal'>{renderContent()}</Card>
      {isProjectEditable && (
        <Card type='small'>
          <div className='inline-flex-gap' style={{ width: '100%' }}>
            <TextInput
              value={message}
              onChange={setMessage}
              name='message'
              required={false}
              placeholder='Текст комментария'
            />
            <Button
              style={{ width: 'fit-content' }}
              theme='primary'
              disabled={!message}
              onClick={() => projectActions.createComment(props.project.id, message).then(() => setMessage(''))}
              loading={projectActions.loading}
            >
              Отправить
            </Button>
          </div>
        </Card>
      )}
    </div>
  )
}

export default ProjectCommentsContent
