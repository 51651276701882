import useEntity from 'recoil/useEntity/useEntity'
import projectTechnologiesApi from '../projectTechnologies.api'
import { IProjectTechnogy } from '../projectTechnologies.types'
import projectTechnologiesTableAtom from '../componenets/ProjectTechnologiesTable/projectTechnologiesTable.atom'

const useProjectTechnology = (id: IProjectTechnogy['id']) => {
  const { entity, loading } = useEntity({
    atom: projectTechnologiesTableAtom,
    entityId: id,
    itemFetcher: projectTechnologiesApi.getOne,
    resource: 'projectTechnologies',
  })

  return { technology: entity, loading }
}

export default useProjectTechnology
