import { TableVirtuoso } from 'react-virtuoso'
import Checkbox from '../Inputs/Checkbox'
import { ReactComponent as ArrowRight } from '../../assets/icons/arrowRight.svg'
import PanelDropdown from '../Inputs/PanelDropdown'
import tableItemsPerPageOptions from './tableItemsPerPageOptions'
import TableRow from './TableRow'
import TableTh from './TableTh'
import { ResizeColumnPluginCtrl } from './plugins/resize/useResizeColumnPluginCtrl'
import TableRequestItemsPerPageService from './TableRequestItemsPerPageService'
import ThreeDotsLoader from '../Loaders/ThreeDotsLoader'

export interface ITableField<T extends Record<string, any>, K extends keyof T | string> {
  key: K
  getter: (item: T, key: K) => string | JSX.Element
  sortGetter: (item: T, key: K) => string | number
  header: string | JSX.Element
  width: string

  // true, если сервер по какой-то причине не может выполнять сортировку по этому полю.
  clientSideOrdering?: boolean
}

interface IProps<T extends Record<string, any>, K extends keyof T> {
  fields: ITableField<T, K>[]

  loading: boolean
  items: T[]
  itemKey: K
  totalItemsCount: number

  pageNumber: number
  onPageNumberChange: (page: number) => void

  itemsPerPage: number
  onItemsPerPageChange: (newCount: number) => void

  orderingState: Partial<Record<keyof T, -1 | 0 | 1>>
  onOrderingStateChange: (updated: Partial<Record<keyof T, -1 | 0 | 1>>) => void

  selectEnabled?: boolean
  handleSelectAll: (allSelected: boolean) => void
  selected: Set<K>
  handleSelect: (key: K, selected: boolean) => void

  onCLick?: (item: T) => void
  renderItemContextMenu?: (item: T) => JSX.Element
  onSwap: (target: K, dropped: K) => void

  resizePluginCtrl: ResizeColumnPluginCtrl
}

const Table = <T extends Record<string, any>, K extends keyof T>(props: IProps<T, K>) => {
  const renderHeader = () => {
    return (
      <tr>
        {props.selectEnabled !== false && (
          <th className='table--checkbox-col'>
            <div>
              <Checkbox checked={props.selected.size === props.items.length} onCheck={props.handleSelectAll} />
            </div>
          </th>
        )}
        {props.fields.map((field, index) => (
          <TableTh
            column={field as any}
            orderingState={props.orderingState[field.key] ?? 0}
            key={field.key.toString()}
            onOrderingStateTrigger={(key: string) =>
              props.onOrderingStateChange({
                ...props.orderingState,
                [key]: props.orderingState[key] === 1 ? -1 : (props.orderingState[key] ?? 0) + 1,
              })
            }
            onSwap={props.onSwap as any}
            index={index}
            resizePluginCtrl={props.resizePluginCtrl}
          />
        ))}

        {props.renderItemContextMenu && <th style={{ width: '18px' }} />}
      </tr>
    )
  }

  const renderItem = (index: number, item: T) => (
    <TableRow
      selectEnabled={props.selectEnabled}
      fields={props.fields}
      selected={props.selected}
      handleSelect={props.handleSelect}
      item={item}
      itemKey={props.itemKey}
      renderItemContextMenu={props.renderItemContextMenu}
      onCLick={props.onCLick}
      index={index}
    />
  )

  return (
    <>
      <div className='tableContainer'>
        <div className='tableWrapper' data-loading={props.loading}>
          <TableVirtuoso
            className='table'
            fixedHeaderContent={renderHeader}
            data={props.items}
            itemContent={renderItem}
            overscan={10}
          />
          {props.loading && (
            <div className='tableLoaderContainer'>
              <ThreeDotsLoader />
            </div>
          )}
        </div>
        <div className='text--body-s inline-flex-gap tableFooter' style={{ gap: '3em' }}>
          <div className='inline-flex-gap'>
            <span>Записей на странице:</span>
            <PanelDropdown
              selectedTitle={props.itemsPerPage.toString()}
              selectedOption={props.itemsPerPage}
              options={tableItemsPerPageOptions}
              onSelect={props.onItemsPerPageChange}
            />
          </div>
          <div className='inline-flex-gap' style={{ gap: '31px' }}>
            <span>
              {props.itemsPerPage * props.pageNumber +
                1 +
                '-' +
                Math.min(props.itemsPerPage * (props.pageNumber + 1), props.totalItemsCount)}{' '}
              из {props.totalItemsCount}
            </span>
            <ArrowRight
              data-disabled={props.pageNumber === 0}
              className='tableArrowLeft tableArrow'
              onClick={() => props.onPageNumberChange(props.pageNumber - 1)}
            />
            <ArrowRight
              data-disabled={props.itemsPerPage * (props.pageNumber + 1) + 1 >= props.totalItemsCount}
              className='tableArrow'
              onClick={() => props.onPageNumberChange(props.pageNumber + 1)}
            />
          </div>
        </div>
      </div>
      <TableRequestItemsPerPageService />
    </>
  )
}

export default Table
