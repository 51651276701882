import { ITableField } from './index'
import { useEffect } from 'react'
import usePersistentState from '../hooks/usePersistentState'

const usePersistenceFields = <T extends Record<string, any>>(
  initialState: ITableField<T, keyof T>[],
  tableKey: string
) => {
  const [persistenceKeys, setPersistenceKeys] = usePersistentState(
    initialState.map((f) => f.key),
    `${tableKey}/fields`
  )
  const [persistenceWidths, setPersistenceWidths] = usePersistentState(
    initialState.map((f) => f.width),
    `${tableKey}/fieldsWidth`
  )

  const [fields, setFields] = usePersistentState(
    getOrderedFields(persistenceKeys, persistenceWidths, initialState),
    `${tableKey}/fields`,
    'inMemory'
  )

  useEffect(() => {
    setPersistenceKeys(fields.map((f) => f.key))
    setPersistenceWidths(fields.map((f) => f.width))
  }, [fields])

  return [fields, setFields] as const
}

const getOrderedFields = <T extends Record<string, any>>(
  keys: (keyof T)[],
  widths: string[],
  fields: ITableField<T, keyof T>[]
): ITableField<T, keyof T>[] => {
  const arr = []
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]
    const width = widths[i]

    arr.push({ ...fields.find((f) => f.key === key), width })
  }

  return arr
}

export default usePersistenceFields
