import { useMemo, useState } from 'react'
import TopNavigationLayoutPageContent from '../../../../shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import { useRecoilValue } from 'recoil'
import selfProjectsAtom from '../../../Auth/components/InitProvider/selfProjects.atom'
import SlideSelect from '../../../../shared/Inputs/SlideSelect'
import CreateProjectContent from './CreateProjectContent'
import ProjectInfoContent from './ProjectInfoContent'
import { IProject } from '../../projects.types'
import eventsSelectors from '../../../Admin/Events/events.selectors'
import selfTeamsAtom from '../../../Auth/components/InitProvider/selfTeams.atom'
import authAtom from '../../../Auth/auth.atom'
import { ReactComponent as HandIcon } from '../../../../assets/icons/hand-regular.svg'
import { useNavigate } from 'react-router'
import ProjectCommentsContent from 'screens/Projects/components/ProjectCommentsContent'

const MyProject = () => {
  const navigate = useNavigate()
  const userInfo = useRecoilValue(authAtom).user
  const selfTeams = useRecoilValue(selfTeamsAtom).items
  const activeTeam = selfTeams[0]

  const eventsMap = useRecoilValue(eventsSelectors.eventsMap)
  const selfProjects = useRecoilValue(selfProjectsAtom).items
  const hasProject = Boolean(selfProjects.length)

  const [selectedSlide, setSelectedSlide] = useState(selfProjects.length ? 'my-project' : 'create-project')

  const slidesOptions = useMemo(() => {
    if (!selfProjects.length) {
      return [{ value: 'create-project', title: 'Создать проект' }]
    }

    return [
      { value: 'my-project', title: 'Мой проект' },
      { value: 'my-project-comments', title: `Комментарии (${selfProjects[0].comments.length})` },
    ]
  }, [selfProjects])

  const renderContent = () => {
    switch (selectedSlide) {
      case 'create-project':
        return <CreateProjectContent onCreated={() => setSelectedSlide('my-project')} />
      case 'my-project':
        return <ProjectInfoContent project={selfProjects[0]} />
      case 'my-project-comments':
        return <ProjectCommentsContent project={selfProjects[0]} />
    }
  }

  if (!activeTeam) {
    return (
      <TopNavigationLayoutPageContent type='default'>
        <div className='flex-page-center'>
          <HandIcon style={{ height: '100px', width: '100px', color: 'var(--secondary)' }} />
          <div style={{ maxWidth: '400px', textAlign: 'center' }}>
            <p className='text--body-l text--blue' style={{ marginBottom: '8px' }}>
              Прежде чем создать проект необходимо создать команду.
            </p>
            <p className='text--body-l'>
              Для создания перейдите во вкладку{' '}
              <span className='text--link text--link-underline' onClick={() => navigate('/my-team')}>
                "Команда"
              </span>
              , либо вступите в уже существующую команду.
            </p>
          </div>
        </div>
      </TopNavigationLayoutPageContent>
    )
  }

  if (!selfProjects.length && activeTeam.ownerId !== userInfo.id) {
    return (
      <TopNavigationLayoutPageContent type='default'>
        <div className='flex-page-center'>
          <HandIcon style={{ height: '100px', width: '100px', color: 'var(--secondary)' }} />
          <div style={{ maxWidth: '400px', textAlign: 'center' }}>
            <p className='text--body-l text--blue' style={{ marginBottom: '8px' }}>
              Создать проект может только лидер вашей команды.
            </p>
          </div>
        </div>
      </TopNavigationLayoutPageContent>
    )
  }

  return (
    <TopNavigationLayoutPageContent type='default'>
      <div className='flex-space-between'>
        <div className='inline-flex-gap' style={{ gap: '32px', width: '100%' }}>
          <div className='flex-lines' style={{ gap: '6px', flexShrink: 0, width: 'fit-content' }}>
            <h2 className='text--heading-h4'>{hasProject ? selfProjects[0].name + ' ' : 'Управление проектом'}</h2>
            {hasProject && selfProjects[0].eventId && (
              <p className='text--caption-m'>
                {translateProjectStatus(selfProjects[0].status)} • {eventsMap[selfProjects[0].eventId]}
              </p>
            )}
          </div>
          <div
            style={{
              flexShrink: 0,
              marginBottom: hasProject ? 'auto' : undefined,
              marginLeft: hasProject ? '2em' : undefined,
            }}
          >
            <SlideSelect
              options={slidesOptions}
              selectedOption={selectedSlide}
              onSelect={(val) => setSelectedSlide(val)}
            />
          </div>
        </div>
      </div>
      {renderContent()}
    </TopNavigationLayoutPageContent>
  )
}

export const translateProjectStatus = (status: IProject['status']) => {
  switch (status) {
    case 'REJECTED':
      return <span className='text--alert text--caption-m text--bold'>Отклонен</span>
    case 'PENDING':
      return <span className='text--blue text--caption-m text--bold'>На рассмотрении</span>
    case 'APPROVED':
      return <span className='text--success text--caption-m text--bold'>Одобрен</span>
  }
}

export default MyProject
