import { useCallback, useEffect, useState } from 'react'
import { produce, enableMapSet } from 'immer'
enableMapSet()

interface IProps<T extends string | number> {
  len: number
  allKeys?: T[]
}

const useSelect = <T extends string | number>({ len, allKeys }: IProps<T>) => {
  const [selectedIndexes, setSelectedItems] = useState(new Set<T>())
  const [selectedAll, setSelectedAll] = useState(false)

  useEffect(() => {
    setSelectedAll(selectedIndexes.size === len)
  }, [selectedIndexes.size])

  const setSelectAll = useCallback(
    (v: boolean) => {
      setSelectedAll(v)
      setSelectedItems(v ? (allKeys ? new Set(allKeys) : new Set<T>(Array(len).keys() as any)) : new Set<T>())
    },
    [len, allKeys]
  )

  useEffect(() => {
    setSelectedAll(false)
  }, [len, allKeys])

  const setSelected = useCallback((index: T, selected?: boolean) => {
    setSelectedItems((prev) => {
      const isSelected = selected ?? prev.has(index)

      return produce(prev, (draft: Set<T>) => {
        isSelected ? draft.add(index) : draft.delete(index)
      })
    })
  }, [])

  return {
    selectedCount: selectedIndexes.size,
    selectedIndexes,
    selectedAll,

    setSelected,
    setSelectAll,
  }
}

export default useSelect
