import * as process from 'process'

const config = {
  WS_URL: '',
  API_URL: 'https://xn--e1aajagmjdbheh6azd.xn--p1ai',
  VERSION: process.env.REACT_APP_VERSION || 'Not found',
  VERSION_TS: parseInt(process.env.REACT_APP_VERSION_TS) || 0,
  production: process.env.NODE_ENV === 'production',
}

export default config
