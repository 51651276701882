import { ITeam } from 'screens/Teams/teams.types'
import { ITableField } from 'shared/Table'

const teamsTableFields: ITableField<ITeam, keyof ITeam>[] = [
  {
    key: 'name',
    header: 'Название',
    getter: (team) => team.name,
    sortGetter: (team) => team.name,
    width: '400px',
  },
  {
    key: 'maxCapacity',
    header: 'Вместимость',
    getter: (team) => team.maxCapacity.toString(),
    sortGetter: (team) => team.maxCapacity,
    width: '100px',
  },
  {
    key: 'members',
    header: 'Члены команды',
    getter: (team) => team.members.map((m) => m.user.info.name).join(', '),
    sortGetter: (team) => team.members.length,
    width: '600px',
  },
]
export default teamsTableFields
