import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import selfProjectsAtom from 'screens/Auth/components/InitProvider/selfProjects.atom'
import useHttpLoader from 'shared/hooks/useHttpLoader'
import projectsAtom from '../projects.atom'
import projectsApi, { ICreateProjectDTO } from '../projects.api'
import { IProject } from '../projects.types'
import recoilStateUpdaters from 'recoil/recoil.stateUpdaters'

const useProjectsActions = () => {
  const { wait, loading } = useHttpLoader()

  const setProjectState = useSetRecoilState(projectsAtom)
  const setSelfProjectsState = useSetRecoilState(selfProjectsAtom)

  const update = useCallback((id: IProject['id'], updates: Partial<ICreateProjectDTO>) => {
    return wait(projectsApi.updateProject(id, updates), (resp) => {
      if (resp.status === 'success') {
        recoilStateUpdaters.itemUpdated(setProjectState, resp.body)
        recoilStateUpdaters.itemUpdated(setSelfProjectsState, resp.body)
      }

      return resp
    })
  }, [])

  const projectUpdated = useCallback((project: IProject) => {
    recoilStateUpdaters.itemUpdated(setProjectState, project)
    recoilStateUpdaters.itemUpdated(setSelfProjectsState, project)
  }, [])

  const createComment = useCallback((id: IProject['id'], message: string) => {
    return wait(projectsApi.addProjectComment(id, message), (resp) => {
      if (resp.status === 'success') {
        setProjectState((prev) => {
          const projectIndex = prev.items.findIndex((it) => it.id === id)
          if (projectIndex === -1) return prev

          return {
            ...prev,
            items: [
              ...prev.items.slice(0, projectIndex),
              { ...prev.items[projectIndex], comments: [...prev.items[projectIndex].comments, resp.body] },
              ...prev.items.slice(projectIndex + 1),
            ],
          }
        })

        setSelfProjectsState((prev) => {
          const projectIndex = prev.items.findIndex((it) => it.id === id)
          if (projectIndex === -1) return prev

          return {
            ...prev,
            items: [
              ...prev.items.slice(0, projectIndex),
              { ...prev.items[projectIndex], comments: [...prev.items[projectIndex].comments, resp.body] },
              ...prev.items.slice(projectIndex + 1),
            ],
          }
        })
      }

      return resp
    })
  }, [])

  return { loading, update, createComment, projectUpdated }
}

export default useProjectsActions
