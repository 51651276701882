import { PropsWithChildren } from 'react'
import css from './adminNavigationLayout.module.css'
import NavigationLink from 'navigation/components/NavigationLink'
import UserSKillsPage from 'screens/Admin/UserSkills'
import OrganizationsPage from 'screens/Admin/Organizations'
import EventsPage from 'screens/Admin/Events'
import ProjectTechnologiesPage from 'screens/Admin/ProjectTechnologies'
import { ReactComponent as SkillsIcon } from 'assets/icons/skill.svg'
import { ReactComponent as EventsIcon } from 'assets/icons/event.svg'
import { ReactComponent as OrganizationsIcon } from 'assets/icons/organization.svg'
import { ReactComponent as TechIcon } from 'assets/icons/technology.svg'
import { ReactComponent as UserIcon } from 'assets/icons/user-solid.svg'
import AdminTeamsPage from 'screens/Admin/Teams'
import ImportPage from 'screens/Admin/Import'
import UsersAdminPage from 'screens/Admin/Users'
import ExportScreen from 'screens/Admin/Export'

const AdminNavigationLayout = (props: PropsWithChildren<any>) => {
  return (
    <div className={css.content}>
      <div className={css.leftCol}>
        <NavigationLink
          route={{
            path: '/admin/skills',
            element: <UserSKillsPage />,
            title: 'Компетенции',
            icon: <SkillsIcon style={{ width: '24px', height: '24px', margin: '5px 0' }} />,
          }}
          className={css.navLink}
        />
        <NavigationLink
          route={{
            path: '/admin/organizations',
            element: <OrganizationsPage />,
            title: 'Организации',
            icon: <OrganizationsIcon style={{ width: '24px', height: '24px', margin: '5px 0' }} />,
          }}
          className={css.navLink}
        />
        <NavigationLink
          route={{
            path: '/admin/events',
            element: <EventsPage />,
            title: 'События',
            icon: <EventsIcon style={{ width: '24px', height: '24px', margin: '5px 0' }} />,
          }}
          className={css.navLink}
        />
        <NavigationLink
          route={{
            path: '/admin/project-technologies',
            element: <ProjectTechnologiesPage />,
            title: 'Ключевые технологии',
            icon: <TechIcon style={{ width: '24px', height: '24px', margin: '5px 0' }} />,
          }}
          className={css.navLink}
        />
        <NavigationLink
          route={{
            path: '/admin/teams',
            element: <AdminTeamsPage />,
            title: 'Команды',
            icon: <UserIcon style={{ width: '24px', height: '24px', margin: '5px 0' }} />,
          }}
          className={css.navLink}
        />
        <NavigationLink
          route={{
            path: '/admin/users',
            element: <UsersAdminPage />,
            title: 'Пользователи',
            icon: <UserIcon style={{ width: '24px', height: '24px', margin: '5px 0' }} />,
          }}
          className={css.navLink}
        />
        <NavigationLink
          route={{
            path: '/admin/import',
            element: <ImportPage />,
            title: 'Импорт проектов',
            icon: <UserIcon style={{ width: '24px', height: '24px', margin: '5px 0' }} />,
          }}
          className={css.navLink}
        />
        <NavigationLink
          route={{
            path: '/admin/export',
            element: <ExportScreen />,
            title: 'Экспорт',
            icon: <UserIcon style={{ width: '24px', height: '24px', margin: '5px 0' }} />,
          }}
          className={css.navLink}
        />
      </div>
      {props.children}
    </div>
  )
}

export default AdminNavigationLayout
