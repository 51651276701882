import useEntity from 'recoil/useEntity/useEntity'
import eventsTableAtom from '../components/EventsTable/eventsTable.atom'
import { IEvent } from '../events.types'
import eventsApi from '../events.api'

const useEvent = (id: IEvent['id']) => {
  const { entity, loading } = useEntity<IEvent>({
    atom: eventsTableAtom,
    entityId: id,
    itemFetcher: eventsApi.getOne,
    resource: 'events',
  })

  return { event: entity, loading }
}

export default useEvent
