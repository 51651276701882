import { atom } from 'recoil'
import { IOrganization } from '../../organizations.types'

interface IOrganizaionsTableAtomState {
  items: IOrganization[]
  filters: {
    search?: string
  }
}

const organiaztionsTableAtom = atom<IOrganizaionsTableAtomState>({
  key: 'tables/admin/organizations',
  default: { items: [], filters: { search: '' } },
})
export default organiaztionsTableAtom
