import { CSSProperties, PropsWithChildren } from 'react'
import css from './textField.module.css'

const TextField = (props: PropsWithChildren<{ header: string; contentStyle?: CSSProperties }>) => {
  return (
    <div className={css.wrapper}>
      <h2 className="text--secondary text--body-m">{props.header}</h2>
      <p className="text--body-m text--primary" style={props.contentStyle}>
        {props.children}
      </p>
    </div>
  )
}

export default TextField
