import { useCallback } from 'react'
import { EntityRecoilState, EntityWithId } from '../useEntity/useEntity.types'
import { RecoilState, useSetRecoilState } from 'recoil'

const useItemsSetter = <T extends EntityWithId>(atom: RecoilState<EntityRecoilState<T>>) => {
  const setAtomState = useSetRecoilState(atom)

  return useCallback(
    (p: T[] | ((prev: T[]) => T[])) => {
      if (typeof p === 'function') {
        setAtomState((prev) => ({ ...prev, items: p(prev.items) }))
      } else {
        setAtomState((prev) => ({ ...prev, items: p }))
      }
    },
    [setAtomState]
  )
}

export default useItemsSetter
