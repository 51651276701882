import { ListSelectOption } from './index'
import { IOption } from '../../types/global'
import searchUtils from '../../utils/search'

const search = <T extends string | number>(options: IOption<T>[], searchString: string): ListSelectOption<T>[] => {
  const values = searchString
    .toLowerCase()
    .split(' ')
    .filter((v) => v)

  const matches: ListSelectOption<T>[] = []
  for (const option of options) {
    const result = searchUtils.search(option.title.toString(), values)

    if (result.length !== 0) matches.push({ ...option, index: matches.length, search: result })
  }

  return matches
}

const twoListSelectUtils = { search }
export default twoListSelectUtils
