import { CSSProperties, forwardRef, PropsWithChildren } from 'react'
import css from '../card.module.css'

export type CardType = 'normal' | 'small'

const Card = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ type: CardType; style?: CSSProperties; hoverEffect?: boolean; onClick?: () => void }>
>((props, ref) => {
  return (
    <div
      className={css.card}
      data-cardtype={props.type}
      style={props.style}
      ref={ref}
      data-hover={props.hoverEffect}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  )
})

export default Card
