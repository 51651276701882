import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import recoilStateUpdaters from 'recoil/recoil.stateUpdaters'
import { IUser } from 'screens/Admin/RolesAndUsers/users.types'
import UserSelect from 'screens/Admin/Users/components/UserSelect'
import teamsTableAtom from 'screens/Teams/components/TeamsTable/teamsTable.atom'
import teamsApi from 'screens/Teams/teams.api'
import Button from 'shared/Buttons/Button/Button'
import TextField from 'shared/Forms/TextField'
import NumberInput from 'shared/Inputs/NumberInput'
import TextInput from 'shared/Inputs/TextInput'
import AdminNavigationLayout from 'shared/Layouts/TopNavigationLayout/AdminNavifationLayout'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import Sheet from 'shared/Sheet'
import useHttpLoader from 'shared/hooks/useHttpLoader'

const CreateTeam = () => {
  const setTeamsState = useSetRecoilState(teamsTableAtom)
  const { wait, loading } = useHttpLoader()

  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [owner, setOwner] = useState<IUser>(null)
  const [capacity, setCapacity] = useState(5)

  const handleSave = () => {
    wait(teamsApi.createTeam({ name, ownerId: owner.id, maxCapacity: capacity }), (resp) => {
      if (resp.status === 'success') {
        recoilStateUpdaters.itemCreated(setTeamsState, resp.body)
        navigate('/teams/' + resp.body.id)
      }
    })
  }

  return (
    <AdminNavigationLayout>
      <TopNavigationLayoutPageContent type='defaultWithBackButton'>
        <h2 className='page--header' style={{ marginBottom: '2em' }}>
          Создание команды
        </h2>
        <Sheet>
          <div className='flex-lines'>
            <TextField header='Наименование'>
              <TextInput value={name} onChange={setName} placeholder='БРИКС' name='name' />
            </TextField>
            <div className='grid-form-2'>
              <TextField header='Лид Команды'>
                <UserSelect selectedUser={owner} onSelect={setOwner} />
              </TextField>
              <TextField header='Максимальная вместимость (человек)'>
                <NumberInput value={capacity} onChange={setCapacity} name='capacity' />
              </TextField>
            </div>
            <div className='form-footer'>
              <Button
                theme='primary'
                style={{ width: 'fit-content' }}
                disabled={!owner || !name}
                loading={loading}
                onClick={handleSave}
              >
                Сохранить
              </Button>
            </div>
          </div>
        </Sheet>
      </TopNavigationLayoutPageContent>
    </AdminNavigationLayout>
  )
}

export default CreateTeam
