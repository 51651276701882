import React from 'react'
import css from './loader.module.css'

const CircleLoader = ({ color, ...props }: React.SVGProps<HTMLDivElement> & { color?: string }) => {
  const ringStyle = {
    color: color ?? 'var(--primary)',
  }

  return (
    <div {...props} className={css.ring}>
      <div className={css.spinner} style={ringStyle}></div>
      <div className={css.spinner} style={ringStyle}></div>
      <div className={css.spinner} style={ringStyle}></div>
      <div className={css.spinner} style={ringStyle}></div>
    </div>
  )
}

export default CircleLoader
