import { ChangeEvent, CSSProperties, PropsWithChildren, useContext, useEffect, useRef } from 'react'
import errorContext from '../../Forms/ValidationForm/ErrorContext'
import cn from '../../../utils/cn'

export interface ITextInputProps {
  value: string
  name: string
  onChange: (val: string, name: string, valid: boolean) => void
  disabled?: boolean
  required?: boolean // default true
  autofocus?: boolean
  type?: string
  style?: CSSProperties
  className?: string
  placeholder?: string
  maxLength?: number
  minLength?: number
  listenForAutofill?: boolean
  autoComplete?: string
  height?: string
}

const Textarea = ({ onChange, value, children, className, ...props }: PropsWithChildren<ITextInputProps>) => {
  const ref = useRef<HTMLTextAreaElement>()

  const context = useContext(errorContext)
  const error = context.getError(props.name)

  useEffect(() => {
    if (!props.required) return context.onValidate(props.name, '')

    if (error && error !== '-') return
    const validationMessage = ref.current.validity.valid ? '' : '-'

    context.onValidate(props.name, validationMessage)

    return () => {
      context.onValidate(props.name, '')
    }
  }, [props.required])

  const handleBlur = () => {
    onChange(ref.current.value, props.name, ref.current.validity.valid)

    const validationMessage = ref.current.validity.valid ? '' : '-'
    context.onValidate(props.name, validationMessage)
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const validationMessage = e.target.validity.valid ? '' : '-'

    context.onValidate(props.name, validationMessage)
    onChange(e.target.value, props.name, e.target.validity.valid)
  }

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {children}
      <textarea
        className={cn('etba-input', className || 0)}
        {...props}
        value={value ?? ''}
        required={props.required ?? true}
        onChange={handleChange}
        ref={ref}
        data-invalid={Boolean(error)}
        onBlur={handleBlur}
        style={{ height: props.height ?? 'auto' }}
      />
      {error && error !== '-' && <p className="etba-input_error text--danger">{error}</p>}
    </div>
  )
}

export default Textarea
