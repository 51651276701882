import cn from '../../../../../utils/cn'
import styles from '../authPage.module.css'
import { PropsWithChildren, useContext } from 'react'
import authContext from '../../../auth.context'
import Checkbox from '../../../../../shared/Inputs/Checkbox'
import TextInput from '../../../../../shared/Inputs/TextInput'

interface IProps {
  serverError: string
}

const CredentialsForm = (props: PropsWithChildren<IProps>) => {
  const context = useContext(authContext)

  return (
    <>
      <div className={cn('flex-form', styles.fields)}>
        <TextInput
          name='email'
          value={context.values.email}
          onChange={context.onChange}
          placeholder='Email'
          type='text'
        />
        <TextInput
          name='password'
          value={context.values.password}
          onChange={context.onChange}
          placeholder='Пароль'
          type='password'
        />
        <Checkbox
          name='rememberMe'
          inscription='Запомнить меня'
          checked={context.values.rememberMe}
          onCheck={context.onChange}
        />
        {props.serverError && (
          <span className={cn('text--alert', 'text--body-xs', 'text-400', 'input--error')}>{props.serverError}</span>
        )}
      </div>
      {props.children}
    </>
  )
}

export default CredentialsForm
