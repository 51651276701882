import { selector } from 'recoil'
import projectTechnologiesAtom from './projectTechnologies.atom'
import { IProjectTechnogy } from './projectTechnologies.types'

const projectTechnologiesMap = selector({
  key: 'selectors/project-technologies-full-map',
  get: ({ get }): Record<IProjectTechnogy['id'], IProjectTechnogy> => {
    const eventsState = get(projectTechnologiesAtom)

    return eventsState.items.reduce((acc, val) => ({ ...acc, [val.id]: val }), {})
  },
})

const projectTechnologiesSelectors = { projectTechnologiesMap }
export default projectTechnologiesSelectors
