import { atom } from 'recoil'
import { IEvent } from '../../events.types'

interface IEventsTableAtomState {
  items: IEvent[]
  filters: {
    search?: string
  }
}

const eventsTableAtom = atom<IEventsTableAtomState>({
  key: 'tables/admin/events',
  default: { items: [], filters: { search: '' } },
})
export default eventsTableAtom
