import { atom } from 'recoil'
import config from '../../config'
import eventBus from '../../services/eventBus'
import { ILoginResponse } from './auth.types'

export interface IAuthState {
  auth: {
    accessToken: string
    expires: number
  }
  user: ILoginResponse['user']
}

export const initialAuthState = {
  auth: {
    accessToken: !config.production ? localStorage.getItem('access') : '',
    expires: 0,
  },
  user: null,
}

const authAtom = atom<IAuthState>({
  key: 'auth',
  default: initialAuthState,
  effects: [
    ({ onSet }) => {
      onSet((newValue, oldValue: IAuthState) => {
        if (newValue.auth.accessToken !== oldValue.auth.accessToken) {
          eventBus.pub('access.changed', { token: newValue.auth.accessToken })
        }
      })
    },
  ],
})
export default authAtom
