import { IEvent } from './events.types'
import http, { handleHttpError, handleHttpResponse } from '../../../services/http'
import { HTTPResponse, IPaginationResponse } from 'services/http/http.types'

interface IGetParams {
  search?: string
}
const list = async (params: IGetParams): Promise<HTTPResponse<IPaginationResponse<IEvent>>> => {
  return http.get('/nsi/v1/events', { params }).then(handleHttpResponse).catch(handleHttpError)
}

const getOne = async (id: IEvent['id']): Promise<HTTPResponse<IEvent>> => {
  return http
    .get('/nsi/v1/events/' + id)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const update = async (dto: Partial<IEvent>, id: IEvent['id']): Promise<HTTPResponse<IEvent>> => {
  const normalized = { ...dto }
  if (normalized.startAt) normalized.startAt = new Date(dto.startAt).toISOString()
  if (normalized.endsAt) normalized.endsAt = new Date(dto.endsAt).toISOString()

  return http
    .patch('/nsi/v1/events/' + id, normalized)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const createEvent = async (dto: Omit<IEvent, 'id'>) => {
  const normalized = { ...dto }
  normalized.startAt = new Date(dto.startAt).toISOString()
  normalized.endsAt = new Date(dto.endsAt).toISOString()

  return http.post('/nsi/v1/events', normalized).then(handleHttpResponse).catch(handleHttpError)
}

const eventsApi = { list, getOne, update, createEvent }
export default eventsApi
