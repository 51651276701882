import loaderGif from '../../assets/loader.gif'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import noimage from '../../assets/icons/no-image.svg'
import css from './image.module.css'

interface IProps {
  src: string
  className?: string
  style?: CSSProperties
  alt: string

  wrapperStyle?: CSSProperties
  wrapperClassname?: string
  onClick?: () => void
  loading?: boolean
  objectFit?: CSSProperties['objectFit']
  defaultImageSrc?: string
}

const Image = (props: IProps) => {
  const ref = useRef<HTMLImageElement>()
  const [loading, setLoading] = useState(Boolean(props.src))
  const [error, setError] = useState(!Boolean(props.src))

  useEffect(() => {
    setError(!Boolean(props.src))
  }, [props.src])

  const handleError = () => {
    setError(true)
    setLoading(false)
  }

  const normalizedStyle = props.style || {}
  return (
    <div
      className={`${css.wrapper} ${props.wrapperClassname || ''}`}
      style={props.wrapperStyle}
      onClick={props.onClick}
    >
      {(props.loading || loading) && <div className={css.loader} style={{ background: `url(${loaderGif})` }} />}
      <img
        ref={ref}
        alt={error ? 'Изображение не найдено' : props.alt}
        style={{ objectPosition: 'center', objectFit: props.objectFit || 'contain', ...normalizedStyle }}
        src={error ? (props.defaultImageSrc || noimage) : props.src}
        className={props.className}
        key={error ? 1 : 0}
        onLoad={() => setLoading(false)}
        onError={handleError}
      />
    </div>
  )
}

export default Image
