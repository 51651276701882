import SearchInput from '../../../../../shared/Inputs/SearchInput'
import studentsTableAtom from './studentsTable.atom'
import { useRecoilState } from 'recoil'
import useItemsSetter from '../../../../../recoil/useItemsSetter'
import useTableWithRequest from '../../../../../shared/Table/useTableWithRequest'
import studentsTableFields from './studentsTable.fields'
import { useCallback } from 'react'
import useDebounce from '../../../../../shared/hooks/useDebounce'
import usersApi from '../../../../Users/users.api'
import Table from '../../../../../shared/Table'
import { IUser } from '../../../../Admin/RolesAndUsers/users.types'
import confirmServiceCtrl from '../../../../../services/ConfirmService/confirmService.ctrl'
import eventBus from '../../../../../services/eventBus'
import teamsApi from '../../../teams.api'
import { ITeam } from '../../../teams.types'

interface IProps {
  team: ITeam
  onClick?: (user: IUser) => void
}

const InviteStudentContent = (props: IProps) => {
  const [usersTableState, setUsersTableState] = useRecoilState(studentsTableAtom)
  const debouncedSearch = useDebounce(usersTableState.filters.search, 400)

  const handleChange = (value: any, name: string) => {
    setUsersTableState((prev) => ({ ...prev, filters: { ...prev.filters, [name]: value } }))
  }

  const fetcher = useCallback(
    (params: Record<string, any>) => {
      return usersApi.list({
        search: debouncedSearch,
        ...params,
      })
    },
    [debouncedSearch]
  )

  const itemsSetter = useItemsSetter(studentsTableAtom)

  const tableCtrl = useTableWithRequest({
    dataFetcher: fetcher,
    fields: studentsTableFields,
    itemKey: 'id',
    items: usersTableState.items,
    setItems: itemsSetter,
    tableKey: 'users',
    serverSideOrder: true,
  })

  const handleClick = useCallback((user: IUser) => {
    confirmServiceCtrl
      .requestConfirm({
        type: 'info',
        title: 'Подтвердите действие',
        description: `Пригласить студента ${user.info.name} в команду?`,
      })
      .then((r) => {
        if (r.response === 'confirm') {
          teamsApi.inviteIntoTeam(props.team.id, user.id)
          eventBus.pub('ntf.message', { type: 'info', message: 'Приглашение отправлено!' })
        }
      })
  }, [])

  return (
    <div className='flex-lines' style={{ height: '100%' }}>
      <div className='inline-flex-gap' style={{ gap: '8px', width: '100%' }}>
        <SearchInput
          value={usersTableState.filters.search}
          name='search'
          onChange={handleChange}
          required={false}
          placeholder='Поиск по ФИО или группе'
          autofocus={true}
        />
      </div>
      <Table
        {...tableCtrl}
        items={tableCtrl.orderedItems}
        handleSelect={tableCtrl.selectedCtrl.setSelected}
        selected={tableCtrl.selectedCtrl.selectedIndexes as Set<keyof IUser>}
        handleSelectAll={tableCtrl.selectedCtrl.setSelectAll}
        itemKey='id'
        onCLick={props.onClick || handleClick}
        selectEnabled={false}
      />
    </div>
  )
}

export default InviteStudentContent
