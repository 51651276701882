import Card from '../../../../../../../shared/Cards/Card'
import TextField from '../../../../../../../shared/Forms/TextField'
import { ITeam } from '../../../../../teams.types'
import timeUtils from '../../../../../../../utils/time.utils'

interface IProps {
  team: ITeam
}

const TeamInfoCard = (props: IProps) => {
  return (
    <Card type='normal'>
      <div className='flex-lines'>
        <div style={{ marginBottom: '16px' }}>
          <h2 className='text--caption-l text-700'>О команде</h2>
        </div>
        <TextField header='Название'>{props.team.name}</TextField>
        <div className='grid-form-2'>
          <TextField header='Создана'>{timeUtils.formatDate(new Date(props.team.createdAt), 'dd MMM yyyy')}</TextField>
          <TextField header='Обновлена'>
            {timeUtils.formatDate(new Date(props.team.updatedAt), 'dd MMM yyyy')}
          </TextField>
        </div>
      </div>
    </Card>
  )
}

export default TeamInfoCard
