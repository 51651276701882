import { IFullTeam, ITeam } from '../../../../../teams.types'
import SkeletonCard from '../../../../../../../shared/Cards/SkeletonCard'
import useHttpLoader from '../../../../../../../shared/hooks/useHttpLoader'
import { useEffect, useState } from 'react'
import teamsApi from '../../../../../teams.api'
import { ReactComponent as LeadIcon } from '../../../../../../../assets/icons/cup.svg'
import { ReactComponent as MemberIcon } from '../../../../../../../assets/icons/user-solid.svg'
import { useNavigate } from 'react-router'
import { IUser } from 'screens/Admin/RolesAndUsers/users.types'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import authAtom from 'screens/Auth/auth.atom'
import EditButton from 'shared/Buttons/EditButton'
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-trash.svg'
import Button from 'shared/Buttons/Button/Button'
import SelectUserModal from '../../SelectUserModal'
import eventBus from 'services/eventBus'
import teamsTableAtom from 'screens/Teams/components/TeamsTable/teamsTable.atom'
import recoilStateUpdaters from 'recoil/recoil.stateUpdaters'

interface IProps {
  team: ITeam
}

const TeamMembersCard = (props: IProps) => {
  const [selectUserModalOpen, setSelectUserModalOpen] = useState(false)

  const navigate = useNavigate()
  const selfId = useRecoilValue(authAtom).user.id
  const isAdmin = useRecoilValue(authAtom).user.isAdmin
  const setTeamsState = useSetRecoilState(teamsTableAtom)

  const { wait: waitSave, loading: saveLoading } = useHttpLoader()
  const { wait, loading } = useHttpLoader()
  const [team, setTeam] = useState<IFullTeam>(null)
  const [editing, setEditing] = useState(false)
  const [draftMembers, setDraftMembers] = useState(props.team.members)

  useEffect(() => {
    wait(teamsApi.getFullTeam(props.team.id), (resp) => {
      if (resp.status === 'success') {
        setTeam(resp.body)
      }
    })
  }, [props.team.id])

  const handleTeamMemberClick = (userId: IUser['id']) => () => {
    if (selfId === userId) {
      navigate('/profile')
    } else {
      navigate('/users/' + userId)
    }
  }

  const handleMemeberRemoved = (index: number) => {
    setDraftMembers((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
  }

  const handleAddMemeber = (user: IUser) => {
    setSelectUserModalOpen(false)
    setDraftMembers((prev) => [...prev, { userId: user.id, user }])
  }

  const handleSave = () => {
    waitSave(
      teamsApi.setTeamMembers(
        props.team.id,
        draftMembers.map((m) => m.userId)
      ),
      (resp) => {
        if (resp.status === 'success') {
          recoilStateUpdaters.itemUpdated(setTeamsState, { id: props.team.id, members: draftMembers })
          setEditing(false)
          eventBus.pub('ntf.message', { type: 'info', message: 'Состав команды обновлен' })
        }
      }
    )
  }

  const renderContent = () => {
    if (!team) return null

    return (
      <div className='flex-lines'>
        {selectUserModalOpen && (
          <SelectUserModal onClose={() => setSelectUserModalOpen(false)} onSelect={handleAddMemeber} />
        )}
        <div style={{ marginBottom: '16px' }}>
          <div className='flex-space-between'>
            <h2 className='text--caption-l text-700'>
              Состав команды ({draftMembers.length + 1} / {props.team.maxCapacity})
            </h2>
            {isAdmin && !editing && <EditButton onClick={() => setEditing(true)} />}
          </div>
        </div>
        <div className='flex-lines'>
          <div className='inline-flex-gap'>
            <LeadIcon style={{ height: '24px', width: '24px', color: 'var(--primary)' }} />
            <span
              className='text--caption-m text--link-underline'
              style={{ cursor: 'pointer' }}
              onClick={handleTeamMemberClick(team.ownerId)}
            >
              {team.owner?.info?.name}
            </span>
          </div>
          {draftMembers.map((member, index) => (
            <div className='inline-flex-gap'>
              <MemberIcon style={{ height: '24px', width: '24px', color: 'var(--tertiary)' }} />
              <span
                className='text--caption-m text--link-underline'
                style={{ cursor: 'pointer' }}
                onClick={handleTeamMemberClick(member.userId)}
              >
                {member.user.info.name}
              </span>
              {editing && (
                <DeleteIcon
                  style={{ width: '20px', height: '20px', marginLeft: '5px', cursor: 'pointer' }}
                  onClick={() => handleMemeberRemoved(index)}
                />
              )}
            </div>
          ))}
          {editing && (
            <div className='form-footer'>
              <div className='inline-flex-gap'>
                <Button theme='primary' style={{ width: 'fit-content' }} onClick={() => setSelectUserModalOpen(true)}>
                  Добавить студента
                </Button>
                <Button theme='primary' style={{ width: 'fit-content' }} onClick={handleSave} loading={saveLoading}>
                  Сохранить
                </Button>
                <Button
                  theme='grey-outline'
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    setDraftMembers(props.team.members)
                    setEditing(false)
                  }}
                >
                  Отмена
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <SkeletonCard type='normal' height='130px' width='100%' loading={loading}>
      {renderContent()}
    </SkeletonCard>
  )
}

export default TeamMembersCard
