import { useRecoilValue } from 'recoil'
import { IProject } from '../projects.types'
import authAtom from 'screens/Auth/auth.atom'
import selfTeamsAtom from 'screens/Auth/components/InitProvider/selfTeams.atom'
import selfProjectsAtom from 'screens/Auth/components/InitProvider/selfProjects.atom'
import eventsSelectors from 'screens/Admin/Events/events.selectors'

const useIsProjectEditable = (id: IProject['id']) => {
  const eventsMap = useRecoilValue(eventsSelectors.eventsFullMap)
  const user = useRecoilValue(authAtom).user
  const selfTeams = useRecoilValue(selfTeamsAtom)
  const selfProjects = useRecoilValue(selfProjectsAtom)

  if (user.isAdmin) return true

  const project = selfProjects.items.find((p) => p.id === id)
  if (!project || project.organizationId) return false

  const team = selfTeams.items.find((t) => project.projectToTeams.some((pt) => pt.teamId === t.id))
  if (!team || team.ownerId !== user.id) return false

  return !eventsMap[project.eventId].locked
}

export default useIsProjectEditable
