import React, { CSSProperties } from 'react'
import ReactDOM from 'react-dom'
import './modal.styles.css'

class Modal extends React.Component<Props> {
  modal = React.createRef<HTMLDivElement>()

  componentDidMount() {
    document.addEventListener('click', this.handleClick)
    document.addEventListener('keyup', this.handleKeyUp)
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
    document.removeEventListener('keyup', this.handleKeyUp)
    document.body.style.overflow = 'auto'
  }

  // close modal on click outside
  private handleClick = (e) => {
    if (e.target.className.includes?.('app-modal__shadow') && this.props.closeOnClickOutside !== false) {
      this.props.onClose('click-outside')
    }
  }

  // close modal on Esc key
  private handleKeyUp = (e) => {
    if (e.keyCode === 27 && this.props.closeOnEscape) {
      this.props.onClose('esc')
    }
  }

  render() {
    const size = this.props.size || 'md'

    let modalClass = 'app-modal is-' + size
    if (this.props.isTall) modalClass += ' is-tall'

    let shadowClass = 'app-modal__shadow'
    if (this.props.shouldAnimate !== false) shadowClass += ' app-modal-animation'

    const body = document.getElementsByTagName('body')[0]

    // в случае если родительский контейнер компонента имеет css трансформацию, то она
    // будет нарушать позиционирование модального окна, поэтому выносим его из текущего
    // положения в дереве в <body> при помощи portals
    return ReactDOM.createPortal(
      <div className={shadowClass}>
        <div ref={this.modal} className={modalClass} style={this.props.style}>
          {this.props.children}
        </div>
      </div>,
      body
    )
  }
}

interface Props {
  size?: 'sm' | 'md' | 'lg' | 'hg' | 'xs'
  isTall?: boolean
  onClose: (reason: 'click-outside' | 'esc') => void
  closeOnClickOutside?: boolean // default true
  closeOnEscape?: boolean // default true
  shouldAnimate?: boolean
  style?: CSSProperties
  children: any
}

export default Modal
