import { atom } from 'recoil'
import { IProjectTechnogy } from './projectTechnologies.types'

interface IProjectTechnogiesAtom {
  items: IProjectTechnogy[]
}

const projectTechnologiesAtom = atom<IProjectTechnogiesAtom>({
  key: 'projectTechnogies',
  default: { items: [] },
})
export default projectTechnologiesAtom
