import { useState } from 'react'
import AdminNavigationLayout from 'shared/Layouts/TopNavigationLayout/AdminNavifationLayout'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import Button from 'shared/Buttons/Button/Button'
import useHttpLoader from 'shared/hooks/useHttpLoader'
import { useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import EventSheet from '../../components/EventSheet'
import eventsTableAtom from '../../components/EventsTable/eventsTable.atom'
import eventsApi from '../../events.api'

const CreateEventPage = () => {
  const navigate = useNavigate()
  const { wait, loading } = useHttpLoader()
  const [draft, setDraft] = useState({ name: '', startAt: '', endsAt: '', locked: false })
  const setState = useSetRecoilState(eventsTableAtom)

  const handleChange = (val: any, name: string) => {
    setDraft((prev) => ({ ...prev, [name]: val }))
  }

  const handleSave = () => {
    wait(eventsApi.createEvent(draft), (resp) => {
      if (resp.status === 'success') {
        setState((prev) => ({ ...prev, items: [resp.body, ...prev.items] }))
        navigate('/admin/events')
      }
    })
  }

  return (
    <AdminNavigationLayout>
      <TopNavigationLayoutPageContent type='default'>
        <div className='flex-space-between'>
          <div className='inline-flex-gap' style={{ gap: '2.5em' }}>
            <h2 className='page--header'>Создание события</h2>
          </div>
        </div>
        <EventSheet event={draft} onChange={handleChange}>
          <Button theme='primary' loading={loading} onClick={handleSave} style={{ width: 'fit-content' }}>
            Создать
          </Button>
        </EventSheet>
      </TopNavigationLayoutPageContent>
    </AdminNavigationLayout>
  )
}

export default CreateEventPage
