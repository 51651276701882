import Card from 'shared/Cards/Card'

const UserProjectsCard = () => {
  return (
    <Card type='normal'>
      <div className='flex-lines'>
        <div style={{ marginBottom: '16px' }}>
          <h2 className='text--caption-l text-700'>Архив проектов</h2>
        </div>
        <div className='flex-lines text--body-m' style={{ gap: '6px' }}>
          <p>Тут пока ничего нет 😞</p>
          <p>Все будет, главное не сдаваться! 🚀</p>
        </div>
      </div>
    </Card>
  )
}

export default UserProjectsCard
