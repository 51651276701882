import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import OrganizationSelect from 'screens/Admin/Organizations/componenets/OrganizationSelect'
import organizationsSelectors from 'screens/Admin/Organizations/organizations.selectors'
import projectTechnologiesSelectors from 'screens/Admin/ProjectTechnologies/projectTechnologies.selectors'
import UserSelect from 'screens/Admin/Users/components/UserSelect'
import authAtom from 'screens/Auth/auth.atom'
import useIsProjectEditable from 'screens/Projects/hooks/useIsProjectEditable'
import useProjectsActions from 'screens/Projects/hooks/useProjectsActions'
import { IProject } from 'screens/Projects/projects.types'
import { IUser } from 'screens/Admin/RolesAndUsers/users.types'
import Button from 'shared/Buttons/Button/Button'
import EditButton from 'shared/Buttons/EditButton'
import Card from 'shared/Cards/Card'
import TextField from 'shared/Forms/TextField'
import TextInput from 'shared/Inputs/TextInput'
import timeUtils from 'utils/time.utils'
import RrojectEducationYearDropdown from '../../ProjectEducationYearDropdown'
import Toggle from 'shared/Inputs/Toggle'
import { useNavigate } from 'react-router-dom'

interface IProps {
  project: Pick<
    IProject,
    | 'id'
    | 'keyTechnologies'
    | 'mentor'
    | 'repositoryLink'
    | 'taskTrackingLink'
    | 'organizationId'
    | 'createdAt'
    | 'updatedAt'
    | 'teamsTargetCount'
    | 'maxEducationYear'
    | 'minEducationYear'
    | 'adminOnly'
  >
}

const ProjectInfoCard = (props: IProps) => {
  const navigate = useNavigate()
  const organiaztionsMap = useRecoilValue(organizationsSelectors.organiaztionsMap)
  const projectsActions = useProjectsActions()
  const keyTechnologiesMap = useRecoilValue(projectTechnologiesSelectors.projectTechnologiesMap)

  const isEditable = useIsProjectEditable(props.project.id)
  const [editing, setEditing] = useState(false)
  const [draft, setDraft] = useState(props.project)
  const [selectedUser, setSelectedUser] = useState<IUser>(null)
  const isAdmin = useRecoilValue(authAtom).user.isAdmin

  const handleChange = (val: any, name: string) => {
    setDraft((prev) => ({ ...prev, [name]: val }))
  }

  const handleSave = () => {
    const payload: any = {}
    if (selectedUser?.id || (props.project.mentor?.id && isAdmin)) {
      payload.mentorId = selectedUser?.id || props.project.mentor?.id
    }
    if (draft.organizationId && isAdmin) {
      payload.organizationId = draft.organizationId
    }
    payload.repositoryLink = draft.repositoryLink
    payload.taskTrackingLink = draft.taskTrackingLink
    if (isAdmin) payload.adminOnly = draft.adminOnly

    projectsActions.update(props.project.id, payload).then((resp) => {
      if (resp.status === 'success') {
        setEditing(false)
      }
    })
  }

  const renderContent = () => {
    if (editing) {
      return (
        <div className='flex-lines'>
          <div className='flex-space-between' style={{ marginBottom: '16px', width: '100%' }}>
            <h2 className='text--caption-l text-700'>Общая информация</h2>
          </div>
          {isAdmin && (
            <TextField header='Доступен для записи только администраторами'>
              <Toggle checked={draft.adminOnly} name='adminOnly' onChange={handleChange} />
            </TextField>
          )}
          <div className='grid-form-2'>
            {isAdmin && (
              <TextField header='Организация'>
                <OrganizationSelect value={draft.organizationId} onChange={handleChange} name='organizationId' />
              </TextField>
            )}
            {isAdmin && (
              <TextField header='Куратор'>
                <UserSelect userId={draft.mentor?.id} onSelect={setSelectedUser} selectedUser={selectedUser} />
              </TextField>
            )}
            {isAdmin && (
              <TextField header='Минимальный курс обучения'>
                <RrojectEducationYearDropdown
                  value={draft.minEducationYear}
                  name='minEducationYear'
                  onChange={handleChange}
                />
              </TextField>
            )}
            {isAdmin && (
              <TextField header='Максимальный курс обучения'>
                <RrojectEducationYearDropdown
                  value={draft.maxEducationYear}
                  name='maxEducationYear'
                  onChange={handleChange}
                />
              </TextField>
            )}
            <TextField header='Ссылка на репозиторий'>
              <TextInput value={draft.repositoryLink} onChange={handleChange} name='repositoryLink' />
            </TextField>
            <TextField header='Ссылка на трекинг'>
              <TextInput value={draft.taskTrackingLink} onChange={handleChange} name='taskTrackingLink' />
            </TextField>
          </div>
          <div className='form-footer'>
            <div className='inline-flex-gap'>
              <Button
                theme='primary'
                style={{ width: 'fit-content' }}
                onClick={handleSave}
                loading={projectsActions.loading}
              >
                Сохранить
              </Button>
              <Button
                theme='grey-outline'
                style={{ width: 'fit-content' }}
                onClick={() => {
                  setDraft(props.project)
                  setEditing(false)
                }}
                disabled={projectsActions.loading}
              >
                Отмена
              </Button>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='flex-lines'>
        <div className='flex-space-between' style={{ marginBottom: '16px', width: '100%' }}>
          <h2 className='text--caption-l text-700'>Общая информация</h2>
          {!editing && isEditable && <EditButton onClick={() => setEditing(true)} />}
        </div>
        <TextField header='Доступен для записи'>{draft.adminOnly ? 'Только через администраторов' : 'Всем'}</TextField>
        <div className='grid-form-2'>
          <TextField header='Организация'>{organiaztionsMap[props.project.organizationId]?.name || '-'}</TextField>
          <TextField header='Ключевые технологии'>
            {props.project.keyTechnologies.map((t) => keyTechnologiesMap[t.technologyId].title).join(', ') || '-'}
          </TextField>
          <TextField header='Куратор'>
            {props.project?.mentor?.id ? (
              <span
                className='text--link text--link-underline'
                onClick={() => navigate('/users/' + props.project.mentor.id, { state: { mentor: true } })}
              >
                {props.project.mentor?.info?.name}
              </span>
            ) : (
              '-'
            )}
          </TextField>
          <TextField header='Ожидаемое кол-во команд'>{props.project.teamsTargetCount || '-'}</TextField>
          <TextField header='Минимальный курс обучения'>{props.project.minEducationYear}</TextField>
          <TextField header='Максимальный курс обучения'>{props.project.maxEducationYear}</TextField>

          <TextField header='Ссылка на репозиторий'>
            <a className='text--link-underline' href={props.project.repositoryLink} target='_blank'>
              {props.project.repositoryLink || '-'}
            </a>
          </TextField>
          <TextField header='Ссылка на трекинг'>
            <a className='text--link-underline' href={props.project.taskTrackingLink} target='_blank'>
              {props.project.taskTrackingLink || '-'}
            </a>
          </TextField>
          <TextField header='Создан'>
            {timeUtils.formatDate(new Date(props.project.createdAt), 'dd MMM yyyy')}
          </TextField>
          <TextField header='Обновлен'>
            {timeUtils.formatDate(new Date(props.project.updatedAt), 'dd MMM yyyy')}
          </TextField>
        </div>
      </div>
    )
  }

  return <Card type='normal'>{renderContent()}</Card>
}

export default ProjectInfoCard
