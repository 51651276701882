import usePopup from '../../hooks/usePopup'
import { useRef } from 'react'
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrowRight.svg'
import css from './panelDropdown.module.css'
import cn from '../../../utils/cn'
import SmoothPanel from '../../Animated/SmoothPanel'

export interface IDropdownOption<T = any> {
  value: T
  title: string
}

interface IProps<T = any> {
  selectedTitle?: string
  selectedOption: T
  options: IDropdownOption<T>[]
  onSelect: (selected: T) => void
}

const PanelDropdown = (props: IProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { open, setOpen } = usePopup(ref)

  const selectedOptionTitle = props.options.find((o) => o.value === props.selectedOption)?.title || '-'

  const renderPanelContent = () => {
    return (
      <div className={css.panelContent}>
        {props.options.map((option) => (
          <div
            className={css.option}
            key={option.value}
            onClick={() => {
              setOpen(false)
              props.onSelect(option.value)
            }}
          >
            {option.title}
          </div>
        ))}
      </div>
    )
  }

  const dropdown = (
    <div
      data-open={open}
      className={cn(css.value, 'inline-flex-gap')}
      onClick={() => setOpen(!open)}
      style={{ cursor: 'pointer' }}
    >
      <span>{props.selectedTitle ?? selectedOptionTitle}</span>
      <ArrowRight data-open={open} className={css.arrow} />
    </div>
  )

  return (
    <SmoothPanel open={open} icon={dropdown} transformOrigin='left bottom' ref={ref}>
      {renderPanelContent()}
    </SmoothPanel>
  )
}

export default PanelDropdown
