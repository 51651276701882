import { EntityRecoilState, EntityWithId } from './useEntity/useEntity.types'
import { Dispatch, SetStateAction } from 'react'

const itemUpdated = <T extends EntityWithId, U extends EntityRecoilState<T>>(
  setter: Dispatch<SetStateAction<U>>,
  item: EntityWithId
) => {
  setter((prev) => {
    const index = prev.items.findIndex((it) => it.id === item.id)
    if (index === -1) return prev

    return {
      ...prev,
      items: [...prev.items.slice(0, index), { ...prev.items[index], ...item }, ...prev.items.slice(index + 1)],
    }
  })
}

const itemCreated = <T extends EntityWithId, U extends EntityRecoilState<T>>(
  setter: Dispatch<SetStateAction<U>>,
  item: EntityWithId,
  shift = 100000
) => {
  setter((prev) => {
    if (!prev.items.some((it) => it.id === item.id)) {
      return { ...prev, items: [item, ...prev.items].slice(0, shift) }
    }

    return prev
  })
}

const itemRemoved = <T extends EntityWithId, U extends EntityRecoilState<T>>(
  setter: Dispatch<SetStateAction<U>>,
  id: EntityWithId['id']
) => {
  setter((prev) => {
    const index = prev.items.findIndex((it) => it.id === id)
    if (index === -1) return prev

    return { ...prev, items: [...prev.items.slice(0, index), ...prev.items.slice(index + 1)] }
  })
}

const recoilStateUpdaters = { itemUpdated, itemCreated, itemRemoved }
export default recoilStateUpdaters
