import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IUser } from 'screens/Admin/RolesAndUsers/users.types'
import { IProject } from 'screens/Projects/projects.types'
import { ITeam } from 'screens/Teams/teams.types'
import usersApi from 'screens/Users/users.api'
import SkeletonCard from 'shared/Cards/SkeletonCard'
import useHttpLoader from 'shared/hooks/useHttpLoader'

interface IProps {
  userId: IUser['id']
}

const UserTeamsAndProjectsCard = (props: IProps) => {
  const navigate = useNavigate()
  const { wait, loading } = useHttpLoader()
  const [state, setState] = useState<{ teams: ITeam[]; projects: IProject[] }>(null)

  useEffect(() => {
    wait(usersApi.getUserTeamsAndProjects(props.userId), (resp) => {
      if (resp.status === 'success') {
        setState(resp.body)
      }
    })
  }, [props.userId])

  const renderContent = () => {
    if (!state) return null

    if (!state.teams.length && !state.projects.length) {
      return (
        <>
          <h2 className='text--caption-l' style={{ marginBottom: '16px' }}>
            Проекты и команды
          </h2>
          <div
            className='flex-page-center'
            style={{ height: '150px', alignItems: 'center', justifyContent: 'center', padding: 0 }}
          >
            <p className='text--body-m text--blue'>У пользователя пока нет ни проектов, ни команд :(</p>
          </div>
        </>
      )
    }

    return (
      <>
        <h2 className='text--caption-l' style={{ marginBottom: '32px' }}>
          Проекты и команды
        </h2>
        <div className='grid-form-2'>
          <div className='flex-lines'>
            <h2 className='text--caption-m'>Команды ({state.teams.length})</h2>
            {state.teams.map((t) => (
              <span
                style={{ width: 'fit-content' }}
                key={t.id}
                className='text--body-m text--link text--link-underline'
                onClick={() => navigate('/teams/' + t.id)}
              >
                {t.name}
              </span>
            ))}
          </div>
          <div className='flex-lines'>
            <h2 className='text--caption-m'>Проекты ({state.projects.length})</h2>
            {state.projects.map((t) => (
              <p key={t.id} className='line_clamp' title={t.name}>
                <span
                  style={{ width: 'fit-content' }}
                  className='text--body-m text--link text--link-underline'
                  onClick={() => navigate('/projects/' + t.id)}
                >
                  {t.name}
                </span>
              </p>
            ))}
          </div>
        </div>
      </>
    )
  }

  return (
    <SkeletonCard type='normal' loading={loading} width='100%' height='300px'>
      {renderContent()}
    </SkeletonCard>
  )
}

export default UserTeamsAndProjectsCard
