import { useState, useEffect } from 'react'
import eventBus from '../eventBus/index'
import { IConfirmRequestParams } from './confirmService.ctrl'

const useConfirmServiceCtrl = () => {
  const [params, setParams] = useState<IConfirmRequestParams>(null)
  const [active, setActive] = useState(false)

  useEffect(() => {
    const unsubscribe = eventBus.sub('confirmService.request', (p) => {
      setActive(true)
      setParams(p)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return { params, active, setActive }
}

export default useConfirmServiceCtrl
