import Dropdown from 'shared/Inputs/Dropdown'
import { IOption } from 'types/global'

interface IProps {
  value: number
  name: string
  onChange: (value: number, name: string) => void
}

const RrojectEducationYearDropdown = (props: IProps) => {
  return (
    <Dropdown
      onSelect={props.onChange}
      name={props.name}
      options={options}
      selectedOption={props.value}
      title='Год обучения'
      allowEmpty={false}
    />
  )
}

const options: Array<IOption<1 | 2 | 3 | 4 | 5>> = [
  { value: 1, title: '1' },
  { value: 2, title: '2' },
  { value: 3, title: '3' },
  { value: 4, title: '4' },
  { value: 5, title: '5' },
]

export default RrojectEducationYearDropdown
