import React, { CSSProperties } from 'react'

/**
 * Компонент для загрузки текстовых файлов
 * Рендерит скрытый инпут и кнопку для выбора файла
 */
class FileInput extends React.Component<Props> {
  private ref = React.createRef<HTMLInputElement>()

  componentDidMount() {
    if (this.props.autofocus) this.handleClick()
  }

  private handleClick = () => {
    !this.props.disabled && this.ref.current.click()
  }

  private handleChange = (e) => {
    const reader = new FileReader()
    reader.readAsText(e.target.files[0])

    reader.onload = () => {
      this.props.onChange(reader.result as string, e.target.files[0])
    }
  }

  render() {
    return (
      <React.Fragment>
        <button
          style={{ width: 'fit-content' }}
          className="etba-button"
          onClick={this.props.onClick ?? this.handleClick}
          data-theme={this.props.buttonClass || 'primary'}
        >
          <span>{this.props.children}</span>
        </button>
        <input
          style={{ display: 'none' }}
          ref={this.ref}
          className="etba-input"
          type="file"
          accept={this.props.accept}
          onChange={this.handleChange}
        />
      </React.Fragment>
    )
  }
}

interface Props {
  accept: string // какие расширения файлов принимать
  onChange: (content: string, file: File) => void
  children: React.ReactNode | React.ReactNodeArray
  buttonClass?: 'primary' | 'grey-outline' | 'secondary'
  style?: CSSProperties
  autofocus?: boolean
  disabled?: boolean
  onClick?: () => void
}

export default FileInput
