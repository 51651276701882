import { useRecoilState, useSetRecoilState } from 'recoil'
import selfTeamsAtom from './selfTeams.atom'
import { PropsWithChildren, useEffect } from 'react'
import ThreeDotsLoader from '../../../../shared/Loaders/ThreeDotsLoader'
import authApi from '../../auth.api'
import selfInvitesAtom from './selfInvites.atom'
import userSkillsAtom from '../../../Admin/UserSkills/userSkills.atom'
import eventsAtom from '../../../Admin/Events/events.atom'
import selfProjectsAtom from './selfProjects.atom'
import organizationsAtom from 'screens/Admin/Organizations/organizations.atom'
import projectTechnologiesAtom from 'screens/Admin/ProjectTechnologies/projectTechnologies.atom'

const InitProvider = (props: PropsWithChildren) => {
  const [teamsState, setTeamsState] = useRecoilState(selfTeamsAtom)
  const setTeamInvitesState = useSetRecoilState(selfInvitesAtom)
  const setSkillsState = useSetRecoilState(userSkillsAtom)
  const setEventsState = useSetRecoilState(eventsAtom)
  const setSelfProjectsState = useSetRecoilState(selfProjectsAtom)
  const setOrganizationsState = useSetRecoilState(organizationsAtom)
  const setProjectTechnologiesState = useSetRecoilState(projectTechnologiesAtom)

  useEffect(() => {
    if (teamsState.loaded) return

    authApi.init().then((r) => {
      if (r.status === 'success') {
        setTeamsState((prev) => ({ ...prev, loaded: true, items: r.body.userTeams }))
        setTeamInvitesState((prev) => ({ ...prev, loaded: true, items: r.body.teamInvites }))
        setSkillsState((prev) => ({ ...prev, items: r.body.skills }))
        setEventsState((prev) => ({ ...prev, items: r.body.events }))
        setSelfProjectsState((prev) => ({ ...prev, items: r.body.userProjects }))
        setOrganizationsState((prev) => ({ ...prev, items: r.body.organizations }))
        setProjectTechnologiesState((prev) => ({ ...prev, items: r.body.keyProjectsTechnologies }))
      }
    })
  }, [teamsState.loaded])

  if (!teamsState.loaded) {
    return (
      <div className='flex-page-center' style={{ height: '80vh', justifyContent: 'center', paddingTop: 0 }}>
        <div className='flex-lines'>
          <ThreeDotsLoader />
          <p className='text--caption-m' style={{ textAlign: 'center' }}>
            Загружаем информацию о пользователе...
          </p>
        </div>
      </div>
    )
  }

  return <>{props.children}</>
}

export default InitProvider
