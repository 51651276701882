import { RefObject, useEffect } from 'react'

/**
 * Переместить элемент в видимую область, если он находится за ее границами из-за скролла на родительском элементе
 */
const useScrollIntoView = (
  shouldScroll: boolean,
  elementRef: RefObject<HTMLElement>,
  parentSelector: string,
  block: ScrollLogicalPosition = 'center'
) => {
  useEffect(() => {
    if (!shouldScroll) return

    const element = elementRef.current
    const parent: HTMLDivElement = document.querySelector(parentSelector)
    if (!element || !parent) return

    const rect = element.getBoundingClientRect()
    const parentRect = parent.getBoundingClientRect()

    if (rect.top + rect.height < parentRect.top || rect.top > parentRect.top + parentRect.height) {
      element.scrollIntoView({ block, inline: 'start' })
    }
  }, [shouldScroll])
}

export default useScrollIntoView
