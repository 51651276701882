import { IUser } from 'screens/Admin/RolesAndUsers/users.types'
import http, { handleHttpError, handleHttpResponse } from 'services/http'
import { HTTPResponse } from 'services/http/http.types'

const getUser = async (id: IUser['id']): Promise<HTTPResponse<IUser>> => {
  return http
    .get('/nsi/v1/users/' + id)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

export interface ICreateUserDTO {
  email: string
  name: string
  universityGroup: string
  password: string
  educationYear: number
}
const createUser = async (dto: ICreateUserDTO): Promise<HTTPResponse<IUser>> => {
  return http.post('/nsi/v1/users', dto).then(handleHttpResponse).catch(handleHttpError)
}

const usersApi = { getUser, createUser }
export default usersApi
