import { selector } from 'recoil'
import userSkillsAtom from './userSkills.atom'

const skillsMap = selector({
  key: 'selectors/skills-map',
  get: ({ get }) => {
    const skillsState = get(userSkillsAtom)

    return skillsState.items.reduce((acc, val) => ({ ...acc, [val.id]: val.name }), {})
  },
})

const userSkillsSelectors = { skillsMap }
export default userSkillsSelectors
