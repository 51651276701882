import { memo } from 'react'
import { ITableField } from '../index'
import { ReactComponent as TriangleDown } from '../../../assets/icons/triangleDown.svg'
import useDnD from '../../hooks/useDnD'
import useFlip from '../../hooks/useFlip'
import { ResizeColumnPluginCtrl } from '../plugins/resize/useResizeColumnPluginCtrl'
import useResizeColumnPluginTableThCtrl from '../plugins/resize/useResizeColumnPluginTableThCtrl'

interface IProps<T extends Record<string, any>, K extends keyof T> {
  column: ITableField<T, K>
  onSwap: (target: K, dropped: K) => void
  onOrderingStateTrigger: (key: K) => void
  orderingState: number
  index: number
  resizePluginCtrl: ResizeColumnPluginCtrl
}

const TableTh = <T extends Record<string, any>, K extends keyof T>(props: IProps<T, K>) => {
  const flipCtrl = useFlip([props.index])
  const resizeCtrl = useResizeColumnPluginTableThCtrl(props.resizePluginCtrl, props.column.key as string)

  const dnd = useDnD({
    ref: flipCtrl.element,
    id: props.column.key as any,
    prefix: 'tableTh:',
    style: { background: 'var(--active)', color: 'var(--primary)' },
    onDrop: (k) => props.onSwap(props.column.key, k as any),
  })

  return (
    <th
      ref={flipCtrl.element as any}
      key={props.column.key as string}
      onClick={() => !resizeCtrl.isResizing && props.onOrderingStateTrigger(props.column.key)}
      {...dnd}
      draggable={true}
      style={props.column.width ? { width: props.column.width } : undefined}
    >
      <div className='flex-space-between' style={{ position: 'relative' }}>
        {props.column.header}
        <TriangleDown className='tableThArrow' data-sort={props.orderingState} />
        <div ref={resizeCtrl.resizeElementRef} className='tableThResize' />
      </div>
    </th>
  )
}

export default memo(TableTh)
