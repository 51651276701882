import Card from '../../../../shared/Cards/Card'
import TextField from '../../../../shared/Forms/TextField'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import authAtom from '../../../Auth/auth.atom'
import EditButton from '../../../../shared/Buttons/EditButton'
import { useState } from 'react'
import TextInput from '../../../../shared/Inputs/TextInput'
import Button from '../../../../shared/Buttons/Button/Button'
import useHttpLoader from '../../../../shared/hooks/useHttpLoader'
import selfApi from '../../self.api'

const GeneralInfoCard = () => {
  const setAuthState = useSetRecoilState(authAtom)
  const selfInfo = useRecoilValue(authAtom).user
  const { wait, loading } = useHttpLoader()

  const [mode, setMode] = useState<'view' | 'edit'>('view')
  const [draftInfo, setDraftInfo] = useState({
    name: selfInfo.info.name,
    universityGroup: selfInfo.info.universityGroup,
    phone: selfInfo.info.phone || undefined,
    telegram: selfInfo.info.telegram || undefined,
  })

  const handleChange = (val: any, name: string) => {
    setDraftInfo((prev) => ({ ...prev, [name]: val }))
  }

  const handleSave = () => {
    wait(selfApi.updateSelfInfo(draftInfo), (resp) => {
      if (resp.status === 'success') {
        setAuthState((prev) => ({ ...prev, user: { ...prev.user, info: { ...prev.user.info, ...resp.body } } }))
        setMode('view')
      }
    })
  }

  const renderField = (name: string, title: string) => {
    if (mode === 'view') {
      return <TextField header={title}>{selfInfo.info[name] || '-'}</TextField>
    }

    return (
      <TextField header={title}>
        <TextInput value={draftInfo[name]} name={name} onChange={handleChange} required={false} />
      </TextField>
    )
  }

  const renderFooter = () => {
    if (mode === 'view') return null

    return (
      <div className='form-footer'>
        <div className='inline-flex-gap'>
          <Button theme='primary' style={{ width: 'fit-content' }} loading={loading} onClick={handleSave}>
            Сохранить
          </Button>
          <Button
            theme='grey-outline'
            style={{ width: 'fit-content' }}
            onClick={() => setMode('view')}
            disabled={loading}
          >
            Отмена
          </Button>
        </div>
      </div>
    )
  }

  return (
    <Card type='normal' style={{ height: mode === 'edit' ? '350px' : '225px' }}>
      <div className='flex-lines'>
        <div className='flex-space-between' style={{ marginBottom: '16px' }}>
          <h2 className='text--caption-l text-700'>Общая информация</h2>
          {mode !== 'edit' && <EditButton onClick={() => setMode('edit')} />}
        </div>
        <div className='grid-form-2'>
          {renderField('name', 'ФИО')}
          {renderField('universityGroup', 'Универститетская группа')}
          {renderField('phone', 'Номер телефона')}
          {renderField('telegram', 'Telegram')}
        </div>
        {renderFooter()}
      </div>
    </Card>
  )
}

export default GeneralInfoCard
