import useIsJoinProjectPossible from 'screens/Projects/hooks/useIsJoinProjectPossible'
import { IProject } from 'screens/Projects/projects.types'
import Button from 'shared/Buttons/Button/Button'
import Card from 'shared/Cards/Card'
import css from './projectTeamsCard.module.css'
import useHttpLoader from 'shared/hooks/useHttpLoader'
import projectsApi from 'screens/Projects/projects.api'
import useSelfActiveTeam from 'screens/Teams/hooks/useSelfActiveTeam'
import { useRecoilValue } from 'recoil'
import authAtom from 'screens/Auth/auth.atom'
import EditButton from 'shared/Buttons/EditButton'
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-trash.svg'
import { useState } from 'react'
import SelectTeamModal from 'screens/Teams/components/SelectTeamModal'
import { ITeam } from 'screens/Teams/teams.types'
import { useNavigate } from 'react-router-dom'

interface IProps {
  project: Pick<IProject, 'id' | 'teamsTargetCount' | 'projectToTeams' | 'adminOnly'>
  onJoined: (project: IProject) => void
  onUpdated: (project: IProject) => void
}

const ProjectTeamsCard = (props: IProps) => {
  const navigate = useNavigate()
  const isAdmin = useRecoilValue(authAtom).user.isAdmin

  const [draft, setDraft] = useState(props.project.projectToTeams)
  const [teamModalOpen, setTeamModalOpen] = useState(false)
  const [editing, setEditing] = useState(false)
  const { wait, loading } = useHttpLoader()
  const { wait: teamsUpdateWait, loading: teamsUpdateLoading } = useHttpLoader()

  const joinPossible = useIsJoinProjectPossible()
  const projectHasPlace = props.project.projectToTeams.length < props.project.teamsTargetCount

  const userId = useRecoilValue(authAtom).user.id
  const activeTeam = useSelfActiveTeam()

  const handleSave = () => {
    wait(projectsApi.joinProject(props.project.id), (resp) => {
      if (resp.status === 'success') {
        props.onJoined(resp.body)
      }
    })
  }

  const handleTeamsUpdated = () => {
    teamsUpdateWait(
      projectsApi.setTeams(
        props.project.id,
        draft.map((t) => t.teamId)
      ),
      (resp) => {
        if (resp.status === 'success') {
          props.onUpdated(resp.body)
          setEditing(false)
        }
      }
    )
  }

  const handleTeamRemoved = (index: number) => {
    setDraft((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
  }

  const handleTeamSelected = (team: ITeam) => {
    setTeamModalOpen(false)
    setDraft((prev) => [...prev, { team: { name: team.name }, teamId: team.id, projectId: props.project.id }])
  }

  return (
    <Card type='normal' style={{ height: '100%', width: '100%' }}>
      {teamModalOpen && <SelectTeamModal onClose={() => setTeamModalOpen(false)} onSelect={handleTeamSelected} />}
      {!isAdmin && joinPossible && projectHasPlace && (
        <div className={css.joinProjectWrapper}>
          <Button theme='primary' style={{ width: 'fit-content' }} loading={loading} onClick={handleSave}>
            Присоедениться к проекту
          </Button>
        </div>
      )}
      {!isAdmin && joinPossible && projectHasPlace && props.project.adminOnly && (
        <div className={css.joinProjectWrapper}>
          <p className='text--body-m text--alert' style={{ textAlign: 'center' }}>
            Вступить в проект можно только{'\n'}
            через запрос к администраторам.
          </p>
        </div>
      )}
      {!isAdmin && !activeTeam && projectHasPlace && (
        <div className={css.joinProjectWrapper}>
          <p className='text--body-m text--alert' style={{ textAlign: 'center' }}>
            Для вступления в проект создайте команду{'\n'} или вступите в уже существующую
          </p>
        </div>
      )}
      {!isAdmin && activeTeam && activeTeam.ownerId !== userId && projectHasPlace && (
        <div className={css.joinProjectWrapper}>
          <p className='text--body-m text--alert' style={{ textAlign: 'center' }}>
            Вступить в проект может только лидер вашей команды
          </p>
        </div>
      )}
      <div className='flex-lines'>
        <div className='flex-space-between'>
          <h2 className='text--caption-l'>
            Команды {draft.length} / {props.project.teamsTargetCount}
          </h2>
          {isAdmin && !editing && <EditButton onClick={() => setEditing(true)} />}
        </div>
        <div className='flex-lines'>
          {draft.map((t, index) => (
            <div key={t.teamId} className='inline-flex-gap' style={{ gap: '8px' }}>
              <div className={css.teamDot} />
              <div
                className='text--body-m text--link text--link-underline'
                onClick={() => navigate('/teams/' + t.teamId)}
              >
                {t.team.name}
              </div>
              {isAdmin && editing && (
                <DeleteIcon
                  onClick={() => handleTeamRemoved(index)}
                  style={{ width: '20px', height: '20px', marginLeft: '20px', cursor: 'pointer' }}
                />
              )}
            </div>
          ))}
        </div>
        {editing && (
          <div className='form-footer'>
            <div className='inline-flex-gap'>
              <Button theme='primary' style={{ width: 'fit-content' }} onClick={() => setTeamModalOpen(true)}>
                Добавить команду
              </Button>
              <Button
                theme='primary'
                style={{ width: 'fit-content' }}
                onClick={handleTeamsUpdated}
                loading={teamsUpdateLoading}
              >
                Сохранить
              </Button>
              <Button
                theme='grey-outline'
                style={{ width: 'fit-content' }}
                onClick={() => {
                  setDraft(props.project.projectToTeams)
                  setEditing(false)
                }}
              >
                Отмена
              </Button>
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

export default ProjectTeamsCard
