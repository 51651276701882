import { Outlet } from 'react-router-dom'
import css from './topNavigationLayout.module.css'
import cn from '../../../utils/cn'
import { ReactComponent as Logo } from 'assets/images/logo/logo.svg'
import router from '../../../navigation/app.router'
import navigationUtils from '../../../navigation/navigation.utils'
import { Fragment } from 'react'
import NavigationLink from '../../../navigation/components/NavigationLink'
import ProfileIcon from './ProfileIcon'

const TopNavigationLayout = () => {
  const renderLinks = () => {
    return router.flattenRoutes.children.map((r) => {
      if ((!r.title && !r.navigationLink) || !r.element || r.path === '/settings') return null

      // Найти роут чтобы увидеть его детей (flattenRoutes убирает детей)
      const { parent } = navigationUtils.findRoute(r.path)
      if (parent) return null

      if (r.navigationLink) {
        return <Fragment key={r.path}>{r.navigationLink}</Fragment>
      }
      return <NavigationLink key={r.path} route={r} />
    })
  }

  return (
    <div className={css.wrapper}>
      <div className={cn('sides-padding', css.header)}>
        <Logo className={css.logo} />
        <div
          className='inline-flex-gap'
          style={{ height: '100%', width: '100%', justifyContent: 'center', gap: '2em' }}
        >
          {renderLinks()}
        </div>
        <div className={css.profile}>
          <ProfileIcon />
        </div>
      </div>
      <div className={css.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default TopNavigationLayout
