import SearchInput from '../../../../../shared/Inputs/SearchInput'
import { useRecoilState } from 'recoil'
import useItemsSetter from '../../../../../recoil/useItemsSetter'
import useTableWithRequest from '../../../../../shared/Table/useTableWithRequest'
import { useCallback } from 'react'
import useDebounce from '../../../../../shared/hooks/useDebounce'
import usersApi from '../../../../Users/users.api'
import Table from '../../../../../shared/Table'
import { IUser } from '../../../RolesAndUsers/users.types'
import usersTableAtom from './usersTable.atom'
import usersTableFields from './usersTable.fields'

interface IProps {
  onClick?: (user: IUser) => void
}

const UsersTable = (props: IProps) => {
  const [usersTableState, setUsersTableState] = useRecoilState(usersTableAtom)
  const debouncedSearch = useDebounce(usersTableState.filters.search, 400)

  const handleChange = (value: any, name: string) => {
    setUsersTableState((prev) => ({ ...prev, filters: { ...prev.filters, [name]: value } }))
  }

  const fetcher = useCallback(
    (params: Record<string, any>) => {
      return usersApi.list({
        search: debouncedSearch,
        ...params,
      })
    },
    [debouncedSearch]
  )

  const itemsSetter = useItemsSetter(usersTableAtom)

  const tableCtrl = useTableWithRequest({
    dataFetcher: fetcher,
    fields: usersTableFields,
    itemKey: 'id',
    items: usersTableState.items,
    setItems: itemsSetter,
    tableKey: 'users',
    serverSideOrder: true,
  })

  return (
    <div className='flex-lines' style={{ height: '100%' }}>
      <div className='inline-flex-gap' style={{ gap: '8px', width: '100%' }}>
        <SearchInput
          value={usersTableState.filters.search}
          name='search'
          onChange={handleChange}
          required={false}
          placeholder='Поиск по ФИО или группе'
          autofocus={true}
        />
      </div>
      <Table
        {...tableCtrl}
        items={tableCtrl.orderedItems}
        handleSelect={tableCtrl.selectedCtrl.setSelected}
        selected={tableCtrl.selectedCtrl.selectedIndexes as Set<keyof IUser>}
        handleSelectAll={tableCtrl.selectedCtrl.setSelectAll}
        itemKey='id'
        onCLick={props.onClick}
        selectEnabled={false}
      />
    </div>
  )
}

export default UsersTable
