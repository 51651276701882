import React from 'react'
import Button from './shared/Buttons/Button/Button'

/**
 * Компонент для обработки непредвиденных и не перехваченных ошибок.
 * Выводит сообщение ошибки с предложением перезагрузить страницу или вернуться на главную
 */
class ErrorBoundary extends React.Component<any, State> {
  state: State = { hasError: false, errorMsg: '' }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMsg: error.message }
  }

  componentDidCatch(error, info) {
    console.error(error, info)
  }

  private reloadPage = () => location.reload() // eslint-disable-line
  private movetoMain = () => location.replace('/') // eslint-disable-line

  render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    return (
      <div className="flex-page-center">
        <h2 className="page--header text--alert">Произошла непредвиденная ошибка :(</h2>
        <div className="inline-flex-gap">
          <Button theme="grey-outline" onClick={this.reloadPage}>
            Перезагрузить страницу
          </Button>
          <Button theme="primary" onClick={this.movetoMain}>
            На главную
          </Button>
        </div>
      </div>
    )
  }
}

interface State {
  hasError: boolean
  errorMsg: string
}

export default ErrorBoundary
