import useNtf from './useNtf'
import styles from './ntf.module.css'
import cn from '../../utils/cn'

/* Нотификации, появляющиеся в правом нижнем углу */
const Ntf = () => {
  const { currentNtf, ntfActive, setNtfActive } = useNtf()

  return (
    <div
      className={cn(styles.wrapper, 'text--body-m')}
      data-active={ntfActive}
      data-msgtype={currentNtf?.type}
      onClick={() => setNtfActive(false)}
    >
      {currentNtf?.message}
    </div>
  )
}

export default Ntf
