import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import authAtom from 'screens/Auth/auth.atom'
import useIsProjectEditable from 'screens/Projects/hooks/useIsProjectEditable'
import useProjectsActions from 'screens/Projects/hooks/useProjectsActions'
import { IProject } from 'screens/Projects/projects.types'
import Button from 'shared/Buttons/Button/Button'
import EditButton from 'shared/Buttons/EditButton'
import Card from 'shared/Cards/Card'
import Textarea from 'shared/Inputs/Textarea'

interface IProps {
  project: Pick<IProject, 'id' | 'expectingResults'>
}

const ProjectExpectingResultsCard = (props: IProps) => {
  const projectsActions = useProjectsActions()

  const isAdmin = useRecoilValue(authAtom).user.isAdmin
  const isEditable = useIsProjectEditable(props.project.id)
  const [editing, setEditing] = useState(false)
  const [draftExpectingResults, setDraftExpectingResult] = useState(props.project.expectingResults)

  const handleSave = () => {
    projectsActions.update(props.project.id, { expectingResults: draftExpectingResults }).then((r) => {
      if (r.status === 'success') {
        setEditing(false)
      }
    })
  }

  const renderContent = () => {
    if (editing) {
      return (
        <>
          {!isAdmin && (
            <p className='text--alert text--body-s' style={{ marginBottom: '8px' }}>
              Внимание! При изменении проекта, его статус станет "На рассмотрении".
            </p>
          )}
          <Textarea
            value={draftExpectingResults}
            onChange={setDraftExpectingResult}
            name='expectingResults'
            height='100px'
          />
          <div className='form-footer'>
            <div className='inline-flex-gap'>
              <Button
                theme='primary'
                style={{ width: 'fit-content' }}
                onClick={handleSave}
                loading={projectsActions.loading}
              >
                Сохранить
              </Button>
              <Button
                theme='grey-outline'
                style={{ width: 'fit-content' }}
                onClick={() => {
                  setDraftExpectingResult(props.project.expectingResults)
                  setEditing(false)
                }}
                disabled={projectsActions.loading}
              >
                Отмена
              </Button>
            </div>
          </div>
        </>
      )
    }

    return (
      <p className='text--body-m' style={{ whiteSpace: 'pre-wrap' }}>
        {draftExpectingResults}
      </p>
    )
  }

  return (
    <Card type='normal'>
      <div className='flex-space-between' style={{ marginBottom: '16px', width: '100%' }}>
        <h2 className='text--caption-l text-700'>Ожидаемые результаты</h2>
        {!editing && isEditable && <EditButton onClick={() => setEditing(true)} />}
      </div>
      {renderContent()}
    </Card>
  )
}

export default ProjectExpectingResultsCard
