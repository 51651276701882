import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import InputMask from 'react-input-mask'
import errorContext from '../../Forms/ValidationForm/ErrorContext'

interface IProps {
  date: string
  name: string
  onChange: (date: string, name: string) => void
  placeholder?: string
  required?: boolean
  validator?: (d: string) => string | null
  disabled?: boolean
}

const dateRegex = `^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$`

const DateInput = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const context = useContext(errorContext)
  const error = context.getError(props.name)

  const [maskValue, setMaskValue] = useState(props.date ? props.date.split('T')[0].split('-').reverse().join('.') : '')

  useEffect(() => {
    if (!inputRef.current || props.disabled) return

    const isValid = inputRef.current?.validity?.valid
    if (isValid) {
      if (props.validator) {
        const value = inputRef.current.value.split('.').reverse().join('-')
        const validationMessage = props.validator(value)

        context.onValidate(props.name, validationMessage)
      }
    }
  }, [props.validator])

  const handleMaskChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) return

    setMaskValue(e.target.value)

    let validationMessage = e.target.validity.valid ? '' : '-'
    if (e.target.validity.valid) {
      if (props.validator && !validationMessage) {
        const val = e.target.value.split('.').reverse().join('-')
        validationMessage = props.validator(val)
      }
    }

    context.onValidate(props.name, validationMessage)

    if (e.target.validity.valid) props.onChange(e.target.value.split('.').reverse().join('-'), props.name)
  }

  return (
    <div style={{ width: '100%' }}>
      <InputMask
        mask='99.99.9999'
        placeholder={props.placeholder}
        onChange={handleMaskChange}
        value={maskValue}
        inputRef={inputRef}
        disabled={props.disabled}
      >
        <input
          pattern={dateRegex}
          className='etba-input'
          required={true}
          data-invalid={Boolean(error)}
          name={props.name}
          inputMode='numeric'
          ref={inputRef}
          disabled={props.disabled}
        />
      </InputMask>
      {error && error !== '-' && <p className='etba-input_error text--danger'>{error}</p>}
    </div>
  )
}

export default DateInput
