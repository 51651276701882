import AdminNavigationLayout from 'shared/Layouts/TopNavigationLayout/AdminNavifationLayout'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import Sheet from 'shared/Sheet'
import EventsDropdown from '../Events/components/EventsDropdown'
import { useState } from 'react'
import { IEvent } from '../Events/events.types'
import Button from 'shared/Buttons/Button/Button'
import { useRecoilValue } from 'recoil'
import authAtom from 'screens/Auth/auth.atom'

const ExportScreen = () => {
  const [selectedEventId, setSelectedEventId] = useState<IEvent['id']>(null)
  const accessToken = useRecoilValue(authAtom).auth.accessToken

  const handleITSExport = () => {
    const link = document.createElement('a')
    link.target = '_blank'
    link.setAttribute('download', 'ITS.json')
    link.setAttribute(
      'href',
      `https://xn--e1aajagmjdbheh6azd.xn--p1ai/nsi/v1/convey/export/${selectedEventId}/users-on-projects/its.json?accessToken=${accessToken}`
    )

    link.click()
  }

  return (
    <AdminNavigationLayout>
      <TopNavigationLayoutPageContent type='default'>
        <h2 className='page--header'>Экспорт</h2>
        <div>
          <Sheet>
            <div className='flex-lines'>
              <h2 className='text--caption-l'>Экспорт для ИТС</h2>
              <div className='grid-form-2'>
                <EventsDropdown value={selectedEventId} onChange={setSelectedEventId} />
              </div>
              <div className='form-footer'>
                <Button
                  theme='primary'
                  disabled={!selectedEventId}
                  style={{ width: 'fit-content' }}
                  onClick={handleITSExport}
                >
                  Экспорт
                </Button>
              </div>
            </div>
          </Sheet>
        </div>
      </TopNavigationLayoutPageContent>
    </AdminNavigationLayout>
  )
}

export default ExportScreen
