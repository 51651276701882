import { useState } from 'react'
import projectsApi from 'screens/Projects/projects.api'
import eventBus from 'services/eventBus'
import Button from 'shared/Buttons/Button/Button'
import FileInput from 'shared/Inputs/FileInput'
import AdminNavigationLayout from 'shared/Layouts/TopNavigationLayout/AdminNavifationLayout'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import Sheet from 'shared/Sheet'
import useHttpLoader from 'shared/hooks/useHttpLoader'
import { IEvent } from '../Events/events.types'
import EventsDropdown from '../Events/components/EventsDropdown'
import confirmServiceCtrl from 'services/ConfirmService/confirmService.ctrl'

const ImportPage = () => {
  const { wait, loading } = useHttpLoader()
  const { wait: waitDeleteAll, loading: deleteAllLoading } = useHttpLoader()

  const [file, setFile] = useState<File>(null)
  const [eventId, setEventId] = useState<IEvent['id']>(null)

  const handleSave = () => {
    wait(projectsApi.importProjects(file, eventId), (resp) => {
      if (resp.status === 'success') {
        eventBus.pub('ntf.message', { type: 'info', message: 'Импорт успешно завершен' })
        setFile(null)
      }
    })
  }

  const handleDeleteAllProjects = () => {
    confirmServiceCtrl
      .requestConfirm({
        type: 'danger',
        title: 'Подтвердите операцию',
        description: 'Подтвердите удаление всех проектов в системе',
      })
      .then((r) => {
        if (r.response === 'cancel') return

        waitDeleteAll(projectsApi.deleteAllProjects(), (resp) => {
          if (resp.status === 'success') {
            eventBus.pub('ntf.message', { type: 'info', message: 'Все проекты удалены' })
          }
        })
      })
  }

  return (
    <AdminNavigationLayout>
      <TopNavigationLayoutPageContent type='default'>
        <h2 className='page--header'>Импорт проектов</h2>
        <div style={{ width: '70%' }}>
          <Sheet>
            <div className='flex-lines'>
              <EventsDropdown value={eventId} onChange={setEventId} />

              <div className='grid-form-2'>
                <div className='flex-lines' style={{ gap: '6px' }}>
                  <FileInput onChange={(_, f) => setFile(f)} accept='.csv' buttonClass='grey-outline'>
                    Загрузить файл
                  </FileInput>
                  {file && <p className='text--body-s'>{file.name}</p>}
                </div>
              </div>

              <div>
                <p className='text--body-m'>
                  На вход ожидается таблица со следующими колонками:{'\n'}
                  {'\n'}
                  Название проекта, Описание проекта, Куратор (Наставник), Цель проекта, Результат, Организация
                  заказчика (ID), Контакты представителя заказчика, Роли, Курсы, которым будет доступен проект,
                  Жизненный сценарий (Проектный трек), Ключевая технология (ID), Запись закрыта, Размер команды,
                  Ограничение по командам, Всего студентов, Soft skills, Hard Skills{' '}
                </p>
              </div>
              <div className='form-footer'>
                <Button
                  theme='primary'
                  style={{ width: 'fit-content' }}
                  disabled={!file || !eventId}
                  loading={loading}
                  onClick={handleSave}
                >
                  Сохранить
                </Button>
              </div>
            </div>
          </Sheet>
        </div>
        <div style={{ width: '70%' }}>
          <Sheet>
            <Button
              theme='danger'
              style={{ width: 'fit-content' }}
              loading={deleteAllLoading}
              onClick={handleDeleteAllProjects}
            >
              Удалить все проекты
            </Button>
          </Sheet>
        </div>
      </TopNavigationLayoutPageContent>
    </AdminNavigationLayout>
  )
}

export default ImportPage
