import eventBus from '../eventBus'

export interface IConfirmRequestParams {
  type: 'info' | 'danger'
  title: string
  description: string
}
const requestConfirm = async (params: IConfirmRequestParams): Promise<{ response: 'confirm' | 'cancel' }> => {
  eventBus.pub('confirmService.request', params) //to open the modal
  return eventBus.waitForEvent('confirmService.response').catch()
}

const confirmServiceCtrl = { requestConfirm }
export default confirmServiceCtrl
