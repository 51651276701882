import css from './materialInput.module.css'
import { ChangeEvent, PropsWithChildren, useContext, useEffect, useRef } from 'react'
import errorContext from '../../Forms/ValidationForm/ErrorContext'

interface IProps {
  value: string
  name: string
  onChange: (val: string, name: string, valid: boolean) => void

  title: string
  required?: boolean
  disabled?: boolean
  autofocus?: boolean
}

const MaterialInput = (props: PropsWithChildren<IProps>) => {
  const ref = useRef<HTMLInputElement>()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) return

    const validationMessage = e.target.validity.valid ? '' : '-'

    context.onValidate(props.name, validationMessage)

    props.onChange(e.target.value as any, props.name, e.target.validity.valid)
  }

  const context = useContext(errorContext)
  const error = context.getError(props.name)

  useEffect(() => {
    if (props.disabled) return

    if (!props.required) return context.onValidate(props.name, '')

    if (error && error !== '-') return
    const validationMessage = ref.current.validity.valid ? '' : '-'

    context.onValidate(props.name, validationMessage)

    return () => {
      context.onValidate(props.name, '')
    }
  }, [props.required])

  const renderInput = () => {
    if (props.children) return props.children

    return (
      <input
        autoFocus={props.autofocus}
        placeholder=' '
        value={props.value ?? ''}
        name={props.name}
        onChange={handleChange}
        ref={ref}
        required={props.required}
        disabled={props.disabled}
      />
    )
  }

  return (
    <div className={css.wrapper}>
      <label className={css.materialInput}>
        {renderInput()}
        <span>{props.title}</span>
      </label>
      {error !== '-' && error && (
        <span title={error} className='text--alert line_clamp' style={{ maxWidth: '100%' }}>
          {error}
        </span>
      )}
    </div>
  )
}

export default MaterialInput
