import { ITeam } from 'screens/Teams/teams.types'
import http, { handleHttpError, handleHttpResponse } from '../../services/http'
import { HTTPResponse, IPaginationResponse } from '../../services/http/http.types'
import { IUser } from '../Admin/RolesAndUsers/users.types'
import { IProject } from 'screens/Projects/projects.types'

interface IListParams {
  search?: string
  limit?: number
  nextPage?: string
}
const list = async (params: IListParams): Promise<HTTPResponse<IPaginationResponse<IUser>>> => {
  return http.get('/nsi/v1/users', { params }).then(handleHttpResponse).catch(handleHttpError)
}

const getUser = async (id: IUser['id']) => {
  return http
    .get('/nsi/v1/users/' + id)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const getUserTeamsAndProjects = async (
  id: IUser['id']
): Promise<HTTPResponse<{ teams: ITeam[]; projects: IProject[] }>> => {
  return http
    .get('/nsi/v1/users/' + id + '/teams-and-projects')
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const usersApi = { list, getUser, getUserTeamsAndProjects }
export default usersApi
