import { useRecoilValue } from 'recoil'
import eventsAtom from '../../events.atom'
import Dropdown from '../../../../../shared/Inputs/Dropdown'
import { IEvent } from '../../events.types'
import { useMemo } from 'react'

interface IProps {
  name?: string
  value: IEvent['id']
  onChange: (val: IEvent['id'], name: string) => void
}

const EventsDropdown = (props: IProps) => {
  const events = useRecoilValue(eventsAtom).items

  const options = useMemo(() => {
    return events.map((e) => ({ title: e.name, value: e.id }))
  }, [events])

  return (
    <Dropdown
      options={options}
      selectedOption={props.value}
      title='Событие'
      onSelect={props.onChange}
      name={props.name}
    />
  )
}

export default EventsDropdown
