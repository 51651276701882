import { EBCallback, EBTopic, IEBTopicData } from './eventBus.types'

type Cached = {
  [key in EBTopic]: IEBTopicData[key]
}

class EventBus {
  private cached: Cached = {} as any
  private subscribers: Map<EBTopic, EBCallback<EBTopic>[]> = new Map<EBTopic, EBCallback<EBTopic>[]>()

  public pub<T extends EBTopic>(topic: T, value: IEBTopicData[T], cacheLastValue = false) {
    if (cacheLastValue) this.cached[topic] = value
    if (!this.subscribers.has(topic)) return

    const subs = this.subscribers.get(topic)
    for (const callback of subs) callback(value)
  }

  public sub<T extends EBTopic>(topic: T, callback: EBCallback<T>, emitLastValue = false) {
    if (this.subscribers.has(topic)) this.subscribers.get(topic).push(callback)
    else this.subscribers.set(topic, [callback])

    if (emitLastValue) callback(this.cached[topic])

    return () => {
      const subs = this.subscribers.get(topic)

      const index = subs.findIndex((cb) => cb === callback)
      if (index === -1) return
      subs.splice(index, 1)
    }
  }

  public getCachedValue<T extends EBTopic>(topic: T): IEBTopicData[T] | undefined {
    return this.cached[topic]
  }

  public waitForEvent<T extends EBTopic>(topic: T): Promise<IEBTopicData[T]> {
    return new Promise((resolve, reject) => {
      eventBus.sub(topic, (e) => {
        resolve(e)
      })
      setTimeout(reject, 20000)
    })
  }
}

const eventBus = new EventBus()
export default eventBus
