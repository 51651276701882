import { IUserSkill } from './userSkills.types'
import { HTTPResponse, IPaginationResponse } from '../../../services/http/http.types'
import http, { handleHttpError, handleHttpResponse } from '../../../services/http'

interface IGetParams {
  search?: string
}
const listUserSkills = async (params: IGetParams): Promise<HTTPResponse<IPaginationResponse<IUserSkill>>> => {
  return http.get('/nsi/v1/skills', { params }).then(handleHttpResponse).catch(handleHttpError)
}

const getSkill = async (id: IUserSkill['id']): Promise<HTTPResponse<IUserSkill>> => {
  return http
    .get('/nsi/v1/skills/' + id)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const createUserSkill = async (dto: Omit<IUserSkill, 'id'>): Promise<HTTPResponse<IUserSkill>> => {
  return http.post('/nsi/v1/skills', dto).then(handleHttpResponse).catch(handleHttpError)
}

const updateSkill = async (dto: Partial<IUserSkill>, id: IUserSkill['id']): Promise<HTTPResponse<IUserSkill>> => {
  return http
    .patch('/nsi/v1/skills/' + id, dto)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const userSkillsApi = { createUserSkill, listUserSkills, getSkill, updateSkill }
export default userSkillsApi
