import { useRecoilValue } from 'recoil'
import eventsSelectors from 'screens/Admin/Events/events.selectors'
import selfProjectsAtom from 'screens/Auth/components/InitProvider/selfProjects.atom'

const useSelfActiveProject = () => {
  const eventsMap = useRecoilValue(eventsSelectors.eventsFullMap)
  const selfProjects = useRecoilValue(selfProjectsAtom)

  return selfProjects.items.find(
    (it) =>
      new Date(eventsMap[it.eventId].endsAt).getTime() > new Date().getTime() &&
      new Date(eventsMap[it.eventId].startAt).getTime() < new Date().getTime()
  )
}

export default useSelfActiveProject
