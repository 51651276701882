import { useMemo } from 'react'
import { createBrowserRouter, matchPath } from 'react-router-dom'
import router from './app.router'

/**
 * Returns router with available for user routes
 * */
const useAppRouter = () => {
  return useMemo(() => {
    const search = window.location.search.toString()
    const path = window.location.pathname

    const newRouter = createBrowserRouter([router.flattenRoutes])

    if (path !== '/' && router.flattenRoutes.children.some((r) => matchPath(r.path, path))) {
      newRouter.navigate(path + search)
    } else {
      newRouter.navigate(router.flattenRoutes.children[1].path)
    }

    router.appRouter = newRouter
    return newRouter
  }, [])
}

export default useAppRouter
