import useEntity from 'recoil/useEntity/useEntity'
import projectsAtom from '../projects.atom'
import { IProject } from '../projects.types'
import projectsApi from '../projects.api'

const useProject = (id: IProject['id']) => {
  const { entity, loading, setEntity } = useEntity<IProject>({
    resource: 'projects',
    atom: projectsAtom,
    entityId: id,
    itemFetcher: projectsApi.getProject,
  })

  return { project: entity, loading, setProject: setEntity }
}

export default useProject
