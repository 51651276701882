import { atom } from 'recoil'
import { IAccessRight } from './auth.types'
import { IUser } from '../Admin/RolesAndUsers/users.types'

export interface ISelfUserState {
  loaded: boolean
  user: IUser
  accessRights: IAccessRight[]
}

const selfUserAtom = atom<ISelfUserState>({
  key: 'user',
  default: {
    loaded: false,
    user: null,
    accessRights: [],
  },
})

export default selfUserAtom
