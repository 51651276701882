import { ReactComponent as ArrowLeft } from '../../../assets/icons/longArrowLeft.svg'
import css from './backButton.module.css'

interface IProps {
  onClick?: () => void
  direction: 'left' | 'right'
}

const BackButton = (props: IProps) => {
  return (
    <div className={css.container} onClick={props.onClick} data-direction={props.direction}>
      <ArrowLeft />
    </div>
  )
}

export default BackButton
