import { IDropdownOption } from '../Inputs/PanelDropdown'

const tableItemsPerPageOptions: IDropdownOption[] = [
  { title: '10', value: 10 },
  { title: '50', value: 50 },
  { title: '100', value: 100 },
  { title: '250', value: 250 },
  { title: '500', value: 500 },
  { title: 'Custom', value: 'custom' },
]
export default tableItemsPerPageOptions
