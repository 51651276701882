import React, { useEffect, useRef } from 'react'
import { ReactComponent as ArrowDown } from 'assets/icons/triangleDown.svg'
import cn from '../../../utils/cn'
import searchUtils, { SearchResult } from '../../../utils/search'
import { useCombobox, UseComboboxStateChange, UseComboboxStateChangeTypes } from 'downshift'
import SmoothDropdown from '../../Animated/SmoothDropdownBehaivor/SmoothDropdown'
import useScrollIntoView from '../../hooks/useScrollIntoView'
import useVirtualMenu from '../../hooks/useVirtualMenu'

const Select = (props: ISelectProps) => {
  const [expandedGroup, setExpandedGroup] = React.useState<string>(null)
  const [isOpen, setOpen] = React.useState(props.autofocus)
  const [inputValue, setInputValue] = React.useState(props.selectedOption?.label || '')

  const ref = useRef<HTMLDivElement>(null)
  const selectedItem = useRef(null)

  const selectedLabel = props.selectedOption?.label
  const shouldFilter = inputValue && inputValue !== selectedLabel
  const shouldGroup = props.groups != null && !shouldFilter

  useEffect(() => {
    if (!isOpen && props.selectedOption && inputValue !== props.selectedOption.label) {
      setInputValue(props.selectedOption.label)
    }
  }, [isOpen])

  const items: SelectItem[] = React.useMemo(() => {
    if (shouldFilter) {
      const values = inputValue
        .toLowerCase()
        .split(' ')
        .filter((v) => v)

      const matches: SelectItem[] = []
      for (const option of props.options) {
        const result = searchUtils.search(option.label, values)

        if (result.length !== 0) {
          matches.push({ ...option, index: matches.length, search: result })
        }
      }

      return matches
    } else if (props.groups) {
      return props.options.filter((o) => o.group === expandedGroup).map((o, index) => ({ ...o, index }))
    } else {
      return props.options.map((o, index) => ({ ...o, index }))
    }
  }, [props.options, shouldFilter, shouldGroup, expandedGroup, inputValue])

  const handleChange = (changes: UseComboboxStateChange<SelectOption>) => {
    const option = changes.selectedItem

    if (!option && props.onRemove) {
      // после нажатия ESC option = null, в этом случае
      // вызвать обработчик удаления если он определен
      props.onRemove(props.name)
    } else if (option) {
      props.onChange(option.value, props.name)
    }

    setOpen(false)
  }

  const handleInputChange = (value: string, type: UseComboboxStateChangeTypes) => {
    setInputValue(value)

    if (type === useCombobox.stateChangeTypes.InputChange) {
      if (!value && props.onRemove) {
        return props.onRemove(props.name)
      }

      const match = props.options.find((o) => o.label === value)
      if (match) props.onChange(match.value, props.name)
    }
  }

  const handleHighlightChange = (changes) => {
    const index = changes.highlightedIndex as number

    if (index !== -1 && props.onHover) {
      const option = items[index]
      props.onHover(option.value, props.name)
    }
  }

  const openMenu = () => {
    if (!props.disabled) {
      setOpen(true)

      if (props.onHover && props.selectedOption) {
        props.onHover(props.selectedOption.value, props.name)
      }
    }
  }

  const toggleGroup = (e) => {
    const group = e.target.dataset.id
    setExpandedGroup((prev) => (prev === group ? null : group))
  }

  const downshift = useCombobox({
    itemToString,
    onSelectedItemChange: handleChange,
    onHighlightedIndexChange: handleHighlightChange,
    onIsOpenChange: (changes) => setOpen(changes.isOpen),
    onInputValueChange: (changes) => handleInputChange(changes.inputValue, changes.type),
    initialSelectedItem: props.selectedOption,
    selectedItem: props.selectedOption || null,
    isOpen,
    items,
  })

  const position = useVirtualMenu({ elementRef: ref, isOpen: isOpen && props.isVirtual })
  useScrollIntoView(isOpen && downshift.selectedItem?.value, selectedItem, '.etba-select', 'nearest')

  const renderMenuItem = (option: SelectItem, label: any) => {
    const className = cn({
      'etba-select-item': true,
      'is-selected': option?.value === props.selectedOption?.value,
      'text--body-s': true,
    })

    return (
      <div
        key={option.index}
        className={className}
        {...downshift.getItemProps({ item: option, index: option.index })}
        ref={option?.value === downshift.selectedItem?.value && downshift.selectedItem ? selectedItem : null}
      >
        {label}
      </div>
    )
  }

  const renderItems = (options: SelectItem[]) => {
    return options.map((option) => renderMenuItem(option, option.label))
  }

  const renderSearchResults = () => {
    return items.map((item) => renderMenuItem(item, searchUtils.renderSearchResult(item.search)))
  }

  const renderGroups = () => {
    return props.groups.map((group) => {
      const isExpanded = group === expandedGroup
      const className = cn('nsi-select__menu-group-title', { 'is-expanded': isExpanded })

      return (
        <div key={group} className='nsi-select__menu-group'>
          <div className={className} data-id={group} onClick={toggleGroup}>
            {group}
          </div>
          {isExpanded ? items.map((o) => renderMenuItem(o, o.label)) : null}
        </div>
      )
    })
  }

  const renderSelectMenu = () => {
    if (props.options.length === 0) {
      return <div style={{ padding: 6 }}>Нет доступных опций</div>
    }

    if (shouldFilter) return renderSearchResults()
    else if (shouldGroup) return renderGroups()
    else return renderItems(items)
  }

  const onKeyDown = (e) => {
    if (e.key === 'Escape' && isOpen) {
      e.preventDefault()
    }
  }

  const renderMenu = () => {
    return (
      <div
        style={isOpen ? {} : { boxShadow: 'none', zIndex: 1 }}
        {...downshift.getMenuProps()}
        className='etba-select-menu'
      >
        <SmoothDropdown maxHeight={300} open={isOpen} overflow='auto'>
          {renderSelectMenu()}
        </SmoothDropdown>
      </div>
    )
  }

  return (
    <div
      onClick={openMenu}
      {...downshift.getComboboxProps()}
      style={props.fullWidth ? { width: '100%' } : {}}
      className='etba-select'
    >
      {props.title && !props.selectedOption && !inputValue && (
        <span className='text--placeholder etba-select-title text--body-s'>{props.title}</span>
      )}
      <span ref={ref} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: -5 }} />
      <input
        type='text'
        className='etba-input'
        {...downshift.getInputProps({ onKeyDown })}
        onFocus={openMenu}
        autoFocus={props.autofocus}
        disabled={props.disabled}
        value={inputValue}
        title={inputValue}
        required={props.required}
      />
      {renderMenu()}
      {!props.disabled && (
        <ArrowDown
          className={`etba-select-arrow ${isOpen && 'is-open'} ${position === 'top' && 'to-top'}`}
          onClick={() => (!isOpen ? openMenu() : downshift.closeMenu())}
        />
      )}
    </div>
  )
}

Select.defaultProps = {
  autofocus: false,
}

const itemToString = (item: SelectOption) => (item ? item.label : '')

export interface SelectOption<T = any> {
  value: T
  label: string
  group?: string
}

interface SelectItem extends SelectOption {
  index: number
  search?: SearchResult[]
}

export interface ISelectProps {
  name: string
  options: SelectOption[]
  selectedOption: SelectOption
  groups?: string[]
  autofocus?: boolean
  disabled?: boolean
  onChange: (value: any, name: string) => void
  onHover?: (value: any, name: string) => void
  onRemove?: (name: string) => void
  fullWidth?: boolean // for 'display: flex'
  required?: boolean
  isVirtual?: boolean
  title?: string
}

export default Select
