import { useLocation } from 'react-router-dom'
import css from './navigationLink.module.css'
import navigationUtils from '../../navigation.utils'
import { IRoute } from '../../navigation.types'
import cn from '../../../utils/cn'

const useNavigationLink = (route: Pick<IRoute, 'path'>) => {
  const location = useLocation()

  const { isGroupActive, isActive } = navigationUtils.isRouteActive(location, route)
  const { parent } = navigationUtils.findRoute(route.path)

  const isNested = Boolean(parent)
  const isLastChild = parent?.children?.[parent?.children?.length - 1]?.path === route.path

  const className = cn({
    [css.navLink]: true,
    [css.navLinkActive]: isActive,
    [css.groupActive]: isGroupActive,
    [css.activeGroupRoot]: isGroupActive && !isNested,
  })

  return { className, isLastChild, isActive, isNested }
}

export default useNavigationLink
