import { produce } from 'immer'
import { ITableField } from '../../index'
import React, { useRef } from 'react'

interface IProps<T extends Record<string, any>, K extends keyof T | string> {
  columns: ITableField<T, K>[]
  columnsSetter: React.Dispatch<React.SetStateAction<ITableField<T, keyof T>[]>>
  tableKey: string
}

const useResizeColumnPlugin = <T extends Record<string, any>, K extends keyof T | string>(props: IProps<T, K>) => {
  const startEvRef = useRef<MouseEvent>(null)
  const originalWidthRef = useRef(0)

  const handleMouseMove = (startEv: MouseEvent, currentEv: MouseEvent, columnKey: string) => {
    if (!startEv || !currentEv || !columnKey) return

    if (startEvRef.current !== startEv) {
      startEvRef.current = startEv

      const columnIndex = props.columns.findIndex((c) => c.key === columnKey)
      if (columnIndex === -1) return

      originalWidthRef.current = parseInt(props.columns[columnIndex].width)
    }

    const movementDx = currentEv.clientX - startEv.clientX
    const originalWidth = originalWidthRef.current

    const updatedWidth = originalWidth + movementDx
    props.columnsSetter((prev) =>
      produce(prev, (draft) => {
        const fieldIndex = draft.findIndex((f) => f.key === columnKey)
        if (fieldIndex === -1) return prev

        draft[fieldIndex].width = updatedWidth.toString() + 'px'
        return draft
      })
    )
  }

  return { handleMouseMove }
}

export type ResizeColumnPluginCtrl = ReturnType<typeof useResizeColumnPlugin>
export default useResizeColumnPlugin
