import { useParams } from 'react-router-dom'
import AdminNavigationLayout from 'shared/Layouts/TopNavigationLayout/AdminNavifationLayout'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import Button from 'shared/Buttons/Button/Button'
import ThreeDotsLoader from 'shared/Loaders/ThreeDotsLoader'
import { useEffect, useState } from 'react'
import useHttpLoader from 'shared/hooks/useHttpLoader'
import eventBus from 'services/eventBus'
import { useSetRecoilState } from 'recoil'
import recoilStateUpdaters from 'recoil/recoil.stateUpdaters'
import useOrganization from '../../hooks/useOrganization'
import organiaztionsTableAtom from '../../componenets/OrganizationsTable/organizations.atom'
import organizationsApi from '../../organizations.api'
import OrganizationSheet from '../../componenets/OrganizationSheet'

const OrganizationPage = () => {
  const { wait, loading: updateLoading } = useHttpLoader()
  
  const params = useParams()
  const id = +params.id
  const { organization, loading } = useOrganization(id)
  const [draft, setDraft] = useState(organization)
  const setState = useSetRecoilState(organiaztionsTableAtom)

  useEffect(() => {
    setDraft(organization)
  }, [organization])

  const handleChange = (val: any, name: string) => {
    setDraft((prev) => ({ ...prev, [name]: val }))
  }

  const handleSave = () => {
    wait(organizationsApi.update(draft, id), (resp) => {
      if (resp.status === 'success') {
        recoilStateUpdaters.itemUpdated(setState, resp.body)
        eventBus.pub('ntf.message', { type: 'info', message: 'Организация обновлена' })
      }
    })
  }

  const renderContent = () => {
    if (!draft || loading) {
      return (
        <div className='flex-page-center'>
          <ThreeDotsLoader />
        </div>
      )
    }

    return (
      <>
        <div className='flex-space-between'>
          <div className='inline-flex-gap' style={{ gap: '2.5em' }}>
            <h2 className='page--header'>Организация</h2>
          </div>
        </div>
        <OrganizationSheet organization={draft} onChange={handleChange}>
          <Button theme='primary' loading={updateLoading} style={{ width: 'fit-content' }} onClick={handleSave}>
            Сохранить
          </Button>
        </OrganizationSheet>
      </>
    )
  }

  return (
    <AdminNavigationLayout>
      <TopNavigationLayoutPageContent type='default'>{renderContent()}</TopNavigationLayoutPageContent>
    </AdminNavigationLayout>
  )
}

export default OrganizationPage
