import { useEffect, useState } from 'react'
import { INtf } from './ntf.types'
import eventBus from '../eventBus'

const useNtf = () => {
  const [currentNtf, setCurrentNtf] = useState<INtf>(null)
  const [ntfActive, setNtfActive] = useState(false)

  useEffect(() => {
    const httpErrorsSub = eventBus.sub('http.error', ({ message }) => {
      setCurrentNtf({ type: 'error', message })
      setNtfActive(true)
      setTimeout(() => setNtfActive(false), 15_000)
    })

    const messagesSub = eventBus.sub('ntf.message', ({ message, type }) => {
      setCurrentNtf({ type, message })
      setNtfActive(true)
      setTimeout(() => setNtfActive(false), 15_000)
    })

    return () => {
      httpErrorsSub()
      messagesSub()
    }
  }, [])

  return { currentNtf, ntfActive, setNtfActive }
}

export default useNtf
