import { useNavigate, useParams } from 'react-router-dom'
import useTeam from 'screens/Teams/hooks/useTeam'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import TeamInfoCard from '../MyTeam/components/TeamCards/TeamInfoCard'
import ThreeDotsLoader from 'shared/Loaders/ThreeDotsLoader'
import TeamMembersCard from '../MyTeam/components/TeamCards/TeamMembersCard'
import ActionsButtonWithMenu from 'shared/Buttons/ActionsButtonWithMenu'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import authAtom from 'screens/Auth/auth.atom'
import confirmServiceCtrl from 'services/ConfirmService/confirmService.ctrl'
import useHttpLoader from 'shared/hooks/useHttpLoader'
import teamsApi from 'screens/Teams/teams.api'
import teamsTableAtom from 'screens/Teams/components/TeamsTable/teamsTable.atom'
import recoilStateUpdaters from 'recoil/recoil.stateUpdaters'

const TeamPage = () => {
  const id = +useParams().id
  const navigate = useNavigate()

  const { wait, loading: deleteLoading } = useHttpLoader()
  const { team, loading } = useTeam(id)
  const isAdmin = useRecoilValue(authAtom).user.isAdmin
  const setTeamsState = useSetRecoilState(teamsTableAtom)

  const handleDelete = () => [
    confirmServiceCtrl
      .requestConfirm({
        title: 'Подтвердите удаление команды',
        description: `Команда "${team.name}" будет удалена и отвязана от всех проектов`,
        type: 'danger',
      })
      .then((r) => {
        if (r.response === 'confirm') {
          wait(teamsApi.deleteTeam(id), (resp) => {
            if (resp.status === 'success') {
              recoilStateUpdaters.itemRemoved(setTeamsState, resp.body.id)
              navigate('/admin/teams')
            }
          })
        }
      }),
  ]

  const renderHeader = () => {
    if (loading || !team) return null

    return (
      <div className='flex-space-between' style={{ marginBottom: '2em' }}>
        <div className='flex-lines' style={{ gap: '6px' }}>
          <h2 className='text--heading-h4' style={{ flexShrink: 0 }}>
            {team.name}
          </h2>
          <span className='text--caption-m'>команда</span>
        </div>
        {isAdmin && (
          <ActionsButtonWithMenu>
            <span className='options-contextmenu-option' data-danger={true} onClick={handleDelete}>
              Удалить
            </span>
          </ActionsButtonWithMenu>
        )}
      </div>
    )
  }

  const renderContent = () => {
    if (loading || !team) {
      return (
        <div className='flex-page-center'>
          <ThreeDotsLoader />
        </div>
      )
    }

    if (deleteLoading) {
      return (
        <div className='flex-page-center'>
          <div className='flex-lines' style={{ justifyContent: 'center' }}>
            <ThreeDotsLoader />
            <span className='text--caption-l'>Удаляем..</span>
          </div>
        </div>
      )
    }

    return (
      <div className='flex-lines gap24'>
        <TeamInfoCard team={team} />
        <TeamMembersCard team={team} />
      </div>
    )
  }

  return (
    <TopNavigationLayoutPageContent type='defaultWithBackButton'>
      {renderHeader()}
      {renderContent()}
    </TopNavigationLayoutPageContent>
  )
}

export default TeamPage
