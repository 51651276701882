import { ReactComponent as ArrowLeft } from 'assets/icons/longArrowLeft.svg'
import css from './backButton.module.css'
import { useNavigate } from 'react-router'

const BackButton = () => {
  const navigate = useNavigate()

  return (
    <div className={css.container} onClick={() => navigate(-1)}>
      <ArrowLeft />
    </div>
  )
}

export default BackButton
