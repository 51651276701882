import { CSSProperties, PropsWithChildren } from 'react'
import css from './panelLayoutPageContent.module.css'
import BackButton from '../../../Buttons/BackButton'
import './panelLayoutPageContent.css'

interface IProps {
  type: 'default' | 'withBackButton' | 'largePadding' | 'defaultWithBackButton'
  style?: CSSProperties
  className?: string
}

const TopNavigationLayoutPageContent = (props: PropsWithChildren<IProps>) => {
  switch (props.type) {
    case 'defaultWithBackButton':
      return (
        <div className={css.pageWithBackButton}>
          <BackButton />
          <div className={css.defaultPageWithBackButtonContent} style={props.style}>
            {props.children}
          </div>
        </div>
      )
    case 'withBackButton':
      return (
        <div className={css.pageWithBackButton}>
          <div />
          <div className={css.pageWithBackButtonContentWrapper} style={props.style}>
            <div className={css.pageWithBackButtonContentWrapperButton}>
              <BackButton />
            </div>
            {props.children}
          </div>
        </div>
      )
    case 'default':
      return (
        <div className={css.defaultPageWrapper}>
          <div className={css.defaultPage} style={props.style}>
            {props.children}
          </div>
        </div>
      )
    case 'largePadding':
      return (
        <div className={css.largePaddingPage} style={props.style}>
          {props.children}
        </div>
      )
    default:
      return null
  }
}

export default TopNavigationLayoutPageContent
