import { ITableField } from 'shared/Table'
import { IOrganization } from '../../organizations.types'

const organiaztionsTableFields: ITableField<IOrganization, keyof IOrganization>[] = [
  { key: 'id', getter: (item) => item.id.toString(), sortGetter: (item) => item.id, header: 'ID', width: '400px' },
  {
    key: 'name',
    getter: (item) => item.name.toString(),
    sortGetter: (item) => item.name,
    header: 'Название',
    width: '400px',
  },
  {
    key: 'contact',
    getter: (item) => item.contact.toString(),
    sortGetter: (item) => item.contact,
    header: 'Контакт',
    width: '400px',
  },
]

export default organiaztionsTableFields
