import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useDebounce from 'shared/hooks/useDebounce'
import { useCallback } from 'react'
import useItemsSetter from 'recoil/useItemsSetter'
import useTableWithRequest from 'shared/Table/useTableWithRequest'
import Table from 'shared/Table'
import projectTechnologiesTableAtom from './projectTechnologiesTable.atom'
import projectTechnologiesApi from '../../projectTechnologies.api'
import projectTechnologiesTableFields from './projectTechnologiesTable.fields'
import { IProjectTechnogy } from '../../projectTechnologies.types'

const ProjectTechnologiesTable = () => {
  const navigate = useNavigate()
  const state = useRecoilValue(projectTechnologiesTableAtom)
  const debouncedSearch = useDebounce(state.filters.search, 400)

  const fetcher = useCallback(
    (params: Record<string, any>) => {
      return projectTechnologiesApi.list({
        search: debouncedSearch,
        ...params,
      })
    },
    [debouncedSearch]
  )

  const itemsSetter = useItemsSetter(projectTechnologiesTableAtom)

  const tableCtrl = useTableWithRequest({
    dataFetcher: fetcher,
    fields: projectTechnologiesTableFields,
    itemKey: 'id',
    items: state.items,
    setItems: itemsSetter,
    tableKey: 'project-technologies',
  })

  const handleClick = useCallback((p: IProjectTechnogy) => {
    navigate(`/admin/project-technologies/${p.id}`)
  }, [])

  return (
    <>
      <Table
        {...tableCtrl}
        items={tableCtrl.orderedItems}
        handleSelect={tableCtrl.selectedCtrl.setSelected}
        selected={tableCtrl.selectedCtrl.selectedIndexes as Set<keyof IProjectTechnogy>}
        handleSelectAll={tableCtrl.selectedCtrl.setSelectAll}
        itemKey='id'
        onCLick={handleClick}
      />
    </>
  )
}

export default ProjectTechnologiesTable
