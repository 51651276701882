import { ITableField } from 'shared/Table'
import { IUserSkill } from '../../userSkills.types'

const userSkillsTableFields: ITableField<IUserSkill, keyof IUserSkill>[] = [
  { key: 'id', getter: (item) => item.id.toString(), sortGetter: (item) => item.id, header: 'ID', width: '400px' },
  {
    key: 'name',
    getter: (item) => item.name.toString(),
    sortGetter: (item) => item.name,
    header: 'Название',
    width: '800px',
  },
]

export default userSkillsTableFields
