import { atom } from 'recoil'
import { ITeamInvite } from '../../../Teams/teams.types'

interface ISelfTeamInvitesState {
  loaded: boolean
  items: ITeamInvite[]
}

const selfTeamInvitesAtom = atom<ISelfTeamInvitesState>({
  key: '/self/team-invites',
  default: { items: [], loaded: false },
})
export default selfTeamInvitesAtom
