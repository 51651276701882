import { ITableField } from 'shared/Table'
import { IProjectTechnogy } from '../../projectTechnologies.types'

const projectTechnologiesTableFields: ITableField<IProjectTechnogy, keyof IProjectTechnogy>[] = [
  { key: 'id', getter: (item) => item.id.toString(), sortGetter: (item) => item.id, header: 'ID', width: '200px' },
  {
    key: 'title',
    getter: (item) => item.title.toString(),
    sortGetter: (item) => item.title,
    header: 'Название',
    width: '400px',
  },
  {
    key: 'description',
    getter: (item) => item.description.toString(),
    sortGetter: (item) => item.description,
    header: 'Описание',
    width: '400px',
  },
]

export default projectTechnologiesTableFields
