import React, { CSSProperties, FunctionComponent, useEffect, useRef } from 'react'
import './pagination.styles.css'
import ThreeDotsLoader from '../ThreeDotsLoader'

const InfinityLoader: FunctionComponent<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const callback = (entry) => {
      if (entry[0].isIntersecting && props.isMore) props.loadMore()
    }

    const observer = new IntersectionObserver(callback, { threshold: 0.9 })
    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [props.loadMore, props.isMore])

  return (
    <div className='inf-scroll__wrapper' style={props.style}>
      {props.children}
      <div ref={ref} className='inf-scroll__loader' style={!props.isMore ? { display: 'none' } : {}}>
        {(props.isMore || props.loading) && <ThreeDotsLoader style={{ marginTop: '40px' }} />}
      </div>
    </div>
  )
}

interface Props {
  style?: CSSProperties
  children: React.ReactNode
  loadMore: () => void
  isMore: boolean
  loading?: boolean
}

export default InfinityLoader
