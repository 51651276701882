import TopNavigationLayoutPageContent from '../../shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import ProjectsFiltersBlock from './components/ProjectsFiltersBlock'
import InfinityLoader from '../../shared/Loaders/InfinityLoader'
import projectsApi from './projects.api'
import { useRecoilState, useRecoilValue } from 'recoil'
import projectsAtom from './projects.atom'
import ProjectListCard from './components/ProjectListCard'
import useHttpLoader from 'shared/hooks/useHttpLoader'
import useSkipFirstRenderEffect from 'shared/hooks/useSkipFirstRenderEffect'
import useDebounce from 'shared/hooks/useDebounce'
import authAtom from 'screens/Auth/auth.atom'
import ProjectStatusSlideSelect from './components/ProjectStatusSlideSelect/OrderStatusSlideSelect'
import Button from 'shared/Buttons/Button/Button'
import { useNavigate } from 'react-router-dom'

const Projects = () => {
  const navigate = useNavigate()
  const isAdmin = useRecoilValue(authAtom).user.isAdmin

  const { wait, loading } = useHttpLoader()
  const [projectsState, setProjectsState] = useRecoilState(projectsAtom)
  const debouncedSearch = useDebounce(projectsState.filters.search)

  useSkipFirstRenderEffect(() => {
    wait(fetcher(true), (resp) => {
      if (resp.status === 'success') {
        setProjectsState((prev) => ({
          ...prev,
          nextPage: resp.body.nextPage,
          items: resp.body.items,
          totalCount: resp.body.totalCount,
        }))
      }
    })
  }, [
    debouncedSearch,
    projectsState.filters.eventId,
    projectsState.filters.organization,
    projectsState.filters.onlyAvailableForJoin,
    projectsState.filters.status,
    projectsState.filters.keyTechnology,
  ])

  const fetcher = (reload?: boolean) => {
    const params: any = {}
    if (projectsState.nextPage && !reload) params.nextPage = projectsState.nextPage
    if (projectsState.filters.eventId) params.eventId = projectsState.filters.eventId
    if (projectsState.filters.search) params.search = projectsState.filters.search
    if (projectsState.filters.organization) params.organizationId = projectsState.filters.organization
    if (projectsState.filters.status) params.status = projectsState.filters.status
    if (projectsState.filters.keyTechnology) params.keyTechnology = projectsState.filters.keyTechnology

    return projectsApi.getProjects(params)
  }

  const loadMore = () => {
    fetcher().then((r) => {
      if (r.status === 'success') {
        setProjectsState((prev) => ({
          ...prev,
          nextPage: r.body.nextPage,
          totalCount: r.body.totalCount,
          items: [...prev.items, ...r.body.items],
        }))
      }
    })
  }

  const content = projectsState.items.map((p) => <ProjectListCard project={p} key={p.id} />)

  return (
    <TopNavigationLayoutPageContent type='default'>
      <div className='flex-lines gap24'>
        <div className='flex-space-between'>
          <div className='inline-flex-gap' style={{ alignItems: 'flex-start' }}>
            <div className='flex-lines' style={{ gap: '6px', width: 'fit-content', flexShrink: 0 }}>
              <h2 className='text--heading-h4' style={{ flexShrink: 0 }}>
                Каталог проектов
              </h2>
              <p className='text--caption-s'>Всего {projectsState.totalCount} проектов</p>
            </div>

            {isAdmin && (
              <ProjectStatusSlideSelect
                selectedOption={projectsState.filters.status}
                onSelect={(status) => setProjectsState((prev) => ({ ...prev, filters: { ...prev.filters, status } }))}
              />
            )}
          </div>
          {isAdmin && (
            <Button theme='primary' style={{ width: 'fit-content' }} onClick={() => navigate('/projects/create')}>
              + Создать
            </Button>
          )}
        </div>
        <ProjectsFiltersBlock loading={loading} />
      </div>

      <div
        key={debouncedSearch}
        style={{
          display: 'inline-flex',
          flexWrap: 'wrap',
          gap: '24px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
        }}
      >
        {content}
      </div>
      <InfinityLoader loadMore={loadMore} isMore={projectsState.nextPage !== null} loading={loading}>
        <div />
      </InfinityLoader>
    </TopNavigationLayoutPageContent>
  )
}

export default Projects
