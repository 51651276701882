import { useParams } from 'react-router-dom'
import useProject from 'screens/Projects/hooks/useProject'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import ThreeDotsLoader from 'shared/Loaders/ThreeDotsLoader'
import ProjectPhotoCard from '../MyProject/ProjectPhotoCard'
import { translateProjectStatus } from '../MyProject'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import authAtom from 'screens/Auth/auth.atom'
import ActionsButtonWithMenu from 'shared/Buttons/ActionsButtonWithMenu'
import useHttpLoader from 'shared/hooks/useHttpLoader'
import projectsApi from 'screens/Projects/projects.api'
import eventBus from 'services/eventBus'
import recoilStateUpdaters from 'recoil/recoil.stateUpdaters'
import projectsAtom from 'screens/Projects/projects.atom'
import CircleLoader from 'shared/Loaders/CircleLoader'
import ProjectInfoCard from 'screens/Projects/components/Cards/ProjectInfoCard'
import ProjectDescriptionCard from 'screens/Projects/components/Cards/ProjectDescriptionCard'
import ProjectMotivationCard from 'screens/Projects/components/Cards/ProjectMotivationCard'
import ProjectExpectingResultsCard from 'screens/Projects/components/Cards/ProjectExpectingResultsCard'
import ProjectTechStackCard from 'screens/Projects/components/Cards/ProjectTechStackCard'
import useIsProjectEditable from 'screens/Projects/hooks/useIsProjectEditable'
import SlideSelect from 'shared/Inputs/SlideSelect'
import { useMemo, useState } from 'react'
import ProjectCommentsContent from 'screens/Projects/components/ProjectCommentsContent'
import organizationsSelectors from 'screens/Admin/Organizations/organizations.selectors'
import eventsSelectors from 'screens/Admin/Events/events.selectors'
import ProjectTeamsCard from 'screens/Projects/components/Cards/ProjectTeamsCard'
import { IProject } from 'screens/Projects/projects.types'
import useProjectsActions from 'screens/Projects/hooks/useProjectsActions'
import selfProjectsAtom from 'screens/Auth/components/InitProvider/selfProjects.atom'

const ProjectPage = () => {
  const organiaztionsMap = useRecoilValue(organizationsSelectors.organiaztionsMap)
  const eventsMap = useRecoilValue(eventsSelectors.eventsFullMap)

  const { wait: approveWait, loading: approveLoading } = useHttpLoader()
  const { wait: rejectWait, loading: rejectLoading } = useHttpLoader()

  const isAdmin = useRecoilValue(authAtom).user.isAdmin

  const params = useParams()
  const setProjectsState = useSetRecoilState(projectsAtom)
  const { project, loading, setProject } = useProject(+params.id)
  const isProjectEditable = useIsProjectEditable(+params.id)
  const [selectedSlide, setSelectedSlide] = useState<'info' | 'comments'>('info')
  const actions = useProjectsActions()
  const setSelfProject = useSetRecoilState(selfProjectsAtom)

  const slidesOptions = useMemo(() => {
    if (!project) return []

    return [
      { value: 'info' as const, title: 'Информация' },
      { value: 'comments' as const, title: `Комментарии (${project.comments.length})` },
    ]
  }, [project])

  const handleApprove = () => {
    approveWait(projectsApi.approveProject(project.id), (resp) => {
      if (resp.status === 'success') {
        setProject((prev) => ({ ...prev, status: 'APPROVED' }))
        recoilStateUpdaters.itemUpdated(setProjectsState, { ...project, status: 'APPROVED' })
        eventBus.pub('ntf.message', { type: 'info', message: 'Проект отмечен одобренным' })
      }
    })
  }

  const handleReject = () => {
    rejectWait(projectsApi.rejectProject(project.id), (resp) => {
      if (resp.status === 'success') {
        setProject((prev) => ({ ...prev, status: 'REJECTED' }))
        recoilStateUpdaters.itemUpdated(setProjectsState, { ...project, status: 'REJECTED' })
        eventBus.pub('ntf.message', { type: 'info', message: 'Проект отмечен неодобренным' })
      }
    })
  }

  const handleJoined = (project: IProject) => {
    setSelfProject((prev) => ({ ...prev, items: [project, ...prev.items] }))
    setProject(project)
    actions.projectUpdated(project)
  }

  const handleUpdated = (project: IProject) => {
    setProject(project)
    actions.projectUpdated(project)
  }

  const renderContent = () => {
    if (loading || !project) {
      return (
        <div className='flex-page-center'>
          <ThreeDotsLoader />
        </div>
      )
    }

    if (selectedSlide === 'comments') {
      return <ProjectCommentsContent project={project} />
    }

    return (
      <div className='flex-lines gap24'>
        <div className='inline-flex-gap gap24'>
          <ProjectPhotoCard id={project.id} />
          <ProjectTeamsCard project={project} onJoined={handleJoined} onUpdated={handleUpdated} />
        </div>

        <ProjectInfoCard project={project} />
        <ProjectDescriptionCard project={project} />
        <ProjectMotivationCard project={project} />
        <ProjectExpectingResultsCard project={project} />
        <ProjectTechStackCard project={project} />
      </div>
    )
  }

  return (
    <TopNavigationLayoutPageContent type='default'>
      <div className='flex-space-between' style={{ gap: '128px' }}>
        <div className='inline-flex-gap' style={{ gap: '32px' }}>
          <div className='inline-flex-gap' style={{ gap: '32px' }}>
            <div className='flex-lines' style={{ gap: '6px' }}>
              <h2 className='text--heading-h4'>{project?.name || 'Проект'}</h2>
              <p className='text--caption-m'>
                {project?.organizationId ? organiaztionsMap[project?.organizationId]?.name : 'Студенческий проект'} •{' '}
                {project && translateProjectStatus(project.status)}
              </p>
              <p className='text--caption-s'>{eventsMap[project?.eventId]?.name}</p>
            </div>
          </div>
          {isProjectEditable && (
            <div style={{ marginRight: 'auto', marginBottom: 'auto', flexShrink: 0 }}>
              <SlideSelect selectedOption={selectedSlide} options={slidesOptions} onSelect={setSelectedSlide as any} />
            </div>
          )}
        </div>
        {isAdmin && (
          <ActionsButtonWithMenu>
            <span className='options-contextmenu-option' onClick={handleApprove}>
              Отметить одобренным
              {approveLoading && <CircleLoader style={{ width: '23px', height: '23px' }} />}
            </span>
            <span className='options-contextmenu-option' onClick={handleReject} data-danger='true'>
              Отметить неодобренным
              {rejectLoading && <CircleLoader style={{ width: '23px', height: '23px' }} />}
            </span>
          </ActionsButtonWithMenu>
        )}
      </div>
      {renderContent()}
    </TopNavigationLayoutPageContent>
  )
}

export default ProjectPage
