import Sheet from 'shared/Sheet'
import TextField from 'shared/Forms/TextField'
import TextInput from 'shared/Inputs/TextInput'
import { PropsWithChildren } from 'react'
import { IProjectTechnogy } from '../../projectTechnologies.types'
import Textarea from 'shared/Inputs/Textarea'

interface IProps {
  technology: Pick<IProjectTechnogy, 'title' | 'description'>
  onChange: (val: any, name: string) => void
}

const ProjectTechnologySheet = (props: PropsWithChildren<IProps>) => {
  return (
    <Sheet>
      <div className='flex-lines'>
        <div className='grid-form-2'>
          <TextField header='Наименование'>
            <TextInput value={props.technology.title} onChange={props.onChange} name='title' placeholder='WEB' />
          </TextField>
        </div>
        <TextField header='Описание'>
          <Textarea
            value={props.technology.description}
            onChange={props.onChange}
            name='description'
            placeholder='WEB включает в себя...'
          />
        </TextField>
      </div>
      <div className='form-footer'>{props.children}</div>
    </Sheet>
  )
}

export default ProjectTechnologySheet
