interface IProps {
  checked?: boolean
  name?: string
  onCheck?: (val: boolean, name: string) => void
  disabled?: boolean
  inscription?: string
}

const Checkbox = (props: IProps) => {
  const renderCheckbox = () => {
    return (
      <input
        type='checkbox'
        checked={props.checked}
        onChange={props.disabled ? undefined : (ev) => props.onCheck(ev.target.checked, props.name)}
        disabled={props.disabled}
        style={{ cursor: 'pointer' }}
      />
    )
  }

  if (!props.inscription) {
    return renderCheckbox()
  }

  return (
    <div className='inline-flex-gap' style={{ gap: '12px' }}>
      {renderCheckbox()}
      <span className='text--body-m'>{props.inscription}</span>
    </div>
  )
}
export default Checkbox
