import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userSkillsTableAtom from './userSkillsTable.atom'
import useDebounce from 'shared/hooks/useDebounce'
import { useCallback } from 'react'
import userSkillsApi from '../../userSkills.api'
import useItemsSetter from 'recoil/useItemsSetter'
import useTableWithRequest from 'shared/Table/useTableWithRequest'
import userSkillsTableFields from './userSkillsTable.fields'
import { IUserSkill } from '../../userSkills.types'
import Table from 'shared/Table'

const UserSkillsTable = () => {
  const navigate = useNavigate()
  const state = useRecoilValue(userSkillsTableAtom)
  const debouncedSearch = useDebounce(state.filters.search, 400)

  const fetcher = useCallback(
    (params: Record<string, any>) => {
      return userSkillsApi.listUserSkills({
        search: debouncedSearch,
        ...params,
      })
    },
    [debouncedSearch]
  )

  const itemsSetter = useItemsSetter(userSkillsTableAtom)

  const tableCtrl = useTableWithRequest({
    dataFetcher: fetcher,
    fields: userSkillsTableFields,
    itemKey: 'id',
    items: state.items,
    setItems: itemsSetter,
    tableKey: 'user-skills',
  })

  const handleClick = useCallback((skill: IUserSkill) => {
    navigate(`/admin/skills/${skill.id}`)
  }, [])

  return (
    <>
      <Table
        {...tableCtrl}
        items={tableCtrl.orderedItems}
        handleSelect={tableCtrl.selectedCtrl.setSelected}
        selected={tableCtrl.selectedCtrl.selectedIndexes as Set<keyof IUserSkill>}
        handleSelectAll={tableCtrl.selectedCtrl.setSelectAll}
        itemKey='id'
        onCLick={handleClick}
      />
    </>
  )
}

export default UserSkillsTable
