import css from './profileIcon.module.css'
import cn from '../../../../utils/cn'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import authAtom, { initialAuthState } from '../../../../screens/Auth/auth.atom'
import authApi from 'screens/Auth/auth.api'

const ProfileIcon = () => {
  const user = useRecoilValue(authAtom).user
  const setAuthState = useSetRecoilState(authAtom)

  const handleLogout = () => {
    authApi.logout()
    setAuthState(initialAuthState)
    location.reload()
  }

  return (
    <div className={css.user}>
      <span className='text--secondary text--body-m' style={{ color: '#4A4A68', flexShrink: 0 }}>
        {user.info.name.split(' ')[1] || user.info.name}
      </span>
      <div className={css.separator} />
      <span
        className={cn('text--secondary text--body-m', css.exit)}
        style={{ flexShrink: 0, cursor: 'pointer' }}
        onClick={handleLogout}
      >
        Выйти
      </span>
    </div>
  )
}

export default ProfileIcon
