import { ReactComponent as EditIcon } from '../../../assets/icons/icon-edit.svg'
import { CSSProperties } from 'react'
import css from './editButton.module.css'

interface IProps {
  onClick?: () => void
  style?: CSSProperties
  className?: string
}

const EditButton = (props: IProps) => {
  return <EditIcon className={css.icon} {...props} />
}

export default EditButton
