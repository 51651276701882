import UserPhotoCard from 'screens/Users/components/UserPhotoCard'
import TopNavigationLayoutPageContent from '../../shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import GeneralInfoCard from './components/SelfGeneralInfoCard'
import SelfSkillsCard from './components/SelfSkillsCard'
import { useRecoilValue } from 'recoil'
import authAtom from 'screens/Auth/auth.atom'
import UserProjectsCard from 'screens/Users/components/UserProjectsCard'

const Profile = () => {
  const userId = useRecoilValue(authAtom).user.id

  return (
    <TopNavigationLayoutPageContent type='default'>
      <div className='inline-flex-gap' style={{ gap: '32px' }}>
        <h2 className='text--heading-h4' style={{ flexShrink: 0 }}>
          Профиль
        </h2>
      </div>
      <div className='flex-lines gap24'>
        <div className='inline-flex-gap gap24' style={{ width: '100%' }}>
          <UserPhotoCard userId={userId} />
          <div style={{ width: '100%' }}>
            <GeneralInfoCard />
          </div>
        </div>
        <SelfSkillsCard />
        <UserProjectsCard />
      </div>
    </TopNavigationLayoutPageContent>
  )
}

export default Profile
