import { memo } from 'react'
import { useRecoilState } from 'recoil'
import projectsAtom from '../../projects.atom'
import SearchInput from '../../../../shared/Inputs/SearchInput'
import EventsDropdown from '../../../Admin/Events/components/EventsDropdown'
import OrganizationSelect from 'screens/Admin/Organizations/componenets/OrganizationSelect'
import ProjectTechnologiesDropdown from 'screens/Admin/ProjectTechnologies/componenets/ProjectTechnologiesDropdown'

const ProjectsFiltersBlock = (props: { loading?: boolean }) => {
  const [projectsState, setProjectsState] = useRecoilState(projectsAtom)

  const handleChange = (val: any, name: string) => {
    setProjectsState((prev) => ({ ...prev, filters: { ...prev.filters, [name]: val } }))
  }

  return (
    <div className='inline-flex-gap' style={{ width: '100%', gap: '8px' }}>
      <SearchInput
        loading={props.loading}
        value={projectsState.filters.search}
        name='search'
        onChange={handleChange}
        required={false}
        placeholder='Поиск по названию, описанию, ссылке на репозиторий'
      />
      <div style={{ width: '350px' }}>
        <ProjectTechnologiesDropdown
          value={projectsState.filters.keyTechnology}
          onChange={handleChange}
          name='keyTechnology'
        />
      </div>
      <div style={{ width: '300px' }}>
        <OrganizationSelect
          value={projectsState.filters.organization}
          onChange={handleChange}
          name='organization'
          title='Организация'
          allowEmpty={true}
        />
      </div>
      <div style={{ width: '300px' }}>
        <EventsDropdown value={projectsState.filters.eventId} onChange={handleChange} name='eventId' />
      </div>
    </div>
  )
}

export default memo(ProjectsFiltersBlock)
