import { ITeam } from 'screens/Teams/teams.types'
import Modal from 'shared/Modal'
import ModalHeader from 'shared/Modal/ModalHeader'
import TeamsTable from '../TeamsTable'

interface IProps {
  onSelect: (team: ITeam) => void
  onClose: () => void
}

const SelectTeamModal = (props: IProps) => {
  return (
    <Modal onClose={props.onClose} size='hg' shouldAnimate={false}>
      <ModalHeader onClose={props.onClose}>Выбор команды</ModalHeader>
      <div className='modal-content flex-lines' style={{ height: '60vh' }}>
        <TeamsTable onClick={props.onSelect} />
      </div>
    </Modal>
  )
}

export default SelectTeamModal
