import Sheet from 'shared/Sheet'
import TextField from 'shared/Forms/TextField'
import TextInput from 'shared/Inputs/TextInput'
import { PropsWithChildren } from 'react'
import { IOrganization } from '../../organizations.types'

interface IProps {
  organization: Pick<IOrganization, 'name' | 'contact'>
  onChange: (val: any, name: string) => void
}

const OrganizationSheet = (props: PropsWithChildren<IProps>) => {
  return (
    <Sheet>
      <div className='flex-lines'>
        <div className='grid-form-2'>
          <TextField header='Наименование'>
            <TextInput value={props.organization.name} onChange={props.onChange} name='name' placeholder='Альфа-Банк' />
          </TextField>
        </div>
        <TextField header='Контактные данные'>
          <TextInput
            value={props.organization.contact}
            onChange={props.onChange}
            name='contact'
            placeholder='Василия, +79088911950'
          />
        </TextField>
      </div>
      <div className='form-footer'>{props.children}</div>
    </Sheet>
  )
}

export default OrganizationSheet
