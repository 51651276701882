import Sheet from 'shared/Sheet'
import TextField from 'shared/Forms/TextField'
import TextInput from 'shared/Inputs/TextInput'
import { PropsWithChildren } from 'react'
import { IEvent } from '../../events.types'
import DateInput from 'shared/Inputs/DateInput'
import Toggle from 'shared/Inputs/Toggle'

interface IProps {
  event: Pick<IEvent, 'name' | 'startAt' | 'endsAt' | 'locked'>
  onChange: (val: any, name: string) => void
}

const EventSheet = (props: PropsWithChildren<IProps>) => {
  return (
    <Sheet>
      <div className='flex-lines'>
        <div className='grid-form-2'>
          <TextField header='Наименование'>
            <TextInput value={props.event.name} onChange={props.onChange} name='name' placeholder='Альфа-Банк' />
          </TextField>
          <TextField header='Locked'>
            <Toggle checked={props.event.locked} name='locked' onChange={props.onChange} />
          </TextField>
          <TextField header='Начало'>
            <DateInput date={props.event.startAt} onChange={props.onChange} name='startAt' />
          </TextField>
          <TextField header='Конец'>
            <DateInput date={props.event.endsAt} onChange={props.onChange} name='endsAt' />
          </TextField>
        </div>
      </div>
      <div className='form-footer'>{props.children}</div>
    </Sheet>
  )
}

export default EventSheet
