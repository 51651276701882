import eventBus from '../eventBus'

class AccessTokenService {
  private token: string

  constructor() {
    eventBus.sub('access.changed', ({ token }) => {
      this.token = token
    })
  }

  public get = (): string => {
    return this.token
  }
}

/**
 * Сервис, позволяющий получить access token без использования хуков
 * */
const accessTokenService = new AccessTokenService()
export default accessTokenService
