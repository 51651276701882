const openLink = (to: string) => {
  if (!to) return

  const l = document.createElement('a')
  l.href = to
  l.target = '_blank'
  l.click()
}

const getQueryParams = (): Record<string, string> => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return Object.fromEntries(urlSearchParams.entries())
}

const extractQueryParams = (url: string): Record<string, string> => {
  const urlSearchParams = new URLSearchParams(url.substring(url.lastIndexOf('/') + 1).split('?')[1])
  return Object.fromEntries(urlSearchParams.entries())
}

const setQueryParam = (key: string, value: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.set(key, value)
  window.location.search = urlParams.toString()
}

const batchSetQueryParams = (params: Array<{ key: string; value: string }>) => {
  const url = new URL(window.location.href)
  for (const param of params) url.searchParams.set(param.key, param.value)

  window.history.pushState(null, '', url)
}

const extractFilenameFromUrl = (url: string) => {
  const filename = url.substring(url.lastIndexOf('/') + 1)
  return filename.split('?')[0]
}

const domUtils = {
  openLink,
  getQueryParams,
  setQueryParam,
  batchSetQueryParams,
  extractFilenameFromUrl,
  extractQueryParams,
}

export default domUtils
