import { atom } from 'recoil'
import { IProjectTechnogy } from '../../projectTechnologies.types'

interface IProjectTechnologiesTableAtomState {
  items: IProjectTechnogy[]
  filters: {
    search?: string
  }
}

const projectTechnologiesTableAtom = atom<IProjectTechnologiesTableAtomState>({
  key: 'tables/admin/project-technologies',
  default: { items: [], filters: { search: '' } },
})
export default projectTechnologiesTableAtom
