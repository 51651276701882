import IStoreTreeStrategy from '../strategy.interface'
import { AnyObject } from '../../../../../types/global'

class InMemoryStrategy<T extends AnyObject> implements IStoreTreeStrategy<T> {
  private readonly state: Record<string, any>

  constructor() {
    this.state = {}
  }

  get(key: string) {
    return this.state[key]
  }

  set(key: string, state: T) {
    this.state[key] = state
  }
}

export default InMemoryStrategy
