import { IOption } from '../../../../../types/global'
import { IProject } from 'screens/Projects/projects.types'

const projectStatusOptions: Array<IOption<IProject['status']>> = [
  { title: 'Все', value: undefined },
  { title: 'На рассмотрении', value: 'PENDING' },
  { title: 'Одобренные', value: 'APPROVED' },
  { title: 'Неодобренные', value: 'REJECTED' },
]
export default projectStatusOptions
