import { HTTPResponse } from '../http.types'
import eventBus from '../../eventBus'
import { isJsonEqual } from '../../../utils/misc.utils'

type HTTPFunction<T extends any, U extends HTTPResponse<any>> = (...params: T[]) => Promise<U>

class HTTPCache {
  private cachedResponses: Map<Function, HTTPResponse<any>>
  private cachedParams: Map<Function, any>

  constructor() {
    this.cachedParams = new Map<Function, any>()
    this.cachedResponses = new Map<Function, HTTPResponse<any>>()
    this.subscribeToCacheUpdates()
  }

  private subscribeToCacheUpdates() {
    eventBus.sub('httpCache.update', (message) => {
      if (!this.cachedResponses.has(message.cachedFunction)) return

      this.cachedResponses.set(message.cachedFunction, message.updates)
      this.cachedParams.set(message.cachedFunction, message.params)
    })
  }

  public async execute<T extends any, U extends HTTPResponse<any>>(
    cachedFn: HTTPFunction<T, U>,
    ...params: T[]
  ): Promise<U> {
    if (this.cachedResponses.has(cachedFn)) {
      if (isJsonEqual(params, this.cachedParams.get(cachedFn))) {
        return this.cachedResponses.get(cachedFn) as U
      }
    }

    const result = await cachedFn(...params)
    this.cachedResponses.set(cachedFn, result)
    this.cachedParams.set(cachedFn, params)

    return result
  }

  public getCachedValue<T extends any, U extends HTTPResponse<any>>(cachedFn: HTTPFunction<T, U>, ...params: T[]): U {
    if (this.cachedResponses.has(cachedFn)) {
      if (isJsonEqual(params, this.cachedParams.get(cachedFn))) {
        return this.cachedResponses.get(cachedFn) as U
      }
    }

    return null
  }

  public updateCachedValue<T extends any, U extends HTTPResponse<any>>(
    value: U,
    cachedFn: HTTPFunction<T, U>,
    ...params: T[]
  ) {
    if (this.cachedResponses.has(cachedFn)) {
      if (isJsonEqual(params, this.cachedParams.get(cachedFn))) {
        this.cachedResponses.set(cachedFn, value)
      }
    }

    return null
  }
}

const httpCache = new HTTPCache()
export default httpCache
