import { IProject } from '../../../projects.types'
import ProjectPhotoCard from '../ProjectPhotoCard'
import ProjectDescriptionCard from 'screens/Projects/components/Cards/ProjectDescriptionCard'
import ProjectMotivationCard from 'screens/Projects/components/Cards/ProjectMotivationCard'
import ProjectExpectingResultsCard from 'screens/Projects/components/Cards/ProjectExpectingResultsCard'
import ProjectTechStackCard from 'screens/Projects/components/Cards/ProjectTechStackCard'
import ProjectInfoCard from 'screens/Projects/components/Cards/ProjectInfoCard'

interface IProps {
  project: IProject
}

const ProjectInfoContent = (props: IProps) => {
  return (
    <div className='flex-lines gap24'>
      <ProjectPhotoCard id={props.project.id} />
      <ProjectInfoCard project={props.project} />
      <ProjectDescriptionCard project={props.project} />
      <ProjectMotivationCard project={props.project} />
      <ProjectExpectingResultsCard project={props.project} />
      <ProjectTechStackCard project={props.project} />
    </div>
  )
}

export default ProjectInfoContent
