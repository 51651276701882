import Checkbox from '../../Inputs/Checkbox'
import { ReactComponent as Options } from '../../../assets/icons/threeDots.svg'
import { ITableField } from '../index'
import usePopup from '../../hooks/usePopup'
import { useRef } from 'react'

interface IProps<T extends Record<string, any>, K extends keyof T> {
  fields: ITableField<T, K>[]

  selectEnabled: boolean
  selected: Set<K>
  handleSelect: (key: K, selected: boolean) => void

  onCLick?: (item: T) => void
  item: T
  itemKey: K
  renderItemContextMenu?: (item: T) => JSX.Element
  index: number
}

const TableRow = <T extends Record<string, any>, K extends keyof T>(props: IProps<T, K>) => {
  const optionsRef = useRef(null)
  const { setOpen, open } = usePopup(optionsRef)

  const onClick = props.onCLick ? () => props.onCLick(props.item) : undefined

  const renderField = (field: ITableField<T, K>) => {
    const value = field.getter(props.item, field.key)

    let title = ''
    if (typeof value === 'string') title = value

    return (
      <td key={field.key as string} onClick={onClick} title={title}>
        <div className={typeof value === 'string' ? 'line-clamp' : undefined}>{value}</div>
      </td>
    )
  }

  return (
    <>
      {props.selectEnabled !== false && (
        <td className='table--checkbox-col'>
          <Checkbox
            checked={props.selected.has(props.item[props.itemKey])}
            onCheck={(checked) => props.handleSelect(props.item[props.itemKey], checked)}
          />
        </td>
      )}
      {props.fields.map(renderField)}
      {props.renderItemContextMenu && (
        <td
          className='tableOptionsRow'
          align='center'
          onClick={(ev) => {
            ev.stopPropagation()
            ev.preventDefault()

            setOpen(true)
          }}
          ref={optionsRef}
        >
          <Options className='tableOptions' />
          {open && <div className='table-contextmenu'>{props.renderItemContextMenu(props.item)}</div>}
        </td>
      )}
    </>
  )
}

export default TableRow
