import AdminNavigationLayout from 'shared/Layouts/TopNavigationLayout/AdminNavifationLayout'
import TopNavigationLayoutPageContent from 'shared/Layouts/TopNavigationLayout/TopNavigationLayoutPageContent'
import { useNavigate } from 'react-router-dom'
import TeamsTable from 'screens/Teams/components/TeamsTable'
import Button from 'shared/Buttons/Button/Button'

const AdminTeamsPage = () => {
  const navigate = useNavigate()

  return (
    <AdminNavigationLayout>
      <TopNavigationLayoutPageContent type='default'>
        <div className='flex-space-between'>
          <div className='inline-flex-gap' style={{ gap: '2.5em' }}>
            <h2 className='page--header'>Команды</h2>
          </div>
          <Button theme='primary' style={{ width: 'fit-content' }} onClick={() => navigate('/admin/teams/create')}>
            + Создать
          </Button>
        </div>
        <TeamsTable onClick={(t) => navigate('/teams/' + t.id)} />
      </TopNavigationLayoutPageContent>
    </AdminNavigationLayout>
  )
}

export default AdminTeamsPage
