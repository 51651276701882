import { Dispatch, useState, SetStateAction, useMemo } from 'react'
import { Primitive } from '../../../types/global'
import LocalStorageStrategy, { ILSCodec } from './percistenceStrategies/LocalStorage/LocalStorageStrategy'
import InMemoryStrategy from './percistenceStrategies/InMemory/InMemoryStrategy'

const inMemoryStrategies: Record<string, InMemoryStrategy<any>> = {}

const usePersistentState = <T extends Object | unknown[] | Set<unknown> | Primitive>(
  initialState: T,
  stateKey: string,
  storeStrategy: 'localstorage' | 'inMemory' = 'localstorage',
  codec: ILSCodec<T> = JSON
): [T, Dispatch<SetStateAction<T>>] => {
  const strategy = useMemo(() => {
    if (storeStrategy === 'inMemory') {
      if (inMemoryStrategies[stateKey]) return inMemoryStrategies[stateKey]

      const st = new InMemoryStrategy()
      inMemoryStrategies[stateKey] = st
      return st
    }

    return new LocalStorageStrategy<T>(codec)
  }, [codec])

  const [state, setState] = useState<T>(strategy.get(stateKey) ?? initialState)
  const setBothStates: Dispatch<SetStateAction<T>> = (arg) => {
    setState((prev) => {
      if (typeof arg === 'function') {
        const updates = arg(prev)
        strategy.set(stateKey, updates)
        return updates
      }

      strategy.set(stateKey, arg)
      return arg
    })
  }

  return [state, setBothStates]
}

export default usePersistentState
