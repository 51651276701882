import useEntity from 'recoil/useEntity/useEntity'
import { IOrganization } from '../organizations.types'
import organiaztionsTableAtom from '../componenets/OrganizationsTable/organizations.atom'
import organizationsApi from '../organizations.api'

const useOrganization = (id: IOrganization['id']) => {
  const { entity, loading } = useEntity({
    atom: organiaztionsTableAtom,
    entityId: id,
    itemFetcher: organizationsApi.getOne,
    resource: 'organizations',
  })

  return { organization: entity, loading }
}

export default useOrganization
