import { atom } from 'recoil'
import { IUser } from '../../../../Admin/RolesAndUsers/users.types'

interface IStudentsTableAtomState {
  items: IUser[]
  filters: {
    search?: string
  }
}
const studentsTableAtom = atom<IStudentsTableAtomState>({
  key: 'tables/students',
  default: { items: [], filters: { search: '' } },
})
export default studentsTableAtom
