import appRoutes from './app.routes'
import navigationUtils from './navigation.utils'
import { Router as RemixRouter } from '@remix-run/router/dist/router'
import { IRoute } from './navigation.types'
import { deepCopy } from '../utils/misc.utils'

export const flattenRoutes = navigationUtils.flatRoutes(deepCopy(appRoutes))

interface IRouter {
  appRouter: RemixRouter
  flattenRoutes: IRoute
}

const router: IRouter = {
  appRouter: null,
  flattenRoutes,
}
export default router
